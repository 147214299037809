import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../Auth';
import { AlertCircle } from 'lucide-react';
import { db } from '../firebaseConfig';
import { doc, setDoc, serverTimestamp } from 'firebase/firestore';

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [company, setCompany] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  
  const { signUp } = useAuth();
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Form validation
    if (!email || !password || !confirmPassword || !fullName) {
      setError('Please fill all required fields');
      return;
    }
    
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    
    try {
      setIsLoading(true);
      setError('');
      
      // Create user account
      const userCredential = await signUp(email, password);
      const userId = userCredential.user.uid;
      
      // Create user document with pending status
      await setDoc(doc(db, "users", userId), {
        email: email,
        fullName: fullName,
        company: company || 'Not specified',
        roles: [], // Empty roles until approved
        status: 'pending', // Pending approval
        createdAt: serverTimestamp()
      });
      
      // Send admin notification email (will implement this in Cloud Functions)
      // For now, we'll assume this happens on the backend
      
      setSuccess(true);
      
      // Optionally sign out the user until they're approved
      // await signOut();
      
    } catch (err) {
      console.error('Error creating account:', err);
      setError(err.message || 'Failed to create account');
    } finally {
      setIsLoading(false);
    }
  };
  
  return (
    <div className="min-h-screen bg-slate-950 flex items-center justify-center p-4">
      <div className="w-full max-w-md bg-slate-900 rounded-lg shadow-xl overflow-hidden">
        <div className="p-6">
          <div className="flex justify-center mb-6">
            <div className="flex items-center space-x-2">
              <div className="w-8 h-8 bg-blue-500 rounded-lg flex items-center justify-center text-white font-bold">N</div>
              <span className="font-bold text-lg text-white">Strategy Hub</span>
            </div>
          </div>
          
          <h2 className="text-xl font-bold text-white mb-6 text-center">
            Create your account
          </h2>
          
          {error && (
            <div className="bg-red-500/10 border border-red-500/20 rounded-lg p-3 mb-4 flex items-start">
              <AlertCircle className="w-5 h-5 text-red-500 mr-2 flex-shrink-0 mt-0.5" />
              <span className="text-red-400 text-sm">{error}</span>
            </div>
          )}
          
          {success ? (
            <div className="bg-green-500/10 border border-green-500/20 rounded-lg p-4 mb-4 text-center">
              <h3 className="font-medium text-green-400 mb-2">Request Submitted</h3>
              <p className="text-slate-300 text-sm mb-4">
                Your account request has been submitted for approval. You'll receive an email when your account is approved.
              </p>
              <Link 
                to="/login" 
                className="inline-block bg-blue-600 hover:bg-blue-700 px-4 py-2 rounded-md text-white text-sm font-medium"
              >
                Back to Login
              </Link>
            </div>
          ) : (
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-slate-300 mb-1">
                  Email address<span className="text-red-400">*</span>
                </label>
                <input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-3 py-2 bg-slate-800 border border-slate-700 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="name@company.com"
                  required
                />
              </div>
              
              <div>
                <label htmlFor="fullName" className="block text-sm font-medium text-slate-300 mb-1">
                  Full Name<span className="text-red-400">*</span>
                </label>
                <input
                  id="fullName"
                  type="text"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  className="w-full px-3 py-2 bg-slate-800 border border-slate-700 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="John Doe"
                  required
                />
              </div>
              
              <div>
                <label htmlFor="company" className="block text-sm font-medium text-slate-300 mb-1">
                  Company
                </label>
                <input
                  id="company"
                  type="text"
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                  className="w-full px-3 py-2 bg-slate-800 border border-slate-700 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Company Name (Optional)"
                />
              </div>
              
              <div>
                <label htmlFor="password" className="block text-sm font-medium text-slate-300 mb-1">
                  Password<span className="text-red-400">*</span>
                </label>
                <input
                  id="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full px-3 py-2 bg-slate-800 border border-slate-700 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="••••••••"
                  required
                />
              </div>
              
              <div>
                <label htmlFor="confirmPassword" className="block text-sm font-medium text-slate-300 mb-1">
                  Confirm Password<span className="text-red-400">*</span>
                </label>
                <input
                  id="confirmPassword"
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="w-full px-3 py-2 bg-slate-800 border border-slate-700 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="••••••••"
                  required
                />
              </div>
              
              <div>
                <button
                  type="submit"
                  disabled={isLoading}
                  className={`w-full py-2 px-4 rounded-md text-white font-medium ${
                    isLoading
                      ? 'bg-blue-600 opacity-70 cursor-not-allowed'
                      : 'bg-blue-600 hover:bg-blue-700'
                  }`}
                >
                  {isLoading ? 'Creating account...' : 'Request Account'}
                </button>
              </div>
              
              <div className="text-center mt-4">
                <Link
                  to="/login"
                  className="text-blue-400 hover:text-blue-300 text-sm"
                >
                  Already have an account? Sign in
                </Link>
              </div>
            </form>
          )}
        </div>
        
        <div className="px-6 py-4 bg-slate-800/50 border-t border-slate-800">
          <p className="text-sm text-slate-400 text-center">
            This portal is restricted to gClouds team members only.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Signup;