import React, { useState } from 'react';
import { 
  Info,
  MessageSquare,
  Building2,
  MapPin,
  ArrowRight
} from 'lucide-react';

const SupportForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    company: '',
    region: '',
    country: '',
    city: '',
    priority: '',
    subject: '',
    description: '',
    marketingConsent: true
  });

  const [showPriorityInfo, setShowPriorityInfo] = useState(false);

  const regions = [
    "Americas",
    "Europe-Middle East-Africa",
    "Asia-Pacific-Japan"
  ];

  const priorityLevels = [
    {
      level: "P1",
      name: "Critical Impact",
      description: "Service Unusable - Complete loss of service affecting all users",
      response: "30 minutes"
    },
    {
      level: "P2",
      name: "High Impact",
      description: "Service Use Severely Impaired - Critical functionality affected",
      response: "4 hours"
    },
    {
      level: "P3",
      name: "Medium Impact",
      description: "Service Use Partially Impaired - Non-critical functionality affected",
      response: "Next Business Day"
    },
    {
      level: "P4",
      name: "Low Impact",
      description: "Service Fully Usable - General guidance or information needed",
      response: "With in 3 working days"
    }
  ];

  const descriptionTemplate = `Please provide detailed information about the problem:

Time: [The specific timestamp when the issue began]
Product: [The product(s) and feature(s) associated with the issue]
Location: [The zone(s) where you are seeing the issue]
Identifiers: [The project/application ID and other concrete identifiers]
Useful artifacts: [Any details that can help us diagnose the issue]
Problem Type: [Is the problem intermittent, transient, or consistent]

What happened:
[Describe what actually occurred]

What you expected:
[Describe what you expected to happen]

Additional Context:
[Any other relevant information that could help us understand the issue]`;

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Here we'll add the Google Form submission logic
    // This will post to your existing Google Form endpoint
    // For now, let's just log the data
    console.log('Form submitted:', formData);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  return (
    <div className="max-w-4xl mx-auto bg-slate-900/50 backdrop-blur-xl rounded-xl border border-slate-800 p-8">
      <form onSubmit={handleSubmit} className="space-y-8">
        {/* Contact Information */}
        <div className="space-y-6">
          <div className="flex items-center space-x-2 mb-6">
            <Building2 className="w-5 h-5 text-blue-400" />
            <h3 className="text-lg font-semibold text-white">Contact Information</h3>
          </div>

          <div className="grid md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-slate-300 mb-2">
                Full Name *
              </label>
              <input
                type="text"
                name="name"
                required
                value={formData.name}
                onChange={handleInputChange}
                className="w-full bg-slate-700/50 border border-slate-600 rounded-lg px-4 py-2.5 text-white focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                placeholder="Your name"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-slate-300 mb-2">
                Business Email *
              </label>
              <input
                type="email"
                name="email"
                required
                value={formData.email}
                onChange={handleInputChange}
                className="w-full bg-slate-700/50 border border-slate-600 rounded-lg px-4 py-2.5 text-white focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                placeholder="your.email@company.com"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-slate-300 mb-2">
                Company Name *
              </label>
              <input
                type="text"
                name="company"
                required
                value={formData.company}
                onChange={handleInputChange}
                className="w-full bg-slate-700/50 border border-slate-600 rounded-lg px-4 py-2.5 text-white focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                placeholder="Your company name"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-slate-300 mb-2">
                Phone Number (Optional)
              </label>
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                className="w-full bg-slate-700/50 border border-slate-600 rounded-lg px-4 py-2.5 text-white focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                placeholder="+44"
              />
            </div>
          </div>
        </div>

        {/* Location Information */}
        <div className="space-y-6">
          <div className="flex items-center space-x-2 mb-6">
            <MapPin className="w-5 h-5 text-blue-400" />
            <h3 className="text-lg font-semibold text-white">Location</h3>
          </div>

          <div className="grid md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-slate-300 mb-2">
                Region *
              </label>
              <select
                name="region"
                required
                value={formData.region}
                onChange={handleInputChange}
                className="w-full bg-slate-700/50 border border-slate-600 rounded-lg px-4 py-2.5 text-white focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
              >
                <option value="">Select Region</option>
                {regions.map((region) => (
                  <option key={region} value={region}>{region}</option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-slate-300 mb-2">
                Country/City *
              </label>
              <input
                type="text"
                name="country"
                required
                value={formData.country}
                onChange={handleInputChange}
                className="w-full bg-slate-700/50 border border-slate-600 rounded-lg px-4 py-2.5 text-white focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                placeholder="United Kingdom, London"
              />
            </div>
          </div>
        </div>

        {/* Case Details */}
        <div className="space-y-6">
          <div className="flex items-center space-x-2 mb-6">
            <MessageSquare className="w-5 h-5 text-blue-400" />
            <h3 className="text-lg font-semibold text-white">Case Details</h3>
          </div>

          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-slate-300 mb-2">
                Priority Level *
                <button
                  type="button"
                  onClick={() => setShowPriorityInfo(!showPriorityInfo)}
                  className="ml-2 text-blue-400 hover:text-blue-300"
                >
                  <Info className="w-4 h-4 inline" />
                </button>
              </label>
              <select
                name="priority"
                required
                value={formData.priority}
                onChange={handleInputChange}
                className="w-full bg-slate-700/50 border border-slate-600 rounded-lg px-4 py-2.5 text-white focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
              >
                <option value="">Select Priority</option>
                {priorityLevels.map((priority) => (
                  <option key={priority.level} value={priority.level}>
                    {priority.level}: {priority.name}
                  </option>
                ))}
              </select>

              {showPriorityInfo && (
                <div className="mt-4 bg-slate-700/50 rounded-lg p-4 space-y-4">
                  {priorityLevels.map((priority) => (
                    <div key={priority.level} className="flex items-start space-x-3">
                      <div className="min-w-[3rem] font-semibold text-blue-400">
                        {priority.level}
                      </div>
                      <div>
                        <div className="font-medium text-white">{priority.name}</div>
                        <div className="text-sm text-slate-300">{priority.description}</div>
                        <div className="text-sm text-blue-400">Response time: {priority.response}</div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-slate-300 mb-2">
                Subject *
              </label>
              <input
                type="text"
                name="subject"
                required
                value={formData.subject}
                onChange={handleInputChange}
                className="w-full bg-slate-700/50 border border-slate-600 rounded-lg px-4 py-2.5 text-white focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                placeholder="Brief description of the issue"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-slate-300 mb-2">
                Description *
              </label>
              <textarea
                name="description"
                required
                value={formData.description || descriptionTemplate}
                onChange={handleInputChange}
                rows={10}
                className="w-full bg-slate-700/50 border border-slate-600 rounded-lg px-4 py-2.5 text-white focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
              />
            </div>
          </div>
        </div>

        {/* Marketing Consent */}
        <div className="flex items-start space-x-3">
          <input
            type="checkbox"
            name="marketingConsent"
            id="marketingConsent"
            checked={formData.marketingConsent}
            onChange={handleInputChange}
            className="mt-1"
          />
          <label htmlFor="marketingConsent" className="text-sm text-slate-300">
            Sign up for gClouds emails with news, product updates, event information, special offers and more.
          </label>
        </div>

        {/* Submit Button */}
        <div className="flex justify-end">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-600 text-white px-8 py-3 rounded-lg transition-colors flex items-center space-x-2"
          >
            <span>Submit Ticket</span>
            <ArrowRight className="w-4 h-4" />
          </button>
        </div>
      </form>
    </div>
  );
};

export default SupportForm;