import React, { useState } from 'react';
import { 
  Smartphone,
  Database,
  Check,
  CloudLightning,
  Code,
  Link,
  Layout,
  X
} from 'lucide-react';
import AIChatBox from '../../AIChatBox';

const ChatModal = ({ show, onClose, initialMessage }) => {
  if (!show) return null;
  return (
    <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
      <div className="bg-slate-900 rounded-xl max-w-4xl w-full">
        <div className="flex justify-between items-center p-6 border-b border-slate-800">
          <h2 className="text-2xl font-semibold text-white">Chat with Nia</h2>
          <button onClick={onClose} className="text-slate-400 hover:text-white">
            <X className="w-6 h-6" />
          </button>
        </div>
        <div className="p-6">
          <AIChatBox initialMessageModal={initialMessage} />
        </div>
      </div>
    </div>
  );
};

const AppsheetProduct = () => {
  const [showChatModal, setShowChatModal] = useState(false);
  const [chatMessage, setChatMessage] = useState('');
  const [billingCycle, setBillingCycle] = useState('annual');

  const handleChatWithNia = (plan) => {
    const message = `Hi Nia, I'm interested in AppSheet ${plan}. Could you help me understand the pricing and features for my organisation?`;
    setShowChatModal(true);
    setChatMessage(message);
  };

  const plans = {
    core: {
      name: 'AppSheet Core',
      monthly: 9.3,
      annual: 93,
      features: [
        'Unlimited apps',
        'Mobile and web deployment',
        'Spreadsheet and cloud data sources',
        'Basic workflow automation',
        'Standard security controls',
        '10GB storage per user',
        'Email support'
      ]
    },
    enterprise: {
      name: 'AppSheet Enterprise',
      features: [
        'Everything in Core, plus:',
        'Advanced security and compliance',
        'Custom branding',
        'Premium integrations',
        'Unlimited storage',
        'Advanced workflow automation',
        'Priority support',
        'Developer API access'
      ],
      highlighted: true
    }
  };

  const features = [
    {
      icon: Smartphone,
      title: 'Cross-Platform Apps',
      description: 'Build once, deploy everywhere - web, iOS, and Android'
    },
    {
      icon: Database,
      title: 'Smart Data Platform',
      description: 'Connect to spreadsheets, cloud databases, and APIs'
    },
    {
      icon: CloudLightning,
      title: 'AI-Powered',
      description: 'Intelligent automation and data capture capabilities'
    },
    {
      icon: Code,
      title: 'No-Code Required',
      description: 'Visual app builder for rapid development'
    },
    {
      icon: Link,
      title: 'Easy Integration',
      description: 'Seamless connection with Google Workspace'
    },
    {
      icon: Layout,
      title: 'Custom UI',
      description: 'Branded interface with drag-and-drop design'
    }
  ];

  return (
    <div className="min-h-screen bg-slate-950">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
            Build Mobile & Web Apps Without Code
          </h1>
          <p className="text-xl text-slate-400 max-w-3xl mx-auto">
            Transform your business with custom applications built using AppSheet's no-code platform
          </p>
        </div>

        {/* Feature Grid */}
        <div className="grid md:grid-cols-3 gap-8 mb-16">
          {features.map((feature, index) => (
            <div
              key={index}
              className="bg-slate-800/50 backdrop-blur-xl p-6 rounded-xl border border-slate-700 hover:border-blue-500/50 transition-all"
            >
              <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center mb-4">
                <feature.icon className="w-6 h-6 text-blue-400" />
              </div>
              <h3 className="text-lg font-semibold text-white mb-2">{feature.title}</h3>
              <p className="text-slate-400">{feature.description}</p>
            </div>
          ))}
        </div>

        {/* Pricing Section */}
        <div className="mb-16">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-white mb-4">Simple, Flexible Pricing</h2>
            <p className="text-slate-400">Choose the plan that's right for your organisation</p>
          </div>

          {/* Billing Toggle */}
          <div className="flex justify-center mb-12">
            <div className="relative inline-flex rounded-lg border border-slate-700 p-1 bg-slate-800">
              <button
                onClick={() => setBillingCycle('monthly')}
                className={`px-4 py-2 rounded-md text-sm font-medium transition-colors relative z-10 
                  ${billingCycle === 'monthly' ? 'text-white' : 'text-slate-400'}`}
              >
                Monthly
              </button>
              <button
                onClick={() => setBillingCycle('annual')}
                className={`px-4 py-2 rounded-md text-sm font-medium transition-colors relative z-10
                  ${billingCycle === 'annual' ? 'text-white' : 'text-slate-400'}`}
              >
                Annual
                <span className="absolute -top-2 -right-2 bg-blue-500 text-xs text-white px-2 py-0.5 rounded-full">
                  Save 20%
                </span>
              </button>
              <div
                className="absolute inset-0 h-full bg-blue-500 rounded-md transition-transform duration-200"
                style={{
                  width: '50%',
                  transform: `translateX(${billingCycle === 'annual' ? '100%' : '0'})`
                }}
              />
            </div>
          </div>

          {/* Plan Cards */}
          <div id="appsheet-core" className="grid md:grid-cols-2 gap-8">
            {/* Core Plan */}
            <div className="bg-slate-800/50 backdrop-blur-xl rounded-xl p-8 border border-slate-700">
              <h3 className="text-2xl font-semibold text-white mb-4">{plans.core.name}</h3>
              
              <div className="mb-8">
                <div className="text-3xl font-bold text-white">
                  £{billingCycle === 'monthly' ? plans.core.monthly : (plans.core.annual/12).toFixed(2)}
                  <span className="text-base font-normal text-slate-400"> per user/month</span>
                </div>
                {billingCycle === 'annual' && (
                  <div className="text-sm text-slate-400">billed annually at £{plans.core.annual}/user</div>
                )}
              </div>

              <ul className="space-y-4 mb-8">
                {plans.core.features.map((feature, index) => (
                  <li key={index} className="flex items-start space-x-3">
                    <Check className="w-5 h-5 text-blue-400 mt-0.5" />
                    <span className="text-slate-300">{feature}</span>
                  </li>
                ))}
              </ul>

              <button
                onClick={() => handleChatWithNia('Core')}
                className="w-full bg-blue-500 hover:bg-blue-600 text-white px-6 py-3 rounded-lg transition-colors"
              >
                Get Started
              </button>
            </div>

            {/* Enterprise Plan */}
            <div className="bg-blue-500/10 backdrop-blur-xl rounded-xl p-8 border-2 border-blue-500 relative">
              <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
                <span className="bg-blue-500 text-white px-4 py-1 rounded-full text-sm">
                  Most Popular
                </span>
              </div>

              <h3 className="text-2xl font-semibold text-white mb-4">{plans.enterprise.name}</h3>
              
              <div className="mb-8">
                <div className="text-3xl font-bold text-white">
                  Custom Pricing
                </div>
                <div className="text-sm text-slate-400">tailored to your needs</div>
              </div>

              <ul className="space-y-4 mb-8">
                {plans.enterprise.features.map((feature, index) => (
                  <li key={index} className="flex items-start space-x-3">
                    <Check className="w-5 h-5 text-blue-400 mt-0.5" />
                    <span className="text-slate-300">{feature}</span>
                  </li>
                ))}
              </ul>

              <button
                onClick={() => handleChatWithNia('Enterprise')}
                className="w-full bg-blue-500 hover:bg-blue-600 text-white px-6 py-3 rounded-lg transition-colors"
              >
                Chat with Nia
              </button>
            </div>
          </div>
        </div>

        {/* Bottom CTA */}
        <div className="text-center bg-slate-800/50 rounded-xl p-8 border border-slate-700">
          <h2 className="text-2xl font-semibold text-white mb-4">
            Ready to start building?
          </h2>
          <p className="text-slate-400 mb-6">
            Our team is ready to help you create the perfect custom applications for your organisation
          </p>
          <button
            onClick={() => handleChatWithNia('Custom')}
            className="bg-blue-500 hover:bg-blue-600 text-white px-8 py-3 rounded-lg transition-colors"
          >
            Talk to Nia
          </button>
        </div>
      </div>
      <ChatModal 
        show={showChatModal}
        onClose={() => setShowChatModal(false)}
        initialMessage={chatMessage}
        />
    </div>
  );
};

export default AppsheetProduct;