import React, { useState } from 'react';
import {
  FileCode2,
  Shield,
  Lock,
  Server,
  Database,
  Code,
  GitBranch,
  CheckCircle,
  ArrowRight,
  Settings,
  Zap,
  Users,
  X
} from 'lucide-react';
import AIChatBox from '../../AIChatBox';

const ChatModal = ({ show, onClose, initialMessage }) => {
  if (!show) return null;
  return (
    <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
      <div className="bg-slate-900 rounded-xl max-w-4xl w-full">
        <div className="flex justify-between items-center p-6 border-b border-slate-800">
          <h2 className="text-2xl font-semibold text-white">Chat with Nia</h2>
          <button onClick={onClose} className="text-slate-400 hover:text-white">
            <X className="w-6 h-6" />
          </button>
        </div>
        <div className="p-6">
          <AIChatBox initialMessageModal={initialMessage} />
        </div>
      </div>
    </div>
  );
};

const SmartContracts = () => {
  const [showChatModal, setShowChatModal] = useState(false);
  const [chatMessage, setChatMessage] = useState('');

  const handleChatWithNia = (topic) => {
    const message = `Hi Nia, I'm interested in ${topic}. Could you tell me more about your blockchain development services and how you could help us implement smart contracts on Google Cloud?`;
    setShowChatModal(true);
    setChatMessage(message);
  };

  const features = [
    {
      icon: FileCode2,
      title: "Smart Contract Development",
      description: "Custom smart contract development using Solidity and other blockchain platforms"
    },
    {
      icon: Shield,
      title: "Security & Auditing",
      description: "Comprehensive security auditing and testing protocols"
    },
    {
      icon: Server,
      title: "GCP Infrastructure",
      description: "Scalable blockchain nodes on Google Cloud Platform"
    },
    {
      icon: Database,
      title: "Data Integration",
      description: "Seamless integration with existing systems and databases"
    },
    {
      icon: GitBranch,
      title: "Version Control",
      description: "Robust version control and deployment pipelines"
    },
    {
      icon: Lock,
      title: "Security Controls",
      description: "Enterprise-grade security and access controls"
    }
  ];

  const services = [
    {
      title: "Development",
      description: "End-to-end smart contract development",
      points: [
        "Custom contract development",
        "Business logic implementation",
        "Token creation and management",
        "Integration with existing systems"
      ]
    },
    {
      title: "Testing & Auditing",
      description: "Comprehensive security testing",
      points: [
        "Security audits",
        "Vulnerability assessment",
        "Performance testing",
        "Gas optimisation"
      ]
    },
    {
      title: "Deployment",
      description: "Secure deployment and management",
      points: [
        "Multi-chain deployment",
        "Network management",
        "Monitoring setup",
        "Maintenance support"
      ]
    }
  ];

  const capabilities = [
    {
      icon: Code,
      title: "Technical Expertise",
      points: [
        "Solidity development",
        "Smart contract auditing",
        "Multi-chain development",
        "Gas optimisation"
      ]
    },
    {
      icon: Shield,
      title: "Security Focus",
      points: [
        "Security-first design",
        "Regular audits",
        "Best practices",
        "Vulnerability testing"
      ]
    },
    {
      icon: Server,
      title: "Infrastructure",
      points: [
        "High availability",
        "Scalable architecture",
        "Performance monitoring",
        "Automated backups"
      ]
    }
  ];

  const useCases = [
    {
      title: "DeFi Applications",
      description: "Decentralized finance solutions",
      features: [
        "Lending protocols",
        "Token swaps",
        "Yield farming",
        "Liquidity pools"
      ]
    },
    {
      title: "Supply Chain",
      description: "Supply chain tracking and verification",
      features: [
        "Asset tracking",
        "Provenance verification",
        "Smart logistics",
        "Automated payments"
      ]
    },
    {
      title: "Digital Assets",
      description: "Digital asset management and NFTs",
      features: [
        "Token creation",
        "Asset management",
        "Marketplace integration",
        "Royalty management"
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-slate-950">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
            Smart Contract Development
          </h1>
          <p className="text-xl text-slate-400 max-w-3xl mx-auto mb-8">
            Secure and scalable smart contract solutions powered by Google Cloud Platform
          </p>
          <button
            onClick={() => handleChatWithNia('smart contract development')}
            className="bg-blue-500 hover:bg-blue-600 text-white px-8 py-3 rounded-lg transition-colors"
          >
            Talk to Nia
          </button>
        </div>

        {/* Features Grid */}
        <div className="grid md:grid-cols-3 gap-8 mb-16">
          {features.map((feature, index) => (
            <div
              key={index}
              className="bg-slate-800/50 backdrop-blur-xl p-6 rounded-xl border border-slate-700 hover:border-blue-500/50 transition-all"
            >
              <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center mb-4">
                <feature.icon className="w-6 h-6 text-blue-400" />
              </div>
              <h3 className="text-lg font-semibold text-white mb-2">{feature.title}</h3>
              <p className="text-slate-400">{feature.description}</p>
            </div>
          ))}
        </div>

        {/* Services Section */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            Our Services
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {services.map((service, index) => (
              <div
                key={index}
                className="bg-slate-800/50 backdrop-blur-xl p-8 rounded-xl border border-slate-700"
              >
                <h3 className="text-xl font-semibold text-white mb-4">{service.title}</h3>
                <p className="text-slate-400 mb-6">{service.description}</p>
                <ul className="space-y-2">
                  {service.points.map((point, idx) => (
                    <li key={idx} className="flex items-start space-x-2 text-slate-300">
                      <CheckCircle className="w-4 h-4 text-blue-400 mt-1" />
                      <span>{point}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        {/* Capabilities Section */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            Technical Capabilities
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {capabilities.map((capability, index) => (
              <div
                key={index}
                className="bg-slate-800/50 backdrop-blur-xl p-8 rounded-xl border border-slate-700"
              >
                <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center mb-6">
                  <capability.icon className="w-6 h-6 text-blue-400" />
                </div>
                <h3 className="text-xl font-semibold text-white mb-4">{capability.title}</h3>
                <ul className="space-y-2">
                  {capability.points.map((point, idx) => (
                    <li key={idx} className="flex items-start space-x-2 text-slate-300">
                      <ArrowRight className="w-4 h-4 text-blue-400 mt-1" />
                      <span>{point}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        {/* Use Cases */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            Use Cases
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {useCases.map((useCase, index) => (
              <div
                key={index}
                className="bg-slate-800/50 backdrop-blur-xl p-8 rounded-xl border border-slate-700"
              >
                <h3 className="text-xl font-semibold text-white mb-4">{useCase.title}</h3>
                <p className="text-slate-400 mb-6">{useCase.description}</p>
                <ul className="space-y-2">
                  {useCase.features.map((feature, idx) => (
                    <li key={idx} className="flex items-start space-x-2 text-slate-300">
                      <CheckCircle className="w-4 h-4 text-blue-400 mt-1" />
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        {/* Development Process */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            Development Process
          </h2>
          <div className="max-w-4xl mx-auto">
            <div className="grid gap-8">
              {[
                {
                  step: "1",
                  title: "Requirements Analysis",
                  description: "Define contract specifications and requirements",
                  icon: Users
                },
                {
                  step: "2",
                  title: "Design & Architecture",
                  description: "Design secure and efficient smart contract architecture",
                  icon: Settings
                },
                {
                  step: "3",
                  title: "Development & Testing",
                  description: "Implementation with comprehensive testing",
                  icon: Code
                },
                {
                  step: "4",
                  title: "Deployment & Monitoring",
                  description: "Secure deployment with ongoing monitoring",
                  icon: Zap
                }
              ].map((phase, index) => (
                <div 
                  key={index}
                  className="relative flex items-start bg-slate-800/50 backdrop-blur-xl p-6 rounded-xl border border-slate-700"
                >
                  <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center flex-shrink-0 mr-6">
                    <phase.icon className="w-6 h-6 text-blue-400" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold text-white mb-2">{phase.title}</h3>
                    <p className="text-slate-400">{phase.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* CTA Section */}
        <div className="text-center bg-blue-500/10 rounded-xl p-8 border border-blue-500/50">
          <h2 className="text-2xl font-semibold text-white mb-4">
            Ready to Build Your Smart Contract Solution?
          </h2>
          <p className="text-slate-300 mb-6 max-w-2xl mx-auto">
            Let's discuss how we can help you implement secure and efficient smart contracts for your business.
          </p>
          <div className="flex flex-col sm:flex-row justify-center gap-4">
            <button
              onClick={() => handleChatWithNia('smart contract consultation')}
              className="bg-blue-500 hover:bg-blue-600 text-white px-8 py-3 rounded-lg transition-colors"
            >
              Chat with Nia
            </button>
            <button
              onClick={() => handleChatWithNia('blockchain demo')}
              className="bg-slate-700 hover:bg-slate-600 text-white px-8 py-3 rounded-lg transition-colors"
            >
              Request Demo
            </button>
          </div>
        </div>
      </div>
      <ChatModal 
        show={showChatModal}
        onClose={() => setShowChatModal(false)}
        initialMessage={chatMessage}
        />
    </div>
  );
};

export default SmartContracts;