import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { functions, db } from '../firebaseConfig';
import { httpsCallable } from 'firebase/functions';
import { doc, onSnapshot } from 'firebase/firestore';
import { Send, MessageSquare, HelpCircle, AlertCircle } from 'lucide-react';
import Cookies from 'js-cookie';
import MarkdownMessage from './MarkdownMessage';
import NiaAvatar from './NiaAvatar';
import ChatGuidance from './ChatGuidance';

const SESSION_COOKIE_NAME = 'gclouds_chat_session';
const COOKIE_EXPIRY = 365;
const MAX_RETRY_ATTEMPTS = 3;
const INITIAL_TIMEOUT = 6500; // 6.5 seconds for first attempt

const AIChatBox = ({ initialMessageModal }) => {
  const location = useLocation();
  const [inputMessage, setInputMessage] = useState(location.state?.initialMessage || initialMessageModal || '');
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const [error, setError] = useState(null);
  const [retryAttempt, setRetryAttempt] = useState(0);
  const messagesEndRef = useRef(null);
  const initialized = useRef(false);
  const messageContainerRef = useRef(null);
  const inputRef = useRef(null);
  const [functionVersion, setFunctionVersion] = useState('');
  const [activeTab, setActiveTab] = useState('chat');
  
  const scrollToBottom = () => {
    if (messageContainerRef.current && activeTab === 'chat') {
      messageContainerRef.current.scrollBy({ top: 100, behavior: 'smooth' });
    }
  };
  
  // scrollToBottomFull function
  const scrollToBottomFull = () => {
    if (messageContainerRef.current && activeTab === 'chat') {
      // Use requestAnimationFrame to ensure DOM updates first
      requestAnimationFrame(() => {
        messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
      });
    }
  };

  const isValidSessionId = (sessionId) => {
    const regex = /^session_\d+_[a-zA-Z0-9]+$/;
    return typeof sessionId === 'string' && regex.test(sessionId);
  };

  const callGemini = httpsCallable(functions, 'callGemini');

  // Create a function to filter messages
  const getDisplayMessages = (messages) => {
    if (!messages.length) return [];
    
    // Filter out temporary connection messages first
    messages = messages.filter(msg => !msg.isTemporary);
    
    // If the first message is a user message and it's empty, remove it
    if (
      messages.length > 0 &&
      messages[0].role === 'user' && 
      !messages[0].content.trim()
    ) {
      return messages.slice(1);
    }
    
    return messages;
  };

useEffect(() => {
  if (location.state?.initialMessage && sessionId) {
    handlenavigatedmessage();
  }
}, [sessionId]);

useEffect(() => {
  if (initialMessageModal && sessionId) {
    handleSubmit();
  }
}, [initialMessageModal, sessionId]);

useEffect(() => {
  if (location.state?.initialMessage && inputRef.current) {
    inputRef.current.scrollIntoView({ behavior: 'smooth' });
    inputRef.current.focus();
  }
}, [location.state?.initialMessage]);

useEffect(() => {
  if (messages.length > 0 && !isLoading && inputRef.current && window.innerWidth >= 768) {
    inputRef.current.focus();
  }
}, [messages, isLoading]);

useEffect(() => {
  let unsubscribe = null;
  
  const initializeSession = async () => {
    // Guard against multiple initialisations
    if (initialized.current) return;
    
    setIsLoading(true);
    setIsConnecting(true);
    
    try {
      // Cookie Management
      let sid = Cookies.get(SESSION_COOKIE_NAME);
      const timestamp = Date.now();
      
      if (!sid || !isValidSessionId(sid)) {
        sid = `session_${timestamp}_${Math.random().toString(36).substr(2, 9)}`;
        Cookies.set(SESSION_COOKIE_NAME, sid, { expires: COOKIE_EXPIRY });
      }
      
      // Set up realtime listener first for existing conversations
    try {
      const docRef = doc(db, 'conversations', sid);
      unsubscribe = onSnapshot(docRef, (doc) => {
        if (doc.exists()) {
          setMessages(doc.data().messages || []);
          scrollToBottom();
        }
      }, (firestoreError) => {
        console.warn('Firestore listener error:', firestoreError);
        // Continue anyways, we'll still try to initialize
      });
    } catch (listenerError) {
      console.warn('Error setting up Firestore listener:', listenerError);
      // Continue with initialization even if listener setup fails
    }
    
    // Begin retry logic for initialization
    let attempt = retryAttempt;
    let success = false;
    let result;
    
    // Show connecting message during initial connection
      setMessages([{
        role: 'model',
        content: `Connecting to Nia${attempt > 0 ? ` (attempt ${attempt + 1})` : ''}...`,
        timestamp: new Date().toISOString(),
        isWelcomeMessage: true,
        isTemporary: true
      }]);
      
      try {
        // Calculate timeout with exponential backoff
        const timeout = INITIAL_TIMEOUT + (2000 * Math.pow(2, attempt));
        
        // Race between the function call and a timeout
        const timeoutPromise = new Promise((_, reject) => 
          setTimeout(() => reject(new Error(`Request timed out after ${timeout}ms`)), timeout)
        );
        
        result = await Promise.race([
          callGemini({
            sessionId: sid,
            isInitializing: true
          }),
          timeoutPromise
        ]);
        
        success = true;
      } catch (error) {
        console.warn(`Initialization attempt ${attempt + 1} failed:`, error);
        
        // Increment retry counter
        attempt++;
        setRetryAttempt(attempt);
        
        if (attempt < MAX_RETRY_ATTEMPTS) {
          // Retry after a short delay
          setError(`Connecting to Nia... This may take a few seconds on first load.`);
          setIsLoading(false);
          setIsConnecting(false);
          
          // Wait before trying again
          setTimeout(() => {
            initialized.current = false;
            initializeSession();
          }, 1000);
          return;
        } else {
          throw new Error('Failed to connect after multiple attempts. Nia is helping other customers right now. Pelase try again later.');
        }
      }
      
      // If we got here, we succeeded
      setRetryAttempt(0);
      
      // To show function version
      if (result.functionVersion) {
        setFunctionVersion(result.functionVersion);
      }
  
      setSessionId(sid);
      
      // If we got messages directly from initialisation, use them
      if (result.messages) {
        // Replace any temporary messages with the real welcome message
        setMessages(result.messages);
        scrollToBottomFull(); // Full scroll for initialisation
      }
      
      initialized.current = true;
    } catch (error) {
      console.error('Chat initialisation error:', error);
      setError(error.message || 'Failed to initialize chat. Please refresh and try again.');
      initialized.current = false;
    } finally {
      setIsLoading(false);
      setIsConnecting(false);
    }
  };
  
  initializeSession();
  
  // Cleanup function
  return () => {
    if (unsubscribe) unsubscribe();
  };
}, [retryAttempt]);

const handlenavigatedmessage = async (e) => {
  if (e) {
    e.preventDefault();
    e.stopPropagation();
  }
  setIsLoading(false);
};

const handleSubmit = async (e) => {
  if (e) {
    e.preventDefault();
    e.stopPropagation();
  }
  
  const trimmedMessage = inputMessage.trim();
  if (!trimmedMessage || isLoading || messages.length === 0 || isConnecting) return;
  
  setInputMessage('');
  setIsLoading(true);
  setError(null);

    // Add user message to UI immediately and scroll
    const userMessage = {
      role: 'user',
      content: trimmedMessage,
      timestamp: new Date().toISOString()
    };
    
    // Update local messages immediately (optimistic UI update)
    setMessages(prevMessages => [...prevMessages, userMessage]);
    
    // Scroll fully to bottom after adding user message
    scrollToBottomFull();

  try {
    await callGemini({
      prompt: trimmedMessage,
      sessionId
    });
    
    // The Firestore listener will handle updating the messages
  } catch (error) {
    console.error('Error sending message:', error);
    setError('Failed to send message. Please refresh the page.');
  } finally {
    setIsLoading(false);
  }
};

  useEffect(() => {
    if (activeTab === 'chat') {
      scrollToBottom();
    }
  }, [messages, activeTab]);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };

  const handleRetry = () => {
    setError(null);
    initialized.current = false;
    setRetryAttempt(0);
  };

  return (
    <div className="flex flex-col bg-slate-900 rounded-xl border border-slate-700/50 h-[500px] w-full max-w-3xl mx-auto shadow-lg">
      {/* Header Section */}
      <div className="flex items-center justify-between p-4 border-b border-slate-800 bg-slate-900/95">
        <div className="flex items-center space-x-3">
          <NiaAvatar 
            variant="header"
            state={isLoading || isConnecting ? 'thinking' : 'idle'} 
          />
          
          <div className="flex flex-col">
            <h2 className="text-sm font-medium text-white">Nia</h2>
            <p className="text-xs text-slate-400">
              Google Cloud Solutions Expert
              {functionVersion && <span className="ml-1">v{functionVersion}</span>}
            </p>
          </div>
        </div>
  
        {/* Online Status Indicator */}
        <div className="flex items-center space-x-2">
          <div className="flex items-center space-x-1.5">
            <div className={`w-2 h-2 rounded-full ${isConnecting ? 'bg-yellow-500' : 'bg-emerald-500'}`} />
            <span className="text-xs text-slate-400">{isConnecting ? 'Connecting' : 'Online'}</span>
          </div>
        </div>
      </div>

      {/* Tabs Navigation */}
      <div className="flex border-b border-slate-800 bg-slate-900">
        <button 
          onClick={() => setActiveTab('chat')}
          className={`flex items-center space-x-2 px-4 py-3 font-medium text-sm transition-colors
            ${activeTab === 'chat' 
              ? 'text-blue-400 border-b-2 border-blue-400' 
              : 'text-slate-400 hover:text-slate-300'}`}
        >
          <MessageSquare className="w-4 h-4" />
          <span>Chat</span>
        </button>
        <button 
          onClick={() => setActiveTab('help')}
          className={`flex items-center space-x-2 px-4 py-3 font-medium text-sm transition-colors
            ${activeTab === 'help' 
              ? 'text-blue-400 border-b-2 border-blue-400' 
              : 'text-slate-400 hover:text-slate-300'}`}
        >
          <HelpCircle className="w-4 h-4" />
          <span>How Nia Can Help</span>
        </button>
      </div>
  
  {/* Tab Content */}
  <div ref={messageContainerRef} className="flex-1 overflow-y-auto">
    {activeTab === 'chat' ? (
      // Chat Messages Container
      <div 
        className="px-4 py-4 space-y-4 scrollbar-thin scrollbar-thumb-slate-700 scrollbar-track-transparent"
      >
        {/* Connecting State - First load, no messages yet */}
        {messages.length === 0 && isConnecting && (
          <div className="flex flex-col items-center justify-center h-64">
            <div className="animate-pulse">
              <NiaAvatar 
                variant="header"
                state="thinking"
              />
            </div>
            <p className="mt-4 text-slate-300 text-center">
              Connecting to Nia...
            </p>
            <p className="text-xs text-slate-400 mt-2 text-center">
              This may take a moment on first load
            </p>
          </div>
        )}

        {/* Connection Message vs Error Message Display */}
        {error && (
          error.includes('Connecting to Nia') ? (
            <div className="bg-blue-500/10 border border-blue-500/20 text-blue-400 p-3 rounded-lg text-sm flex items-center justify-between">
              <div className="flex items-center">
                <span className="inline-block w-4 h-4 mr-2 border-2 border-blue-400 border-t-transparent rounded-full animate-spin"></span>
                <span>{error}</span>
              </div>
              <button
                onClick={handleRetry}
                className="bg-blue-500/20 hover:bg-blue-500/30 text-blue-300 px-2 py-1 rounded text-xs transition-colors"
              >
                Retry Connection
              </button>
            </div>
          ) : (
            <div className="bg-red-500/10 border border-red-500/20 text-red-400 p-3 rounded-lg text-sm flex items-center justify-between">
              <div className="flex items-center">
                <AlertCircle className="w-4 h-4 mr-2 flex-shrink-0" />
                <span>{error}</span>
              </div>
              <button
                onClick={handleRetry}
                className="bg-red-500/20 hover:bg-red-500/30 text-red-300 px-2 py-1 rounded text-xs transition-colors"
              >
                Retry Connection
              </button>
            </div>
          )
        )}
        
        {/* Message Bubbles */}
        {getDisplayMessages(messages).map((message, index) => {
          const isUser = message.role === 'user';
          
          return isUser ? (
            // User Messages - Right aligned with no left spacing
            <div key={index} className="flex justify-end">
              <div className="max-w-[90%] rounded-lg px-4 py-3 bg-blue-500 text-white">
                <MarkdownMessage 
                  content={message.content} 
                  isUser={true} 
                />
              </div>
            </div>
          ) : (
            // AI Messages - Left aligned with avatar
            <div key={index} className="flex items-start space-x-2">
              <NiaAvatar 
                variant="message"
                state={message.isStreaming ? 'speaking' : 'idle'}
              />
              <div className="max-w-[90%] rounded-lg px-4 py-3 bg-slate-800 text-slate-100">
                <MarkdownMessage 
                  content={message.content} 
                  isUser={false} 
                />
              </div>
            </div>
          );
        })}
        
        {/* Typing Indicator */}
        {isLoading && !messages.some(m => m.isStreaming) && (
          <div className="flex items-start space-x-2">
            <NiaAvatar 
              variant="message"
              state="thinking"
            />
            <div className="bg-slate-800 rounded-lg px-4 py-3">
              <div className="flex space-x-1">
                <div className="w-1.5 h-1.5 bg-slate-400 rounded-full animate-bounce" />
                <div className="w-1.5 h-1.5 bg-slate-400 rounded-full animate-bounce delay-150" />
                <div className="w-1.5 h-1.5 bg-slate-400 rounded-full animate-bounce delay-300" />
              </div>
            </div>
          </div>
        )}
        
        <div ref={messagesEndRef} className="h-0" />
      </div>
    ) : (
      // Help Tab Content - Using enhanced ChatGuidance component
      <div className="p-6">
        <ChatGuidance enhanced={true} />
      </div>
    )}
      </div>
  
    {/* Input Area - Only show when in chat tab */}
    {activeTab === 'chat' && (
      <div className="sticky bottom-0 p-4 border-t border-slate-800 bg-slate-900/95">
        <form onSubmit={handleSubmit} className="flex space-x-3">
          <input
            ref={inputRef}
            type="text"
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder={isConnecting ? "Connecting to Nia..." : "Share your business needs..."}
            className="flex-1 bg-slate-800 border border-slate-700 rounded-lg px-4 py-2.5 text-sm text-white
              placeholder-slate-400 focus:outline-none focus:ring-1 focus:ring-slate-600
              transition-all duration-200"
            disabled={isLoading || isConnecting || messages.length === 0}
          />
          <button
            type="submit"
            disabled={isLoading || !inputMessage.trim() || isConnecting || messages.length === 0}
            className="bg-blue-500 hover:bg-blue-600 disabled:bg-slate-700 disabled:cursor-not-allowed
              text-white p-2.5 rounded-lg transition-colors duration-200
              flex items-center justify-center"
          >
            <Send className="w-4 h-4" />
          </button>
        </form>
      </div>
    )}
    </div>
  );
};

export default AIChatBox;