// src/CallDemo.js
import React from 'react';
import NiaLiveCall from '../components/NiaCall/NiaLiveCall';
import { LiveAPIProvider } from '../components/NiaCall/LiveAPIContext';

const CallDemo = () => {
  return (
    <LiveAPIProvider>
      <div className="container mx-auto py-8">
        <h1 className="text-2xl font-bold mb-4 text-center">Nia Voice Call Demo</h1>
        <p className="text-center mb-8">Click the call button to start talking with Nia</p>
        
        <NiaLiveCall />
        
        <p className="text-sm text-gray-500 text-center mt-8">
          Note: This uses the Gemini Live API which is in experimental stage. 
          For best results, use Chrome on desktop or Android.
        </p>
      </div>
    </LiveAPIProvider>
  );
};

export default CallDemo;