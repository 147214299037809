// src/solutions/infrastructure/migration/index.js
import React, { useState } from 'react';
import AIChatBox from '../../../components/AIChatBox';
import { 
  X,
  CheckCircle,
  Layers,
  GitMerge,
  FileCode
} from 'lucide-react';

const ChatModal = ({ show, onClose, initialMessage }) => {
  if (!show) return null;
  return (
    <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
      <div className="bg-slate-900 rounded-xl max-w-4xl w-full">
        <div className="flex justify-between items-center p-6 border-b border-slate-800">
          <h2 className="text-2xl font-semibold text-white">Chat with Nia</h2>
          <button onClick={onClose} className="text-slate-400 hover:text-white">
            <X className="w-6 h-6" />
          </button>
        </div>
        <div className="p-6">
          <AIChatBox initialMessageModal={initialMessage} />
        </div>
      </div>
    </div>
  );
};

const Migration = () => {
  const [showChatModal, setShowChatModal] = useState(false);
  const [chatMessage, setChatMessage] = useState('');

  const handleChatWithNia = () => {
    const message = `Hi Nia, I'm interested in migrating applications to Google Cloud. Could you tell me more about your Migration Factory approach and assessment tools?`;
    setShowChatModal(true);
    setChatMessage(message);
  };

  const approaches = [
    {
      icon: GitMerge,
      title: "Migration Factory",
      description: "Industrialised approach to cloud migration with standardised processes and automation"
    },
    {
      icon: Layers,
      title: "Migration Waves",
      description: "Phased migration approach with structured workload prioritisation"
    },
    {
      icon: FileCode,
      title: "Automated Assessment",
      description: "Comprehensive discovery and dependency mapping tools"
    }
  ];

  const features = [
    {
      title: "Assessment & Planning",
      points: [
        "Cloud readiness assessment",
        "TCO and ROI analysis",
        "Migration strategy development",
        "Dependency mapping",
        "Application portfolio analysis",
        "Risk assessment"
      ]
    },
    {
      title: "Migration Tools",
      points: [
        "Migrate for Compute Engine",
        "Migrate for Anthos",
        "Cloud Storage Transfer Service",
        "Database Migration Service",
        "Striim for real-time migration",
        "Validation tools"
      ]
    }
  ];

  const strategies = [
    {
      title: "Rehost (Lift and Shift)",
      description: "Move applications without major changes",
      points: [
        "VM migration with Migrate for Compute Engine",
        "Minimal application changes",
        "Rapid cloud adoption",
        "Preserved application state"
      ]
    },
    {
      title: "Replatform (Lift and Optimise)",
      description: "Optimise applications during migration",
      points: [
        "Containerisation with Cloud Run",
        "Database modernisation",
        "Cloud-native services integration",
        "Performance optimisation"
      ]
    },
    {
      title: "Refactor (Cloud Native)",
      description: "Modernise applications for cloud architecture",
      points: [
        "Microservices architecture",
        "Kubernetes deployment",
        "Serverless computing",
        "API modernisation"
      ]
    }
  ];

  const waves = [
    {
      title: "Wave Planning",
      points: [
        "Application grouping",
        "Dependency analysis",
        "Resource allocation",
        "Timeline planning"
      ]
    },
    {
      title: "Wave Execution",
      points: [
        "Parallel migrations",
        "Progress tracking",
        "Risk mitigation",
        "Success validation"
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-slate-950">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <div className="flex justify-center mb-8">
            <img 
              src="/images/google-cloud-partner.png" 
              alt="Google Cloud Partner" 
              className="h-16 md:h-20"
            />
          </div>
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
            Application Migration
          </h1>
          <p className="text-xl text-slate-400 max-w-3xl mx-auto mb-8">
            Accelerate your journey to Google Cloud with our industrialised Migration Factory approach
          </p>
          <button
            onClick={handleChatWithNia}
            className="bg-blue-500 hover:bg-blue-600 text-white px-8 py-3 rounded-lg transition-colors"
          >
            Talk to Nia
          </button>
        </div>

        {/* Approaches Grid */}
        <div className="grid md:grid-cols-3 gap-8 mb-16">
          {approaches.map((approach, index) => (
            <div
              key={index}
              className="bg-slate-900/50 backdrop-blur-xl p-6 rounded-xl border border-slate-800 hover:border-blue-500/50 transition-all"
            >
              <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center mb-4">
                <approach.icon className="w-6 h-6 text-blue-400" />
              </div>
              <h3 className="text-lg font-semibold text-white mb-2">{approach.title}</h3>
              <p className="text-slate-400">{approach.description}</p>
            </div>
          ))}
        </div>

        {/* Features Grid */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            Migration Features
          </h2>
          <div className="grid md:grid-cols-2 gap-8">
            {features.map((feature, index) => (
              <div
                key={index}
                className="bg-slate-900/50 backdrop-blur-xl p-8 rounded-xl border border-slate-800"
              >
                <h3 className="text-xl font-semibold text-white mb-4">{feature.title}</h3>
                <ul className="space-y-2">
                  {feature.points.map((point, idx) => (
                    <li key={idx} className="flex items-start space-x-2 text-slate-300">
                      <CheckCircle className="w-4 h-4 text-blue-400 mt-1" />
                      <span>{point}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        {/* Migration Strategies */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            Migration Strategies
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {strategies.map((strategy, index) => (
              <div
                key={index}
                className="bg-slate-900/50 backdrop-blur-xl p-8 rounded-xl border border-slate-800"
              >
                <h3 className="text-xl font-semibold text-white mb-2">{strategy.title}</h3>
                <p className="text-slate-400 mb-4">{strategy.description}</p>
                <ul className="space-y-2">
                  {strategy.points.map((point, idx) => (
                    <li key={idx} className="flex items-start space-x-2 text-slate-300">
                      <CheckCircle className="w-4 h-4 text-blue-400 mt-1" />
                      <span>{point}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        {/* Migration Waves */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            Wave-Based Migration
          </h2>
          <div className="grid md:grid-cols-2 gap-8">
            {waves.map((wave, index) => (
              <div
                key={index}
                className="bg-slate-900/50 backdrop-blur-xl p-8 rounded-xl border border-slate-800"
              >
                <h3 className="text-xl font-semibold text-white mb-4">{wave.title}</h3>
                <ul className="space-y-2">
                  {wave.points.map((point, idx) => (
                    <li key={idx} className="flex items-start space-x-2 text-slate-300">
                      <CheckCircle className="w-4 h-4 text-blue-400 mt-1" />
                      <span>{point}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        {/* CTA Section */}
        <div className="text-center bg-blue-500/10 rounded-xl p-8 border border-blue-500/50">
          <h2 className="text-2xl font-semibold text-white mb-4">
            Ready to Start Your Migration Journey?
          </h2>
          <p className="text-slate-300 mb-6 max-w-2xl mx-auto">
            Let's discuss your migration goals and create a tailored strategy for your organisation.
          </p>
          <button
            onClick={handleChatWithNia}
            className="bg-blue-500 hover:bg-blue-600 text-white px-8 py-3 rounded-lg transition-colors"
          >
            Chat with Nia
          </button>
        </div>
      </div>

      <ChatModal 
        show={showChatModal}
        onClose={() => setShowChatModal(false)}
        initialMessage={chatMessage}
      />
    </div>
  );
};

export default Migration;