import React, { useState } from 'react';
import { Menu, X } from 'lucide-react';
import NavItem from './navigation/NavItem';
import MobileNav from './navigation/MobileNav';
import { menuData } from './navigation/menuData';

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);

  // Navigation items with mega menu data
  const navItems = [
    { title: 'Overview', hasMegaMenu: true, menuData: menuData.overview },
    { title: 'Solutions', hasMegaMenu: true, menuData: menuData.solutions },
    { title: 'Products', hasMegaMenu: true, menuData: menuData.products },
    { title: 'Services', hasMegaMenu: true, menuData: menuData.services },
    { title: 'Contact', href: '/contact' },
    { title: 'Support', href: '/support' },
    { title: 'AppSheet Apps', href: '/solutions/application/appsheet' },
    { title: 'AI', href: '/ai' }
  ];

  return (
    <nav className="bg-slate-900 border-b border-slate-800">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          {/* Logo */}
          <div className="flex-shrink-0 flex items-center">
            <a href="/" className="flex items-center space-x-2">
              <img
                src="/images/gClouds-logo.png"
                alt="gClouds"
                className="h-8 w-auto"
              />
            </a>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden md:block">
            <div className="ml-10 flex items-baseline space-x-4">
              {navItems.map((item) => (
                <NavItem
                  key={item.title}
                  title={item.title}
                  icon={item.icon}
                  hasMegaMenu={item.hasMegaMenu}
                  href={item.href}
                  menuData={item.menuData}
                />
              ))}
            </div>
          </div>

          {/* Mobile menu button */}
          <div className="md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-slate-800 focus:outline-none"
            >
              <span className="sr-only">Open main menu</span>
              {isOpen ? (
                <X className="h-6 w-6" />
              ) : (
                <Menu className="h-6 w-6" />
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      {isOpen && (
        <div className="md:hidden bg-slate-800">
          <div className="px-2 pt-2 pb-3 space-y-1">
            <a
              href="/contact"
              className="bg-blue-500 text-white block px-3 py-2 rounded-md text-base font-medium"
            >
              Contact Us
            </a>
            <MobileNav isOpen={isOpen} onClose={() => setIsOpen(false)} /> {/* MobileNav moved inside */}
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navigation;