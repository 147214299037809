import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Brain, Cloud, MessageSquare, Zap, ArrowRight } from 'lucide-react';

const navigateToSection = (navigate, path, sectionId) => {
  navigate(path);
  setTimeout(() => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, 100); // Delay to allow page transition
};

const AILanding = () => {
  const navigate = useNavigate();

  const solutions = [
    {
      title: "AI Chat Solutions",
      description: "Intelligent chatbots with human-like interactions",
      link: "/solutions/ai-chat",
      icon: MessageSquare
    },
    {
      title: "GenAI Implementation",
      description: "Transform operations with cutting-edge AI",
      link: "/solutions/gen-ai",
      icon: Brain
    },
    {
      title: "AI Consulting",
      description: "Strategic guidance for AI adoption",
      link: "/services/ai-consulting",
      icon: Cloud
    },
    {
      title: "GenAI Automation",
      description: "Streamline processes with AI automation",
      link: "/services/gen-ai-automate",
      icon: Zap
    }
  ];

  const features = [
    {
      title: "Document Understanding",
      description: "Process and analyze documents intelligently"
    },
    {
      title: "Context Awareness",
      description: "Maintain conversation context and history"
    },
    {
      title: "Multi-Channel Support",
      description: "Deploy across web and mobile platforms"
    }
  ];

  return (
    <div className="min-h-screen bg-slate-950">
      <div className="max-w-7xl mx-auto px-4 py-12">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <div className="flex justify-center mb-8">
            <img src="/images/google-cloud-partner.png" alt="Google Cloud Partner" className="h-20" />
          </div>
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
            Transform Your Business with AI
          </h1>
          <p className="text-xl text-slate-400 max-w-3xl mx-auto mb-8">
            Experience the power of AI with Nia, your virtual sales assistant
          </p>
        </div>

        {/* Solutions Grid */}
        <div className="grid md:grid-cols-2 gap-8 mb-16">
          {solutions.map((solution, index) => (
            <div
              key={index}
              onClick={() => navigate(solution.link)}
              className="bg-slate-800/50 backdrop-blur-xl p-8 rounded-xl border border-slate-700 hover:border-blue-500 transition-all cursor-pointer"
            >
              <div className="flex items-start space-x-4">
                <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center">
                  <solution.icon className="w-6 h-6 text-blue-400" />
                </div>
                <div>
                  <h3 className="text-xl font-semibold text-white mb-2">{solution.title}</h3>
                  <p className="text-slate-400 mb-4">{solution.description}</p>
                  <div className="flex items-center text-blue-400 hover:text-blue-300">
                    <span>Learn more</span>
                    <ArrowRight className="w-4 h-4 ml-2" />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Features Grid */}
        <div className="grid md:grid-cols-3 gap-8 mb-16">
          {features.map((feature, index) => (
            <div key={index} className="bg-slate-800/50 backdrop-blur-xl p-6 rounded-xl border border-slate-700">
              <h3 className="text-lg font-semibold text-white mb-2">{feature.title}</h3>
              <p className="text-slate-400">{feature.description}</p>
            </div>
          ))}
        </div>

        {/* CTA Section */}
        <div className="text-center bg-blue-500/10 rounded-xl p-8 border border-blue-500/50">
          <h2 className="text-2xl font-semibold text-white mb-4">
            Ready to Start Your AI Journey?
          </h2>
          <p className="text-slate-300 mb-6 max-w-2xl mx-auto">
            Experience the future of business with our AI solutions
          </p>
          <button
            onClick={() => navigateToSection(navigate, "/services/ai-consulting", "chat")}
            className="bg-blue-500 hover:bg-blue-600 text-white px-8 py-3 rounded-lg transition-colors"
          >
            Schedule a Consultation
          </button>
        </div>
      </div>
    </div>
  );
};

export default AILanding;