import { 
  Cloud, 
  Database, 
  Shield, 
  Users, 
  Mail,
  Smartphone,
  Brain,
  Monitor,
  Building2,
  Settings,
  Scale,
  ArrowRight,
  Server,
  MessagesSquare,
  PanelTopOpen,
  Cylinder,
  Projector,
  MessageCircleCode,
  PackageOpen,
  ArrowsUpFromLine,
  Link,
  Handshake,
  Building,
  BrainCircuit,
  BadgePoundSterling,
} from 'lucide-react';

export const menuData = {
  overview: {
    title: 'Overview',
    links: [
      { id: 'company', title: 'Company' },
      { id: 'use-cases', title: 'Use Cases' },
      { id: 'stories', title: 'Success Stories' },
      { id: 'why-gclouds', title: 'Why gClouds' }
    ],
    sections: [
      {
        id: 'company',
        title: 'About gClouds',
        items: [
          {
            icon: Building2,
            title: 'Google Cloud UK & Ireland Partner',
            description: 'ISV/Technology, Service, and Reseller Partner',
            id: 'partnerships',
            link: 'https://cloud.google.com/find-a-partner/partner/gclouds-it-consultancy-ltd'
          },
          {
            icon: Mail,
            title: 'Location',
            description: 'Hatfield, Hertfordshire, United Kingdom',
            id: 'location',
            link: '/contact'
          }
        ]
      },
      {
        id: 'use-cases',
        title: 'Featured Solutions',
        items: [
          {
            icon: Cloud,
            title: 'GCP Landing Zone',
            description: 'Enterprise-grade security and compliance implementation',
            id: 'landing-zone',
            link: '/solutions/landing-zone'
          },
          {
            icon: Brain,
            title: 'AI Chat Agents',
            description: 'Intelligent website chat with appointment booking',
            id: 'chat-agents',
            link: '/solutions/ai-chat'
          },
          {
            icon: Smartphone,
            title: 'Mobile & Web Apps',
            description: 'Cross-platform applications for your business',
            id: 'app-development',
            link: '/solutions/app-development'
          }
        ]
      },
      {
        id: 'stories',
        title: 'Success Stories',
        items: [
          {
            icon: Building2,
            title: 'Enterprise DevSecOps',
            description: 'Major financial institution landing zone implementation',
            id: 'enterprise',
            link: '/success-stories/enterprise'
          },
          {
            icon: Scale,
            title: 'SMB Transformation',
            description: 'Legal sector digital archiving solution',
            id: 'smb',
            link: '/success-stories/smb'
          },
          {
            icon: ArrowRight,
            title: 'Migration Excellence',
            description: '2M+ emails migrated during holiday period',
            id: 'migration',
            link: '/success-stories/migration'
          }
        ]
      },
      {
        id: 'why-gclouds',
        title: 'Why Choose Us',
        items: [
          {
            icon: Handshake,
            title: 'Official Google Cloud Partner in UK & Ireland',
            description: 'ISV/Technology, Service, and Reseller Partner',
            id: 'partnerships',
            link: '/partnerships'
          },
          {
            icon: Shield,
            title: 'Certified Expertise',
            description: 'Google Cloud certified team with proven experience',
            id: 'expertise',
            link: '/expertise'
          },
          {
            icon: Server,
            title: '24/7 Support',
            description: 'Round-the-clock technical support and account management',
            id: 'support',
            link: '/support'
          }
        ]
      }
    ],
    features: [
      {
        title: 'Why Choose gClouds?',
        description: 'Discover how we can transform your business with Google Cloud',
        link: '/why-gclouds'
      },
      {
        title: 'Certified Expertise',
        description: 'Google Cloud certified team specializing in secure, scalable solutions',
        link: '/expertise'
      },
      {
        title: '24/7 Support',
        description: 'Round-the-clock technical support and dedicated account management',
        link: '/support'
      }
    ]
  },

  solutions: {
    title: 'Solutions',
    links: [
      { id: 'ai', title: 'Artificial Intelligence' },
      { id: 'application', title: 'Application Modernisation' },
      { id: 'blockchain', title: 'Blockchain' },
      { id: 'infrastructure', title: 'Infrastructure Modernisation' }
    ],
    sections: [
      {
        id: 'ai',
        items: [
          {
            icon: MessagesSquare,
            title: 'Chatbot Integration',
            description: 'Intelligent Advance Chatbots',
            id: 'chatbots',
            link: '/solutions/ai-chat'
          },
          {
            icon: BadgePoundSterling,
            title: 'AI Sales Assistant',
            description: 'Nia AI Sales Assistant',
            id: 'nia',
            link: '/nia'
          },
        ]
      },
      {
        id: 'application',
        items: [
          {
            icon: PackageOpen,
            title: 'Mobile App Development',
            description: 'Custom Mobile App Development',
            id: 'mobile-apps',
            link: '/solutions/app-development'
          },
          {
            icon: Smartphone,
            title: 'AppSheet',
            description: 'No-code application platform',
            id: 'appsheet',
            link: '/solutions/application/appsheet'
          },
        ]
      },
      {
        id: 'infrastructure',
        items: [
          {
            icon: ArrowsUpFromLine,
            title: 'Application Migraton',
            description: 'Legacy Application Migration',
            id: 'migration',
            link: '/solutions/infrastructure/migration'
          },
          {
            icon: Monitor,
            title: 'Virtual Desktops',
            description: 'Virtual machines on Google Cloud',
            id: 'virtual-desktops',
            link: '/solutions/infrastructure/virtual-desktops'
          },
          {
            icon: Database,
            title: 'Oracle on Google Cloud',
            description: 'Managed Oracle instances on Google Cloud',
            id: 'oracle',
            link: '/solutions/infrastructure/oracle'
          }
        ]
      },
      {
        id: 'blockchain',
        items: [
          {
            icon: Link,
            title: 'Smart Contracts',
            description: 'Smart Contract Development',
            id: 'smart-contracts',
            link: '/solutions/blockchain/smart-contracts'
          },
        ]
      },
    ],
    features: [
      {
        title: 'Featured: Generative AI',
        description: 'AI-powered characters to enhance your brand',
        link: '/solutions/gen-ai'
      }
    ]
  },

  products: {
    title: 'Products',
    links: [
      { id: 'workspace', title: 'Google Workspace' },
      { id: 'appsheet', title: 'AppSheet' },
      { id: 'cloud', title: 'Google Cloud Platform' },
      { id: 'marketplace', title: 'Google Cloud Marketplace' }
    ],
    sections: [
      {
        id: 'workspace',
        title: 'Workspace Solutions',
        items: [
          {
            icon: Handshake,
            title: 'Business Editions',
            description: 'For small to medium businesses',
            id: 'business-starter',
            link: '/products/workspace/business'
          },
          {
            icon: Building,
            title: 'Enterprise Editions',
            description: 'Premium security and advanced controls for unlimited users',
            id: 'business-standard',
            link: '/products/workspace/enterprise'
          }
        ]
      },
      {
        id: 'appsheet',
        title: 'AppSheet',
        items: [
          {
            icon: Smartphone,
            title: 'AppSheet Core',
            description: 'No-code application platform',
            id: 'appsheet-core',
            link: '/products/workspace/appsheet'
          },
          {
            icon: Settings,
            title: 'AppSheet Enterpise',
            description: 'Advanced features for large organisations',
            id: 'appsheet-enterprise',
            link: '/products/workspace/appsheet'
          }
        ]
      },
      {
        id: 'cloud',
        title: 'Google Cloud Platform',
        items: [
          {
            icon: Cylinder,
            title: 'Cloud Storage',
            description: 'Scalable object storage',
            id: 'storage',
            link: '/products/gcp/storage'
          },
          {
            icon: Server,
            title: 'Compute Engine',
            description: 'Virtual machines on Google Cloud',
            id: 'business-standard',
            link: '/products/gcp/compute-engine'
          }
        ]
      },
      {
        id: 'marketplace',
        title: 'Google Cloud Marketplace',
        items: [
          {
            icon: Database,
            title: 'MS SQL Servers',
            description: 'Managed SQL Server instances on Google Cloud',
            id: 'sql-server',
            link: '/products/marketplace/sql-server'
          },
          {
            icon: PanelTopOpen,
            title: 'Ethereum Develepor Suite',
            description: 'Blockchain development tools on Google Cloud',
            id: 'ethereum',
            link: '/products/marketplace/ethereum'
          }
        ]
      },
    ],

    features: [
      {
        title: 'Featured: Compare licenses',
        description: "Choose the plan that's right for your business",
        link: '/products/workspace/compare'
      },
      {
        title: 'Featured: AppSheet',
        description: 'Build apps without coding. Transform your business with custom mobile and web apps.',
        link: '/products/workspace/appsheet'
      }
    ]
  },

  services: {
    title: 'Services',
    links: [
      { id: 'professional', title: 'Professional Services' }
    ],
    sections: [
      {
        id: 'professional',
        title: 'Professional Services',
        items: [
          {
            icon: Users,
            title: 'Implementation',
            description: 'Expert deployment and migration services',
            id: 'implementation',
            link: '/services/implementation'
          },
          {
            icon: MessageCircleCode,
            title: 'Consulting',
            description: 'Strategic guidance and planning',
            id: 'consulting',
            link: '/services/consulting'
          },
          {
            icon: Brain,
            title: 'AI Consulting & Strategy',
            description: 'Not sure where to start with AI?',
            id: 'ai-consulting',
            link: '/services/ai-consulting'
          },
          {
            icon: Projector,
            title: 'Proof of Concept Development',
            description: 'Test the viability of your idea with a quick and cost-effective PoC',
            id: 'poc',
            link: '/services/poc'
          },
          {
            icon: Shield,
            title: 'Security & Compliance',
            description: 'Cloud Security & Compliance Services',
            id: 'security',
            link: '/services/security'
          },
          {
            icon: BrainCircuit,
            title: 'Automate with GenAI',
            description: 'Generative AI prototype development',
            id: 'genAI',
            link: '/services/gen-ai-automate'
          }
        ]
      },
    ],
    features: [
      {
        title: 'AI Consulting and Strategy',
        description: 'Getting Started with AI for your business',
        link: '/services/ai-consulting'
      }
    ]
  }
};

export default menuData;