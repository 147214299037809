import React, { useState } from 'react';
import {
  Brain,
  Shield,
  Server,
  Lock,
  GitBranch,
  CheckCircle,
  BarChart,
  Clock,
  X
} from 'lucide-react';
import AIChatBox from '../../AIChatBox';

const ChatModal = ({ show, onClose, initialMessage }) => {
  if (!show) return null;
  return (
    <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
      <div className="bg-slate-900 rounded-xl max-w-4xl w-full">
        <div className="flex justify-between items-center p-6 border-b border-slate-800">
          <h2 className="text-2xl font-semibold text-white">Chat with Nia</h2>
          <button onClick={onClose} className="text-slate-400 hover:text-white">
            <X className="w-6 h-6" />
          </button>
        </div>
        <div className="p-6">
          <AIChatBox initialMessageModal={initialMessage} />
        </div>
      </div>
    </div>
  );
};

const Enterprise = () => {
  const [showChatModal, setShowChatModal] = useState(false);
  const [chatMessage, setChatMessage] = useState('');

  const handleChatWithNia = (topic) => {
    const message = `Hi Nia, I'm impressed by your enterprise landing zone implementation success story. Could you tell me more about how you could help our organisation with a similar implementation?`;
    setShowChatModal(true);
    setChatMessage(message);
  };

  const achievements = [
    {
      icon: Shield,
      title: "Security & Compliance",
      description: "Full compliance with financial sector regulations and security standards"
    },
    {
      icon: Clock,
      title: "Deployment Speed",
      description: "Significantly reduced infrastructure provisioning time through automation"
    },
    {
      icon: Server, 
      title: "Cost Management",
      description: "Optimised infrastructure costs through efficient resource utilisation"
    },
    {
      icon: Brain,
      title: "Automation",
      description: "Automated security controls and governance with Infrastructure as Code"
    }
   ];

  const benefits = [
    {
      icon: Lock,
      title: "Enhanced Security",
      points: [
        "CIS benchmark compliance",
        "Automated security controls",
        "Comprehensive audit trails",
        "Advanced threat protection"
      ]
    },
    {
      icon: GitBranch,
      title: "Streamlined Operations",
      points: [
        "Automated provisioning",
        "Standardised environments",
        "Reduced manual effort",
        "Faster deployment cycles"
      ]
    },
    {
      icon: BarChart,
      title: "Business Impact",
      points: [
        "Reduced security risks",
        "Improved compliance",
        "Increased efficiency",
        "Cost optimisation"
      ]
    }
  ];

  const implementation = [
    {
      title: "Challenge",
      description: "A major financial institution needed to implement a secure, compliant, and scalable landing zone in Google Cloud to support their digital transformation initiatives.",
      points: [
        "Complex security requirements",
        "Strict compliance standards",
        "Large-scale deployment needs",
        "Tight implementation timeline"
      ]
    },
    {
      title: "Solution",
      description: "A comprehensive landing zone solution with automated security controls and standardised infrastructure deployment.",
      points: [
        "CIS-benchmarked VM images",
        "Automated CI/CD pipeline",
        "Infrastructure as Code",
        "DevSecOps practices"
      ]
    },
    {
      title: "Results",
      description: "A secure and compliant cloud foundation that accelerated the client's cloud adoption journey.",
      points: [
        "100% security compliance",
        "80% faster deployments",
        "Reduced operational costs",
        "Enhanced governance"
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-slate-950">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
            Enterprise Landing Zone Success Story
          </h1>
          <p className="text-xl text-slate-400 max-w-3xl mx-auto mb-8">
            How we helped a major financial institution build a secure and compliant cloud foundation
          </p>
          <button
            onClick={() => handleChatWithNia('enterprise implementation')}
            className="bg-blue-500 hover:bg-blue-600 text-white px-8 py-3 rounded-lg transition-colors"
          >
            Talk to Nia
          </button>
        </div>

        {/* Key Achievements */}
        <div className="grid md:grid-cols-4 gap-8 mb-16">
          {achievements.map((achievement, index) => (
            <div
              key={index}
              className="bg-slate-800/50 backdrop-blur-xl p-6 rounded-xl border border-slate-700"
            >
              <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center mb-4">
                <achievement.icon className="w-6 h-6 text-blue-400" />
              </div>
              <div className="text-3xl font-bold text-white mb-2">{achievement.value}</div>
              <div className="text-lg font-semibold text-white mb-2">{achievement.title}</div>
              <p className="text-slate-400">{achievement.description}</p>
            </div>
          ))}
        </div>

        {/* Implementation Story */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            Implementation Story
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {implementation.map((phase, index) => (
              <div
                key={index}
                className="bg-slate-800/50 backdrop-blur-xl p-8 rounded-xl border border-slate-700"
              >
                <h3 className="text-xl font-semibold text-white mb-4">{phase.title}</h3>
                <p className="text-slate-400 mb-6">{phase.description}</p>
                <ul className="space-y-3">
                  {phase.points.map((point, idx) => (
                    <li key={idx} className="flex items-start space-x-2">
                      <CheckCircle className="w-4 h-4 text-blue-400 mt-1" />
                      <span className="text-slate-300">{point}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        {/* Key Benefits */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            Key Benefits Delivered
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {benefits.map((benefit, index) => (
              <div
                key={index}
                className="bg-slate-800/50 backdrop-blur-xl p-8 rounded-xl border border-slate-700"
              >
                <div className="flex items-start space-x-4">
                  <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center flex-shrink-0">
                    <benefit.icon className="w-6 h-6 text-blue-400" />
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold text-white mb-4">{benefit.title}</h3>
                    <ul className="space-y-3">
                      {benefit.points.map((point, idx) => (
                        <li key={idx} className="flex items-start space-x-2">
                          <CheckCircle className="w-4 h-4 text-blue-400 mt-1" />
                          <span className="text-slate-300">{point}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* CTA Section */}
        <div className="text-center bg-blue-500/10 rounded-xl p-8 border border-blue-500/50">
          <h2 className="text-2xl font-semibold text-white mb-4">
            Ready to Transform Your Cloud Infrastructure?
          </h2>
          <p className="text-slate-300 mb-6 max-w-2xl mx-auto">
            Let's discuss how we can help you implement a secure and compliant landing zone for your organisation.
          </p>
          <button
            onClick={() => handleChatWithNia('landing zone implementation')}
            className="bg-blue-500 hover:bg-blue-600 text-white px-8 py-3 rounded-lg transition-colors"
          >
            Chat with Nia
          </button>
        </div>
      </div>
      <ChatModal 
        show={showChatModal}
        onClose={() => setShowChatModal(false)}
        initialMessage={chatMessage}
        />
    </div>
  );
};

export default Enterprise;