import React, { useState } from 'react';
import { Check } from 'lucide-react';

const ROICalculator = () => {
  const [firstInvoiceValue, setFirstInvoiceValue] = useState(1000);
  const [futureAnnualBilling, setFutureAnnualBilling] = useState(2000);

  // First invoice commission (15%)
  const firstInvoiceCommission = Math.floor(firstInvoiceValue * 0.15);
  
  // Ongoing annual commission (8%)
  const ongoingAnnualCommission = Math.floor(futureAnnualBilling * 0.08);
  
  // Total first-year commission
  const totalFirstYearCommission = firstInvoiceCommission + ongoingAnnualCommission;
  
  // Net gain calculation
  const totalFirstYearRevenue = firstInvoiceValue + futureAnnualBilling;
  const netGain = totalFirstYearRevenue - totalFirstYearCommission;
  
  // Infrastructure cost (5% of net gain)
  const infrastructureCost = Math.floor(netGain * 0.05);
  
  // Final net gain after infrastructure cost
  const finalNetGain = netGain - infrastructureCost;

  return (
    <div className="bg-slate-800/50 border border-slate-700/50 rounded-xl p-8 max-w-4xl mx-auto">
      <div className="text-center mb-8">
        <h3 className="text-3xl md:text-4xl font-bold mb-4">Dynamic ROI Calculator</h3>
        <p className="text-xl text-slate-300 max-w-3xl mx-auto">
          Explore your potential gains with Nia's success-based model
        </p>
      </div>
      
      <div className="grid md:grid-cols-2 gap-8">
        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-slate-300 mb-2">
              First Invoice Value (£)
            </label>
            <input 
              type="number" 
              value={firstInvoiceValue}
              onChange={(e) => setFirstInvoiceValue(Math.max(0, Number(e.target.value)))}
              className="w-full bg-slate-700 border border-slate-600 rounded-lg px-4 py-2 text-white"
              min="0"
            />
          </div>
          
          <div>
            <label className="block text-sm font-medium text-slate-300 mb-2">
              Future Annual Billing (£)
            </label>
            <input 
              type="number" 
              value={futureAnnualBilling}
              onChange={(e) => setFutureAnnualBilling(Math.max(0, Number(e.target.value)))}
              className="w-full bg-slate-700 border border-slate-600 rounded-lg px-4 py-2 text-white"
              min="0"
            />
          </div>
        </div>
        
        <div className="bg-blue-500/10 rounded-xl p-6 border border-blue-500/50">
          <h4 className="text-2xl font-semibold text-blue-400 mb-6">Revenue Breakdown</h4>
          <div className="space-y-4">
            {[
              { 
                label: "First Invoice Value", 
                value: firstInvoiceValue, 
                type: 'normal' 
              },
              { 
                label: "First Invoice Commission (15%)", 
                value: -firstInvoiceCommission, 
                type: 'negative' 
              },
              { 
                label: "Future Annual Billing", 
                value: futureAnnualBilling, 
                type: 'normal' 
              },
              { 
                label: "Ongoing Annual Commission (8%)", 
                value: -ongoingAnnualCommission, 
                type: 'negative' 
              },
              { 
                label: "Total First Year Revenue", 
                value: totalFirstYearRevenue, 
                type: 'total' 
              },
              { 
                label: "Total Commission", 
                value: -totalFirstYearCommission, 
                type: 'total-negative' 
              },
              { 
                label: "Net Gain", 
                value: netGain, 
                type: 'net-gain' 
              },
              { 
                label: "Infrastructure Cost (5%)", 
                value: -infrastructureCost, 
                type: 'negative' 
              },
              { 
                label: "Final Net Gain", 
                value: finalNetGain, 
                type: 'final-net-gain' 
              }
            ].map((item, index) => {
              const getClassName = () => {
                switch(item.type) {
                  case 'negative': return 'text-red-400';
                  case 'total': return 'font-bold border-t border-slate-700 pt-2';
                  case 'total-negative': return 'font-bold text-red-400 border-t border-slate-700 pt-2';
                  case 'net-gain': return 'font-bold text-green-400 border-t border-slate-700 pt-2';
                  case 'final-net-gain': return 'font-bold text-blue-400 border-t border-slate-700 pt-2';
                  default: return 'text-slate-300';
                }
              };

              return (
                <div key={index} className="flex justify-between">
                  <span className="text-slate-300">{item.label}</span>
                  <span className={getClassName()}>£{Math.abs(item.value).toLocaleString()}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      
      <div className="mt-8 bg-blue-500/10 rounded-xl p-6 border border-blue-500/50">
        <h4 className="text-xl font-semibold text-blue-400 mb-4">Key Insights</h4>
        <div className="grid md:grid-cols-2 gap-4">
          <div className="space-y-3">
            <div className="flex items-center space-x-3">
              <Check className="w-5 h-5 text-blue-400 flex-shrink-0" />
              <span className="text-slate-300">
                Commission represents {((totalFirstYearCommission / totalFirstYearRevenue) * 100).toFixed(2)}% of total revenue
              </span>
            </div>
            <div className="flex items-center space-x-3">
              <Check className="w-5 h-5 text-blue-400 flex-shrink-0" />
              <span className="text-slate-300">
                Net gain provides {((netGain / totalFirstYearRevenue) * 100).toFixed(2)}% return on total revenue
              </span>
            </div>
          </div>
          <div className="space-y-3">
            <div className="flex items-center space-x-3">
              <Check className="w-5 h-5 text-blue-400 flex-shrink-0" />
              <span className="text-slate-300">
                Estimated Infrastructure cost is {((infrastructureCost / netGain) * 100).toFixed(2)}% of net gain
              </span>
            </div>
            <div className="flex items-center space-x-3">
              <Check className="w-5 h-5 text-blue-400 flex-shrink-0" />
              <span className="text-slate-300">
                Final net gain after costs: £{finalNetGain.toLocaleString()}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ROICalculator;