// src/contexts/LiveAPIContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { GoogleGenerativeAI } from '@google/generative-ai';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../firebaseConfig';

// Create context
const LiveAPIContext = createContext(null);

export const LiveAPIProvider = ({ children }) => {
  const [client, setClient] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [config, setConfig] = useState(null);

  useEffect(() => {
    const initializeClient = async () => {
      try {
        setIsLoading(true);
        
        // Get token from Firebase Function
        const getLiveApiToken = httpsCallable(functions, 'getLiveApiToken');
        const { data } = await getLiveApiToken();
        
        if (!data || !data.token) {
          throw new Error('Failed to get authentication token');
        }
        
        // Initialize the Generative AI client
        const genAI = new GoogleGenerativeAI(data.token);
        
        // Check for experimental features
        if (!genAI.experimental) {
          console.warn('Experimental features not available in this SDK version');
        }
        
        setClient(genAI);
        setError(null);
      } catch (err) {
        console.error('Error initializing Generative AI client:', err);
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };
    
    initializeClient();
  }, []);

  // Apply configuration when client and config are both available
  useEffect(() => {
    if (client && config) {
      client.connect(config);
    }
  }, [client, config]);

  return (
    <LiveAPIContext.Provider value={{ client, isLoading, error, setConfig, config }}>
      {children}
    </LiveAPIContext.Provider>
  );
};

export const useLiveAPIContext = () => {
  const context = useContext(LiveAPIContext);
  if (!context) {
    throw new Error('useLiveAPIContext must be used within a LiveAPIProvider');
  }
  return context;
};