import React, { useState } from 'react';
import {
  MapPin,
  Mail,
  Calendar,
  MessageSquare,
  HeadphonesIcon,
  Clock,
  Building2,
  Users,
  X
} from 'lucide-react';
import AIChatBox from '../../AIChatBox';

const ChatModal = ({ show, onClose, initialMessage }) => {
  if (!show) return null;
  return (
    <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
      <div className="bg-slate-900 rounded-xl max-w-4xl w-full">
        <div className="flex justify-between items-center p-6 border-b border-slate-800">
          <h2 className="text-2xl font-semibold text-white">Chat with Nia</h2>
          <button onClick={onClose} className="text-slate-400 hover:text-white">
            <X className="w-6 h-6" />
          </button>
        </div>
        <div className="p-6">
          <AIChatBox initialMessageModal={initialMessage} />
        </div>
      </div>
    </div>
  );
};

const Contact = () => {
  const [showChatModal, setShowChatModal] = useState(false);
  const [chatMessage, setChatMessage] = useState('');
  const handleChatWithNia = (topic) => {
    const message = `Hi Nia, I would like to discuss ${topic} with gClouds. Can you help me?`;
    setShowChatModal(true);
    setChatMessage(message);
  };

  // Calendar link for direct booking
  const CALENDAR_LINK = process.env.REACT_APP_CALENDAR_LINK;

  const contactMethods = [
    {
      icon: MessageSquare,
      title: "Live Chat",
      description: "Chat with Nia, our AI sales specialist",
      action: () => handleChatWithNia('general inquiry'),
      buttonText: "Start Chat"
    },
    {
      icon: Calendar,
      title: "Book a Consultation",
      description: "Schedule a free 30-minute consultation",
      href:CALENDAR_LINK,
      target: "_blank",
      rel: "noopener noreferrer",
      buttonText: "Book Now"
    },
    {
      icon: HeadphonesIcon,
      title: "Technical Support",
      description: "Get help with technical issues",
      action: () => handleChatWithNia('technical support'),
      buttonText: "Get Support"
    },
    {
      icon: Mail,
      title: "Email Us",
      description: "Send us an email at gethelp@gclouds.co.uk",
      href: "mailto:gethelp@gclouds.co.uk",
      buttonText: "Send Email"
    }
  ];

  const officeDetails = [
    {
      icon: MapPin,
      title: "Location",
      text: "Hatfield, Hertfordshire, UK"
    },
    {
      icon: Clock,
      title: "Business Hours",
      text: "Monday - Friday: 9:00 AM - 5:00 PM GMT"
    },
    {
      icon: Building2,
      title: "Company Registration",
      text: "Registered in England & Wales No 12220173"
    },
    {
      icon: Users,
      title: "Partnerships",
      text: "Google Cloud Partner in UK & Ireland"
    }
  ];

  return (
    <div className="w-full bg-slate-950">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <div className="flex justify-center mb-8">
            <img 
              src="/images/google-cloud-partner.png" 
              alt="Google Cloud Partner" 
              className="h-16 md:h-20"
            />
          </div>
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
            Contact Us
          </h1>
          <p className="text-xl text-slate-400 max-w-3xl mx-auto mb-8">
            Get in touch with our team to discuss your cloud needs and how we can help
          </p>
        </div>

        {/* Contact Methods Grid */}
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8 mb-16">
          {contactMethods.map((method, index) => (
            <div
              key={index}
              className="bg-slate-800/50 backdrop-blur-xl p-6 rounded-xl border border-slate-700 flex flex-col"
            >
              <div className="flex items-center space-x-4 mb-4">
                <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center">
                  <method.icon className="w-6 h-6 text-blue-400" />
                </div>
                <h3 className="text-lg font-semibold text-white">{method.title}</h3>
              </div>
              <p className="text-slate-400 mb-6 flex-grow">{method.description}</p>
              {method.href ? (
                <a
                  href={method.href}
                  target={method.target || "_blank"}
                  rel={method.rel || "noopener noreferrer"}
                  className="w-full bg-blue-500 hover:bg-blue-600 text-white px-6 py-3 rounded-lg transition-colors text-center"
                >
                  {method.buttonText}
                </a>
              
              ) : (
                <a
                  onClick={method.action}
                  className="w-full bg-blue-500 hover:bg-blue-600 text-white px-6 py-3 rounded-lg transition-colors"
                >
                  {method.buttonText}
                </a>
              )}
            </div>
          ))}
        </div>

        {/* Office Details */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            Company Information
          </h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            {officeDetails.map((detail, index) => (
              <div
                key={index}
                className="bg-slate-800/50 backdrop-blur-xl p-6 rounded-xl border border-slate-700"
              >
                <div className="flex items-center space-x-4 mb-4">
                  <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center">
                    <detail.icon className="w-6 h-6 text-blue-400" />
                  </div>
                  <h3 className="text-lg font-semibold text-white">{detail.title}</h3>
                </div>
                <p className="text-slate-400">{detail.text}</p>
              </div>
            ))}
          </div>
        </div>

        {/* CTA Section */}
        <div className="text-center bg-blue-500/10 rounded-xl p-8 border border-blue-500/50">
          <h2 className="text-2xl font-semibold text-white mb-4">
            Ready to Get Started?
          </h2>
          <p className="text-slate-300 mb-6 max-w-2xl mx-auto">
            Chat with our AI sales specialist or schedule a consultation with our team.
          </p>
          <div className="flex flex-col sm:flex-row justify-center gap-4">
            <button
              onClick={() => handleChatWithNia('cloud solutions')}
              className="bg-blue-500 hover:bg-blue-600 text-white px-8 py-3 rounded-lg transition-colors"
            >
              Chat with Nia
            </button>
            <a
              href={CALENDAR_LINK}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-slate-700 hover:bg-slate-600 text-white px-8 py-3 rounded-lg transition-colors text-center"
            >
              Book Consultation
            </a>
          </div>
        </div>
      </div>
      <ChatModal 
        show={showChatModal}
        onClose={() => setShowChatModal(false)}
        initialMessage={chatMessage}
        />
    </div>
  );
};

export default Contact;