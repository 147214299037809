import React, { useState } from 'react';
import {
  Smartphone,
  Tablet,
  Layout,
  Box,
  Truck,
  Users,
  ClipboardList,
  Shield,
  Database,
  Zap,
  CheckCircle,
  ArrowRight,
  X
} from 'lucide-react';
import AIChatBox from '../../AIChatBox';

const ChatModal = ({ show, onClose, initialMessage }) => {
  if (!show) return null;
  return (
    <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
      <div className="bg-slate-900 rounded-xl max-w-4xl w-full">
        <div className="flex justify-between items-center p-6 border-b border-slate-800">
          <h2 className="text-2xl font-semibold text-white">Chat with Nia</h2>
          <button onClick={onClose} className="text-slate-400 hover:text-white">
            <X className="w-6 h-6" />
          </button>
        </div>
        <div className="p-6">
          <AIChatBox initialMessageModal={initialMessage} />
        </div>
      </div>
    </div>
  );
};

const AppDevelopment = () => {
  const [showChatModal, setShowChatModal] = useState(false);
  const [chatMessage, setChatMessage] = useState('');

  const handleChatWithNia = (template) => {
    const message = `Hi Nia, I'm interested in developing a ${template} app for my business. Could you tell me more about your mobile app development services and how you could help us create something similar?`;
    setShowChatModal(true);
    setChatMessage(message);
  };

  const templates = [
    {
      category: "Inventory Management",
      icon: Box,
      mockup: "/images/inventory-mockup.png",
      features: [
        "Real-time stock tracking",
        "Barcode scanning",
        "Purchase order management",
        "Supplier management",
        "Stock alerts",
        "Analytics dashboard"
      ]
    },
    {
      category: "Field Service",
      icon: Truck,
      mockup: "/images/field-service-mockup.png",
      features: [
        "Job scheduling",
        "Route optimisation",
        "Digital forms",
        "Photo capture",
        "Customer signatures",
        "Work order tracking"
      ]
    },
    {
      category: "Employee Directory",
      icon: Users,
      mockup: "/images/employee-mockup.png",
      features: [
        "Contact information",
        "Team structure",
        "Skills directory",
        "Project assignments",
        "Leave management",
        "Performance tracking"
      ]
    },
    {
      category: "Project Management",
      icon: ClipboardList,
      mockup: "/images/project-mockup.png",
      features: [
        "Task management",
        "Team collaboration",
        "Timeline tracking",
        "Document sharing",
        "Progress reporting",
        "Resource allocation"
      ]
    }
  ];

  const features = [
    {
      icon: Smartphone,
      title: "Cross-Platform",
      description: "Native apps for iOS and Android from a single codebase"
    },
    {
      icon: Shield,
      title: "Enterprise Security",
      description: "Built-in security controls and data encryption"
    },
    {
      icon: Database,
      title: "Offline Capability",
      description: "Work without internet connection, sync when online"
    },
    {
      icon: Layout,
      title: "Custom UI/UX",
      description: "Tailored design matching your brand identity"
    },
    {
      icon: Zap,
      title: "API Integration",
      description: "Connect with your existing systems and databases"
    },
    {
      icon: Tablet,
      title: "Multi-Device",
      description: "Consistent experience across all devices"
    }
  ];

  return (
    <div className="min-h-screen bg-slate-950">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
            Custom Mobile Apps for Your Business
          </h1>
          <p className="text-xl text-slate-400 max-w-3xl mx-auto mb-8">
            Transform your operations with powerful, user-friendly mobile applications
          </p>
          <button
            onClick={() => handleChatWithNia('custom')}
            className="bg-blue-500 hover:bg-blue-600 text-white px-8 py-3 rounded-lg transition-colors"
          >
            Talk to Nia
          </button>
        </div>

        {/* Features Grid */}
        <div className="grid md:grid-cols-3 gap-8 mb-16">
          {features.map((feature, index) => (
            <div
              key={index}
              className="bg-slate-800/50 backdrop-blur-xl p-6 rounded-xl border border-slate-700 hover:border-blue-500/50 transition-all"
            >
              <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center mb-4">
              <feature.icon className="w-6 h-6 text-blue-400" />
            </div>
            <h3 className="text-lg font-semibold text-white mb-2">{feature.title}</h3>
            <p className="text-slate-400">{feature.description}</p>
          </div>
        ))}
      </div>

      {/* Template Apps */}
      <div className="mb-16">
        <h2 className="text-3xl font-bold text-white text-center mb-12">
          Template Apps
        </h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {templates.map((template, index) => (
            <div
              key={index}
              className="bg-slate-800/50 backdrop-blur-xl p-6 rounded-xl border border-slate-700 hover:border-blue-500/50 transition-all group"
            >
              <div className="w-full aspect-[3/4] bg-slate-900/50 rounded-lg mb-6 overflow-hidden">
                <img 
                  src={template.mockup} 
                  alt={`${template.category} app mockup`}
                  className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
                />
              </div>

              <div className="flex items-center space-x-3 mb-4">
                <div className="w-10 h-10 bg-blue-500/10 rounded-lg flex items-center justify-center">
                  <template.icon className="w-5 h-5 text-blue-400" />
                </div>
                <h3 className="text-lg font-semibold text-white">{template.category}</h3>
              </div>

              <ul className="space-y-2 mb-6">
                {template.features.map((feature, idx) => (
                  <li key={idx} className="flex items-start space-x-2">
                    <CheckCircle className="w-4 h-4 text-blue-400 mt-1 flex-shrink-0" />
                    <span className="text-slate-300 text-sm">{feature}</span>
                  </li>
                ))}
              </ul>

              <button
                onClick={() => handleChatWithNia(template.category.toLowerCase())}
                className="w-full bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg transition-colors flex items-center justify-center space-x-2"
              >
                <span>Learn More</span>
                <ArrowRight className="w-4 h-4" />
              </button>
            </div>
          ))}
        </div>
      </div>

      {/* Development Process */}
      <div className="mb-16">
        <h2 className="text-3xl font-bold text-white text-center mb-12">
          Development Process
        </h2>
        <div className="max-w-4xl mx-auto">
          <div className="grid gap-8">
            {[
              {
                step: "1",
                title: "Requirements",
                description: "We analyse your needs and define the app scope",
                duration: "1-2 hours"
              },
              {
                step: "2",
                title: "Design",
                description: "Create wireframes and interactive prototypes",
                duration: "1 day"
              },
              {
                step: "3",
                title: "Development",
                description: "Build your app",
                duration: "1 day"
              },
              {
                step: "4",
                title: "Testing",
                description: "Thorough testing and quality assurance",
                duration: "1 week"
              },
              {
                step: "5",
                title: "Launch",
                description: "App deployment and ongoing support",
                duration: "1 day"
              }
            ].map((phase, index) => (
              <div 
                key={index}
                className="relative flex items-start bg-slate-800/50 backdrop-blur-xl p-6 rounded-xl border border-slate-700"
              >
                <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center flex-shrink-0 mr-6">
                  <span className="text-xl font-bold text-blue-400">{phase.step}</span>
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-white mb-2">{phase.title}</h3>
                  <p className="text-slate-400 mb-2">{phase.description}</p>
                  <span className="text-sm text-blue-400">{phase.duration}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="text-center bg-blue-500/10 rounded-xl p-8 border border-blue-500/50">
        <h2 className="text-2xl font-semibold text-white mb-4">
          Ready to Build Your App?
        </h2>
        <p className="text-slate-300 mb-6 max-w-2xl mx-auto">
          Whether you're starting from scratch or customising a template, we're here to help bring your app idea to life.
        </p>
        <button
          onClick={() => handleChatWithNia('custom mobile app')}
          className="bg-blue-500 hover:bg-blue-600 text-white px-8 py-3 rounded-lg transition-colors"
        >
          Chat with Nia
        </button>
      </div>
    </div>
    <ChatModal 
        show={showChatModal}
        onClose={() => setShowChatModal(false)}
        initialMessage={chatMessage}
        />
  </div>
);
}
export default AppDevelopment;