import React, { useState } from 'react';
import AIChatBox from '../../components/AIChatBox'; 
import {
  Brain,
  Database,
  Network,
  CheckCircle,
  X,
  ScrollText,
  LineChart,
  Code
} from 'lucide-react';

const ChatModal = ({ show, onClose, initialMessage }) => {
  if (!show) return null;
  return (
    <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
      <div className="bg-slate-900 rounded-xl max-w-4xl w-full">
        <div className="flex justify-between items-center p-6 border-b border-slate-800">
          <h2 className="text-2xl font-semibold text-white">Chat with Nia</h2>
          <button onClick={onClose} className="text-slate-400 hover:text-white">
            <X className="w-6 h-6" />
          </button>
        </div>
        <div className="p-6">
          <AIChatBox initialMessageModal={initialMessage} />
        </div>
      </div>
    </div>
  );
};

const AIConsulting = () => {
  const [showChatModal, setShowChatModal] = useState(false);
  const [chatMessage, setChatMessage] = useState('');

  const handleChatWithNia = (topic) => {
    const message = `Hi Nia, I'm interested in ${topic}. Could you help me understand how you could help us implement AI solutions?`;
    setShowChatModal(true);
    setChatMessage(message);
  };

  const services = [
    {
      icon: Brain,
      title: "AI Strategy Development",
      description: "Create a comprehensive AI adoption roadmap"
    },
    {
      icon: Database,
      title: "Data Readiness",
      description: "Assess and prepare data for AI implementation"
    },
    {
      icon: Network,
      title: "AI Integration",
      description: "Seamless integration of AI solutions with existing systems"
    }
  ];

  const deliverables = [
    {
      icon: ScrollText,
      title: "Strategic Documentation",
      points: [
        "AI readiness assessment",
        "Implementation roadmap",
        "Data strategy plan",
        "ROI analysis"
      ]
    },
    {
      icon: Code,
      title: "Technical Design",
      points: [
        "Architecture blueprints",
        "Model selection guide",
        "Integration patterns",
        "Infrastructure requirements"
      ]
    },
    {
      icon: LineChart,
      title: "Implementation Plan",
      points: [
        "Project milestones",
        "Resource allocation",
        "Success metrics",
        "Risk mitigation strategy"
      ]
    }
  ];

  const solutions = [
    {
      title: "Conversational AI",
      description: "Advanced chatbots and virtual assistants",
      points: [
        "Natural language processing",
        "Multi-channel deployment",
        "Custom knowledge base",
        "Integration with business systems"
      ]
    },
    {
      title: "Document AI",
      description: "Intelligent document processing",
      points: [
        "OCR and data extraction",
        "Form processing",
        "Contract analysis",
        "Automated workflows"
      ]
    },
    {
      title: "Predictive Analytics",
      description: "Data-driven business insights",
      points: [
        "Custom ML models",
        "Forecasting systems",
        "Anomaly detection",
        "Business intelligence"
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-slate-950">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Hero Section */}
        <div id="chat" className="text-center mb-16">
          <div className="flex justify-center mb-8">
            <img 
              src="/images/google-cloud-partner.png" 
              alt="Google Cloud Partner" 
              className="h-16 md:h-20"
            />
          </div>
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
            AI Consulting Services
          </h1>
          <p className="text-xl text-slate-400 max-w-3xl mx-auto mb-8">
            Strategic guidance for implementing Google's AI solutions in your business
          </p>
          <button
            onClick={() => handleChatWithNia('AI consulting')}
            className="bg-blue-500 hover:bg-blue-600 text-white px-8 py-3 rounded-lg transition-colors"
          >
            Talk to Nia
          </button>
        </div>

        {/* Services Grid */}
        <div className="grid md:grid-cols-3 gap-8 mb-16">
          {services.map((service, index) => (
            <div
              key={index}
              className="bg-slate-900/50 backdrop-blur-xl p-6 rounded-xl border border-slate-800 hover:border-blue-500/50 transition-all"
            >
              <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center mb-4">
                <service.icon className="w-6 h-6 text-blue-400" />
              </div>
              <h3 className="text-lg font-semibold text-white mb-2">{service.title}</h3>
              <p className="text-slate-400">{service.description}</p>
            </div>
          ))}
        </div>

        {/* Deliverables Section */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            Key Deliverables
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {deliverables.map((deliverable, index) => (
              <div
                key={index}
                className="bg-slate-900/50 backdrop-blur-xl p-8 rounded-xl border border-slate-800"
              >
                <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center mb-4">
                  <deliverable.icon className="w-6 h-6 text-blue-400" />
                </div>
                <h3 className="text-xl font-semibold text-white mb-4">{deliverable.title}</h3>
                <ul className="space-y-2">
                  {deliverable.points.map((point, idx) => (
                    <li key={idx} className="flex items-start space-x-2 text-slate-300">
                      <CheckCircle className="w-4 h-4 text-blue-400 mt-1" />
                      <span>{point}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        {/* Solutions Section */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            AI Solutions
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {solutions.map((solution, index) => (
              <div
                key={index}
                className="bg-slate-900/50 backdrop-blur-xl p-8 rounded-xl border border-slate-800"
              >
                <h3 className="text-xl font-semibold text-white mb-2">{solution.title}</h3>
                <p className="text-slate-400 mb-4">{solution.description}</p>
                <ul className="space-y-2">
                  {solution.points.map((point, idx) => (
                    <li key={idx} className="flex items-start space-x-2 text-slate-300">
                      <CheckCircle className="w-4 h-4 text-blue-400 mt-1" />
                      <span>{point}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        {/* CTA Section */}
        <div className="text-center bg-blue-500/10 rounded-xl p-8 border border-blue-500/50">
          <h2 className="text-2xl font-semibold text-white mb-4">
            Ready to Transform Your Business with AI?
          </h2>
          <p className="text-slate-300 mb-6 max-w-2xl mx-auto">
            Let's discuss how we can help you leverage Google's AI solutions for your organisation.
          </p>
          <div className="space-x-4">
            <button
              onClick={() => handleChatWithNia('AI implementation')}
              className="bg-blue-500 hover:bg-blue-600 text-white px-8 py-3 rounded-lg transition-colors"
            >
              Chat with Nia
            </button>
          </div>
        </div>
      </div>

      <ChatModal 
        show={showChatModal}
        onClose={() => setShowChatModal(false)}
        initialMessage={chatMessage}
      />
    </div>
  );
};

export default AIConsulting;