import React, { useState } from 'react';
import AIChatBox from '../../components/AIChatBox'; 
import {
  CheckCircle,
  X,
  MessageSquare
} from 'lucide-react';

const Modal = ({ show, onClose, children }) => {
  if (!show) return null;
  
  return (
    <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
      <div className="bg-slate-900 rounded-xl max-w-4xl w-full max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center p-6 border-b border-slate-800">
          <h2 className="text-2xl font-semibold text-white">Chat with an Expert</h2>
          <button onClick={onClose} className="text-slate-400 hover:text-white">
            <X className="w-6 h-6" />
          </button>
        </div>
        <div className="p-6">
          {children}
        </div>
      </div>
    </div>
  );
};

const ChatModal = ({ show, onClose, initialMessage }) => {
  if (!show) return null;
  return (
    <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
      <div className="bg-slate-900 rounded-xl max-w-4xl w-full">
        <div className="flex justify-between items-center p-6 border-b border-slate-800">
          <h2 className="text-2xl font-semibold text-white">Chat with Nia</h2>
          <button onClick={onClose} className="text-slate-400 hover:text-white">
            <X className="w-6 h-6" />
          </button>
        </div>
        <div className="p-6">
          <AIChatBox initialMessageModal={initialMessage} />
        </div>
      </div>
    </div>
  );
};

const GenAIAutomate = () => {
  const [showModal, setShowModal] = useState(false);
  const [showChatModal, setShowChatModal] = useState(false);
  const [chatMessage, setChatMessage] = useState('');

  const handleScheduleConsultant = (topic) => {
    const message = `Hi Nia, I'm interested in for the ${topic}. Could you please schedule a meeting with an expert?`;
    setShowChatModal(true);
    setChatMessage(message);
  };

  const handleChatWithNia = (topic) => {
    const message = `Hi Nia, I'm interested in ${topic}. Could you help me understand the details and potential implementation?`;
    setShowChatModal(true);
    setChatMessage(message);
  };

  const keyDeliverables = [
    "Technical design document",
    "Sample codebase"
  ];

  const googleCloudTeam = [
    "Cloud Engineer",
    "Professional Cloud Architect", 
    "Cloud Project Manager"
  ];

  return (
    <div className="min-h-screen bg-slate-950 text-white">
      <div className="max-w-5xl mx-auto px-4 py-16">
        {/* Hero Section */}
        <div className="mb-16">
          <h1 className="text-4xl font-bold text-white mb-4">
            Automate with GenAI
          </h1>
          <p className="text-xl text-slate-400 mb-6">
          Streamline your business operations with cutting-edge AI technology. Transform inefficient processes, reduce manual workloads, and unlock new levels of productivity. Our GenAI solutions help you leverage advanced artificial intelligence to automate complex tasks
          </p>
          <div className="space-x-4">
            <button 
              onClick={() => handleScheduleConsultant('generative AI automation')}
              className="bg-blue-500 text-white px-6 py-3 rounded-lg hover:bg-blue-600 transition-colors"
            >
              Consult with an expert
            </button>
          </div>
        </div>

        {/* What Your Team Gets */}
        <div className="bg-slate-900/50 rounded-xl p-8 mb-16 backdrop-blur-xl border border-slate-800">
          <h2 className="text-2xl font-semibold text-white mb-6">What your team gets</h2>
          
          <div className="space-y-6">
            <div className="flex items-start space-x-4">
              <CheckCircle className="w-6 h-6 text-blue-400 mt-1 flex-shrink-0" />
              <div>
                <h3 className="font-semibold text-white">Initial Scoping and Insights</h3>
                <p className="text-slate-400">Gain deep insights into your specific AI use case, assessing data readiness, technical infrastructure, and strategic alignment.</p>
              </div>
            </div>

            <div className="flex items-start space-x-4">
              <CheckCircle className="w-6 h-6 text-blue-400 mt-1 flex-shrink-0" />
              <div>
                <h3 className="font-semibold text-white">Rapid Prototype Development</h3>
                <p className="text-slate-400">Create a tailored AI solution prototype that demonstrates practical application of generative AI technologies, highlighting potential business transformations.</p>
              </div>
            </div>

            <div className="flex items-start space-x-4">
              <CheckCircle className="w-6 h-6 text-blue-400 mt-1 flex-shrink-0" />
              <div>
                <h3 className="font-semibold text-white">Strategic Knowledge Transfer</h3>
                <p className="text-slate-400">Receive comprehensive guidance on implementing your AI solution, including actionable insights, performance optimisation strategies, and future-proofing recommendations.</p>
              </div>
            </div>
          </div>
        </div>

        {/* Deliverables and Team */}
        <div className="grid md:grid-cols-2 gap-8 mb-16">
          {/* Key Deliverables */}
          <div className="border border-slate-800 rounded-xl p-6 bg-slate-900/50 backdrop-blur-xl">
            <h3 className="text-xl font-semibold text-white mb-4">Key deliverables</h3>
            <ul className="space-y-2">
              {keyDeliverables.map((deliverable, index) => (
                <li key={index} className="flex items-center space-x-2">
                  <CheckCircle className="w-4 h-4 text-blue-400" />
                  <span className="text-slate-300">{deliverable}</span>
                </li>
              ))}
            </ul>
          </div>

          {/* Google Cloud Team */}
          <div className="border border-slate-800 rounded-xl p-6 bg-slate-900/50 backdrop-blur-xl">
            <h3 className="text-xl font-semibold text-white mb-4">gClouds team include</h3>
            <ul className="space-y-2">
              {googleCloudTeam.map((role, index) => (
                <li key={index} className="flex items-center space-x-2">
                  <CheckCircle className="w-4 h-4 text-blue-400" />
                  <span className="text-slate-300">{role}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Additional Details */}
        <div className="grid md:grid-cols-2 gap-8 mb-16">
          <div className="border border-slate-800 rounded-xl p-6 bg-slate-900/50 backdrop-blur-xl">
            <h3 className="text-xl font-semibold text-white mb-4">Delivery time estimate</h3>
            <p className="text-slate-300">2 weeks</p>
          </div>
          <div className="border border-slate-800 rounded-xl p-6 bg-slate-900/50 backdrop-blur-xl">
            <h3 className="text-xl font-semibold text-white mb-4">Price</h3>
            <p className="text-slate-300">$19K</p>
          </div>
        </div>

        {/* Bottom CTA */}
        <div className="text-center bg-blue-500/10 rounded-xl p-8 border border-blue-500/50">
          <h2 className="text-2xl font-semibold text-white mb-4">
            Connect with our experts to learn how we can help you achieve your desired outcomes
          </h2>
          <div className="space-x-4">
            <button 
              onClick={() => handleScheduleConsultant('generative AI automation')}
              className="bg-blue-500 text-white px-8 py-3 rounded-lg hover:bg-blue-600 transition-colors"
            >
              Consult with an expert
            </button>
            <button
              onClick={() => handleChatWithNia('generative AI automation')}
              className="inline-flex items-center bg-blue-500/10 text-blue-400 px-8 py-3 rounded-lg hover:bg-blue-500/20 transition-colors"
            >
              <MessageSquare className="mr-2 w-5 h-5" />
              Chat with Nia
            </button>
          </div>
        </div>
      </div>

      <Modal show={showModal} onClose={() => setShowModal(false)}>
        <div className="text-white">Expert Consultation Form Placeholder</div>
      </Modal>

      <ChatModal 
        show={showChatModal}
        onClose={() => setShowChatModal(false)}
        initialMessage={chatMessage}
      />
    </div>
  );
};

export default GenAIAutomate;