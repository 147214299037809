import React, { useState } from 'react';
import {
  Mail,
  Clock,
  ShieldCheck,
  CheckCircle,
  Calendar,
  BarChart,
  Zap,
  X
} from 'lucide-react';
import AIChatBox from '../../AIChatBox';

const ChatModal = ({ show, onClose, initialMessage }) => {
  if (!show) return null;
  return (
    <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
      <div className="bg-slate-900 rounded-xl max-w-4xl w-full">
        <div className="flex justify-between items-center p-6 border-b border-slate-800">
          <h2 className="text-2xl font-semibold text-white">Chat with Nia</h2>
          <button onClick={onClose} className="text-slate-400 hover:text-white">
            <X className="w-6 h-6" />
          </button>
        </div>
        <div className="p-6">
          <AIChatBox initialMessageModal={initialMessage} />
        </div>
      </div>
    </div>
  );
};

const Migration = () => {
  const [showChatModal, setShowChatModal] = useState(false);
  const [chatMessage, setChatMessage] = useState('');

  const handleChatWithNia = (topic) => {
    const message = `Hi Nia, I'm interested in your email migration services. Could you tell me more about how you could help our organisation migrate to Google Workspace?`;
    setShowChatModal(true);
    setChatMessage(message);
  };

  const achievements = [
    {
      icon: Mail,
      title: "Emails Migrated",
      value: "2M+",
      description: "Successfully migrated messages"
    },
    {
      icon: Clock,
      title: "Downtime",
      value: "Zero",
      description: "No business disruption"
    },
    {
      icon: Calendar,
      title: "Timeline",
      value: "Limited",
      description: "Holiday period migration"
    }
  ];

  const benefits = [
    {
      icon: ShieldCheck,
      title: "Data Integrity",
      points: [
        "100% data preservation",
        "Full audit trails",
        "Compliance maintained",
        "Verified transfers"
      ]
    },
    {
      icon: Zap,
      title: "Business Continuity",
      points: [
        "Zero downtime migration",
        "Minimal user impact",
        "Seamless transition",
        "Instant accessibility"
      ]
    },
    {
      icon: BarChart,
      title: "Performance Gains",
      points: [
        "Improved email delivery",
        "Enhanced collaboration",
        "Reduced IT overhead",
        "Better security"
      ]
    }
  ];

  const implementation = [
    {
      title: "Challenge",
      description: "Migrate a large organisation's email infrastructure to Google Workspace during a holiday period with zero business disruption.",
      points: [
        "2M+ emails to migrate",
        "Limited maintenance window",
        "Legacy system constraints"
      ]
    },
    {
      title: "Solution",
      description: "Implemented a phased migration approach with parallel processing and real-time verification.",
      points: [
        "Automated migration tools",
        "Parallel processing",
        "Real-time monitoring",
        "Rollback capability"
      ]
    },
    {
      title: "Results",
      description: "Successfully completed the migration ahead of schedule with zero data loss or business disruption.",
      points: [
        "100% data integrity",
        "Zero downtime",
        "Early completion",
        "Smooth transition"
      ]
    }
  ];

  const process = [
    {
      title: "Assessment",
      description: "Analyze current email infrastructure and plan migration strategy"
    },
    {
      title: "Preparation",
      description: "Set up migration tools and prepare target environment"
    },
    {
      title: "Migration",
      description: "Execute migration with real-time monitoring and verification"
    },
    {
      title: "Validation",
      description: "Verify data integrity and ensure user access"
    }
  ];

  return (
    <div className="min-h-screen bg-slate-950">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
            Large-Scale Email Migration Success
          </h1>
          <p className="text-xl text-slate-400 max-w-3xl mx-auto mb-8">
            How we migrated 2 million+ emails to Google Workspace during a holiday period with zero downtime
          </p>
          <button
            onClick={() => handleChatWithNia('email migration')}
            className="bg-blue-500 hover:bg-blue-600 text-white px-8 py-3 rounded-lg transition-colors"
          >
            Talk to Nia
          </button>

        </div>
        {/* Key Achievements */}
        <div className="grid md:grid-cols-3 gap-8 mb-16">
          {achievements.map((achievement, index) => (
            <div
              key={index}
              className="bg-slate-800/50 backdrop-blur-xl p-6 rounded-xl border border-slate-700"
            >
              <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center flex-shrink-0">
                <achievement.icon className="w-6 h-6 text-blue-400" />
                </div>
              <div className="text-3xl font-bold text-white mb-2">{achievement.value}</div>
              <div className="text-lg font-semibold text-white mb-2">{achievement.title}</div>
              <p className="text-slate-400">{achievement.description}</p>
            </div>
          ))}
        </div>

        {/* Key Stats Section */}
        <div className="mb-16">
          <div className="grid md:grid-cols-3 gap-8">
            <div className="bg-slate-800/50 backdrop-blur-xl p-6 rounded-xl border border-slate-700">
              <div className="text-4xl font-bold text-white mb-2">99.99%</div>
              <div className="text-slate-400">Data accuracy rate</div>
            </div>
            <div className="bg-slate-800/50 backdrop-blur-xl p-6 rounded-xl border border-slate-700">
              <div className="text-4xl font-bold text-white mb-2">100%</div>
              <div className="text-slate-400">User satisfaction</div>
            </div>
            <div className="bg-slate-800/50 backdrop-blur-xl p-6 rounded-xl border border-slate-700">
              <div className="text-4xl font-bold text-white mb-2">24/7</div>
              <div className="text-slate-400">Support provided</div>
            </div>
          </div>
        </div>



        {/* Implementation Story */}
        <div className="mb-16">
        <h2 className="text-3xl font-bold text-white text-center mb-12">
            Implementation Story
        </h2>
        <div className="grid md:grid-cols-3 gap-8">
            {implementation.map((phase, index) => (
            <div
                key={index}
                className="bg-slate-800/50 backdrop-blur-xl p-8 rounded-xl border border-slate-700"
            >
                <h3 className="text-xl font-semibold text-white mb-4">{phase.title}</h3>
                <p className="text-slate-400 mb-6">{phase.description}</p>
                <ul className="space-y-3">
                {phase.points.map((point, idx) => (
                    <li key={idx} className="flex items-start space-x-2">
                    <CheckCircle className="w-4 h-4 text-blue-400 mt-1" />
                    <span className="text-slate-300">{point}</span>
                    </li>
                ))}
                </ul>
            </div>
            ))}
        </div>
        </div>

        {/* Migration Process */}
        <div className="mb-16">
        <h2 className="text-3xl font-bold text-white text-center mb-12">
            Migration Process
        </h2>
        <div className="relative">
            <div className="hidden md:block absolute top-1/2 left-0 right-0 h-0.5 bg-slate-700 transform -translate-y-1/2"></div>
            <div className="grid md:grid-cols-4 gap-8">
            {process.map((step, index) => (
                <div key={index} className="relative">
                <div className="bg-slate-800/50 backdrop-blur-xl p-6 rounded-xl border border-slate-700">
                    <div className="flex items-center justify-center mb-4">
                    <div className="w-10 h-10 bg-blue-500/10 rounded-full flex items-center justify-center relative z-10">
                        <span className="text-lg font-bold text-blue-400">{index + 1}</span>
                    </div>
                    </div>
                    <h3 className="text-lg font-semibold text-white text-center mb-2">{step.title}</h3>
                    <p className="text-slate-400 text-center">{step.description}</p>
                </div>
                </div>
            ))}
            </div>
        </div>
        </div>

        {/* Benefits */}
        <div className="mb-16">
        <h2 className="text-3xl font-bold text-white text-center mb-12">
            Benefits Delivered
        </h2>
        <div className="grid md:grid-cols-3 gap-8">
            {benefits.map((benefit, index) => (
            <div
                key={index}
                className="bg-slate-800/50 backdrop-blur-xl p-8 rounded-xl border border-slate-700"
            >
                <div className="flex items-start space-x-4">
                <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center flex-shrink-0">
                    <benefit.icon className="w-6 h-6 text-blue-400" />
                </div>
                <div>
                    <h3 className="text-xl font-semibold text-white mb-4">{benefit.title}</h3>
                    <ul className="space-y-3">
                    {benefit.points.map((point, idx) => (
                        <li key={idx} className="flex items-start space-x-2">
                        <CheckCircle className="w-4 h-4 text-blue-400 mt-1" />
                        <span className="text-slate-300">{point}</span>
                        </li>
                    ))}
                    </ul>
                </div>
                </div>
            </div>
            ))}
        </div>
        </div>

        {/* Migration Timeline */}
        <div className="mb-16">
        <h2 className="text-3xl font-bold text-white text-center mb-12">
            Migration Timeline
        </h2>
        <div className="max-w-4xl mx-auto bg-slate-800/50 backdrop-blur-xl p-8 rounded-xl border border-slate-700">
            <div className="space-y-8">
            {[
                {
                phase: "Pre-Migration",
                duration: "Day 1",
                tasks: ["Environment assessment", "Tool setup", "Initial data sync"]
                },
                {
                phase: "Migration Execution",
                duration: "Day 2",
                tasks: ["Bulk data transfer", "Real-time monitoring", "Continuous verification"]
                },
                {
                phase: "Post-Migration",
                duration: "Day 3",
                tasks: ["Final verification", "User access testing", "Support handover"]
                }
            ].map((phase, index) => (
                <div key={index} className="flex items-start space-x-4">
                <div className="w-24 flex-shrink-0">
                    <div className="text-sm font-medium text-blue-400">{phase.duration}</div>
                </div>
                <div className="flex-1">
                    <div className="bg-slate-700/50 p-4 rounded-lg">
                    <h3 className="text-lg font-semibold text-white mb-3">{phase.phase}</h3>
                    <ul className="space-y-2">
                        {phase.tasks.map((task, idx) => (
                        <li key={idx} className="flex items-start space-x-2">
                            <CheckCircle className="w-4 h-4 text-blue-400 mt-1" />
                            <span className="text-slate-300">{task}</span>
                        </li>
                        ))}
                    </ul>
                    </div>
                </div>
                </div>
            ))}
            </div>
        </div>
        </div>
        {/* CTA Section */}
        <div className="text-center bg-blue-500/10 rounded-xl p-8 border border-blue-500/50">
          <h2 className="text-2xl font-semibold text-white mb-4">
            Planning an Email Migration?
          </h2>
          <p className="text-slate-300 mb-6 max-w-2xl mx-auto">
            Let's discuss how we can help you achieve a smooth and successful migration to Google Workspace.
          </p>
          <div className="flex flex-col sm:flex-row justify-center gap-4">
            <button
              onClick={() => handleChatWithNia('email migration')}
              className="bg-blue-500 hover:bg-blue-600 text-white px-8 py-3 rounded-lg transition-colors"
            >
              Chat with Nia
            </button>
            <button
              onClick={() => handleChatWithNia('migration assessment')}
              className="bg-slate-700 hover:bg-slate-600 text-white px-8 py-3 rounded-lg transition-colors"
            >
              Get Migration Assessment
            </button>
          </div>
        </div>
      </div>
      <ChatModal 
        show={showChatModal}
        onClose={() => setShowChatModal(false)}
        initialMessage={chatMessage}
        />
    </div>
  );
};

export default Migration; 