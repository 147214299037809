import React, { useState } from 'react';
import { ArrowRight, Shield, Mail, Video, Calendar, Users, X } from 'lucide-react';
import { Link } from 'react-router-dom';
import AIChatBox from '../../AIChatBox';

const ChatModal = ({ show, onClose, initialMessage }) => {
  if (!show) return null;
  return (
    <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
      <div className="bg-slate-900 rounded-xl max-w-4xl w-full">
        <div className="flex justify-between items-center p-6 border-b border-slate-800">
          <h2 className="text-2xl font-semibold text-white">Chat with Nia</h2>
          <button onClick={onClose} className="text-slate-400 hover:text-white">
            <X className="w-6 h-6" />
          </button>
        </div>
        <div className="p-6">
          <AIChatBox initialMessageModal={initialMessage} />
        </div>
      </div>
    </div>
  );
};

const BusinessPlans = () => {
  const [showChatModal, setShowChatModal] = useState(false);
  const [chatMessage, setChatMessage] = useState('');
  const [billingCycle, setBillingCycle] = useState('annual');

  const handleChatWithNia = (plan) => {
    const message = `Hi Nia, I'm interested in Google Workspace ${plan}. Could you help me understand the pricing and features for my organisation?`;
    setShowChatModal(true);
    setChatMessage(message);
  };

  const plans = {
    starter: {
      name: 'Business Starter',
      monthly: 6.49,
      annual: 5.49,
      features: [
        { icon: Mail, text: 'Professional email with custom domain' },
        { icon: Video, text: '100 participant video meetings' },
        { icon: Calendar, text: 'Shared calendars' },
        { icon: Shield, text: '30GB cloud storage per user' }
      ]
    },
    standard: {
      name: 'Business Standard',
      monthly: 12.99,
      annual: 10.99,
      features: [
        { icon: Mail, text: 'Professional email with custom domain' },
        { icon: Video, text: '150 participant video meetings' },
        { icon: Calendar, text: 'Shared calendars and appointment scheduling' },
        { icon: Shield, text: '2TB cloud storage per user' }
      ],
      highlighted: true
    },
    plus: {
      name: 'Business Plus',
      monthly: 20.99,
      annual: 17.99,
      features: [
        { icon: Mail, text: 'Professional email with eDiscovery' },
        { icon: Video, text: '500 participant video meetings' },
        { icon: Users, text: 'Advanced user management' },
        { icon: Shield, text: '5TB cloud storage per user' }
      ]
    }
  };

  return (
    <div className="min-h-screen bg-slate-950">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
            Google Workspace Business Plans
          </h1>
          <p className="text-xl text-slate-400 max-w-3xl mx-auto">
            Everything your business needs to collaborate efficiently and work from anywhere
          </p>
        </div>

        {/* Billing Toggle */}
        <div className="flex justify-center mb-12">
          <div className="relative inline-flex rounded-lg border border-slate-700 p-1 bg-slate-800">
            <button
              onClick={() => setBillingCycle('monthly')}
              className={`px-4 py-2 rounded-md text-sm font-medium transition-colors relative z-10 
                ${billingCycle === 'monthly' ? 'text-white' : 'text-slate-400'}`}
            >
              Monthly
            </button>
            <button
            id="business-starter-annual" 
              onClick={() => setBillingCycle('annual')}
              className={`px-4 py-2 rounded-md text-sm font-medium transition-colors relative z-10
                ${billingCycle === 'annual' ? 'text-white' : 'text-slate-400'}`}
            >
              Annual
              <span className="absolute -top-2 -right-2 bg-blue-500 text-xs text-white px-2 py-0.5 rounded-full">
                Save 20%
              </span>
            </button>
            <div
              className="absolute inset-0 h-full bg-blue-500 rounded-md transition-transform duration-200"
              style={{
                width: '50%',
                transform: `translateX(${billingCycle === 'annual' ? '100%' : '0'})`
              }}
            />
          </div>
        </div>

        {/* Pricing Cards */}
        <div className="grid md:grid-cols-3 gap-8 mb-16">
          {Object.entries(plans).map(([key, plan]) => (
            <div
              key={key}
              className={`relative rounded-xl p-6 ${
                plan.highlighted
                  ? 'bg-blue-500/10 border-2 border-blue-500'
                  : 'bg-slate-800/50 border border-slate-700'
              }`}
            >
              {plan.highlighted && (
                <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
                  <span className="bg-blue-500 text-white px-4 py-1 rounded-full text-sm">
                    Most Popular
                  </span>
                </div>
              )}

              <h3 className="text-xl font-semibold text-white mb-4">{plan.name}</h3>
              
              <div className="mb-6">
                <div className="text-3xl font-bold text-white">
                  £{billingCycle === 'monthly' ? plan.monthly : plan.annual}
                  <span className="text-base font-normal text-slate-400"> per user/month</span>
                </div>
                {billingCycle === 'annual' && (
                  <div className="text-sm text-slate-400">billed annually</div>
                )}
              </div>

              <ul className="space-y-4 mb-8">
                {plan.features.map((feature, index) => (
                  <li key={index} className="flex items-start space-x-3">
                    <feature.icon className="w-5 h-5 text-blue-400 mt-0.5" />
                    <span className="text-slate-300">{feature.text}</span>
                  </li>
                ))}
              </ul>

              <button 
                onClick={() => window.open(
                  billingCycle === 'annual' 
                    ? {
                        'Business Starter': 'https://www.paypal.com/ncp/payment/7PUKQNSKH5M7C',
                        'Business Standard': 'https://www.paypal.com/ncp/payment/FD2PLX92D9NSA',
                        'Business Plus': 'https://www.paypal.com/ncp/payment/NWMVFJJTTX5PG'
                      }[plan.name]
                    : {
                        'Business Starter': 'https://www.paypal.com/ncp/payment/ZBJW4WFX885U2',
                        'Business Standard': 'https://www.paypal.com/ncp/payment/SCDR5GT8A4CWU',
                        'Business Plus': 'https://www.paypal.com/ncp/payment/CMXNSMWSRGXHY'
                      }[plan.name], 
                  '_blank'
                )}
                className="w-full bg-blue-500 hover:bg-blue-600 text-white px-6 py-3 rounded-lg transition-colors"
              >
                Buy Now
              </button>
            </div>
          ))}
        </div>

        {/* Feature Comparison Link */}
        <div className="text-center">
          <Link
            to="/products/workspace/compare"
            className="inline-flex items-center text-blue-400 hover:text-blue-300"
          >
            Compare all features
            <ArrowRight className="w-4 h-4 ml-2" />
          </Link>
        </div>
                {/* Contact Section */}
                <div className="text-center bg-slate-800/50 rounded-xl p-8 border border-slate-700">
          <h2 className="text-2xl font-semibold text-white mb-4">
            Need help choosing the right plan?
          </h2>
          <p className="text-slate-400 mb-6">
            Our team is ready to help you find the perfect solution for your organisation
          </p>
          <button
            onClick={() => handleChatWithNia('Business')}
            className="bg-blue-500 hover:bg-blue-600 text-white px-8 py-3 rounded-lg transition-colors"
          >
            Talk to Nia
          </button>
        </div>
      </div>
      <ChatModal 
        show={showChatModal}
        onClose={() => setShowChatModal(false)}
        initialMessage={chatMessage}
        />
    </div>
  );
};

export default BusinessPlans;