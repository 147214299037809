import React, { useState } from 'react';
import {
  Shield,
  Code,
  GitBranch,
  Server,
  Database,
  Lock,
  FileCheck,
  Users,
  Workflow,
  ArrowRight,
  CheckCircle,
  Settings,
  X
} from 'lucide-react';
import AIChatBox from '../../AIChatBox';

const ChatModal = ({ show, onClose, initialMessage }) => {
  if (!show) return null;
  return (
    <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
      <div className="bg-slate-900 rounded-xl max-w-4xl w-full">
        <div className="flex justify-between items-center p-6 border-b border-slate-800">
          <h2 className="text-2xl font-semibold text-white">Chat with Nia</h2>
          <button onClick={onClose} className="text-slate-400 hover:text-white">
            <X className="w-6 h-6" />
          </button>
        </div>
        <div className="p-6">
          <AIChatBox initialMessageModal={initialMessage} />
        </div>
      </div>
    </div>
  );
};

const LandingZone = () => {
  const [showChatModal, setShowChatModal] = useState(false);
  const [chatMessage, setChatMessage] = useState('');

  const handleChatWithNia = (topic) => {
    const message = `Hi Nia, I'm interested in implementing a secure ${topic} for our organisation. Could you tell me more about your landing zone expertise and implementation process?`;
    setShowChatModal(true);
    setChatMessage(message);
  };

  const features = [
    {
      icon: Shield,
      title: "Security-First Design",
      description: "CIS-benchmarked infrastructure with hardened VM images and comprehensive security controls"
    },
    {
      icon: Code,
      title: "DevSecOps Pipeline",
      description: "Automated CI/CD workflows with integrated security scanning and compliance checks"
    },
    {
      icon: GitBranch,
      title: "Infrastructure as Code",
      description: "Version-controlled infrastructure templates ensuring consistency and compliance"
    },
    {
      icon: Server,
      title: "VM Image Factory",
      description: "Automated creation of hardened, compliant virtual machine images"
    },
    {
      icon: Database,
      title: "Resource Hierarchy",
      description: "Structured organisation design with proper access controls and resource management"
    },
    {
      icon: Lock,
      title: "Identity & Access",
      description: "Role-based access control and least privilege principle implementation"
    }
  ];

  const benefits = [
    {
      icon: FileCheck,
      title: "Compliance Assured",
      points: [
        "CIS benchmark compliance",
        "Automated security controls",
        "Regular compliance reporting",
        "Audit-ready infrastructure"
      ]
    },
    {
      icon: Users,
      title: "Enterprise Ready",
      points: [
        "Multi-team collaboration",
        "Role-based access control",
        "Resource segregation",
        "Scalable architecture"
      ]
    },
    {
      icon: Workflow,
      title: "Agile Delivery",
      points: [
        "Sprint-based implementation",
        "Iterative improvements",
        "Continuous integration",
        "Automated deployments"
      ]
    }
  ];

  const processSteps = [
    {
      icon: Shield,
      title: "Security Assessment",
      description: "Evaluate current infrastructure and security requirements"
    },
    {
      icon: Settings,
      title: "Design & Planning",
      description: "Create detailed implementation roadmap and architecture"
    },
    {
      icon: Code,
      title: "Implementation",
      description: "Deploy secure infrastructure using Infrastructure as Code"
    },
    {
      icon: FileCheck,
      title: "Validation",
      description: "Comprehensive testing and security verification"
    }
  ];

  return (
    <div className="min-h-screen bg-slate-950">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
            Secure Cloud Landing Zone
          </h1>
          <p className="text-xl text-slate-400 max-w-3xl mx-auto mb-8">
            Enterprise-grade infrastructure foundation with built-in security, compliance, and DevSecOps practices
          </p>
          <button
            onClick={() => handleChatWithNia('landing zone')}
            className="bg-blue-500 hover:bg-blue-600 text-white px-8 py-3 rounded-lg transition-colors"
          >
            Talk to Nia
          </button>
        </div>

        {/* Features Grid */}
        <div className="grid md:grid-cols-3 gap-8 mb-16">
          {features.map((feature, index) => (
            <div
              key={index}
              className="bg-slate-800/50 backdrop-blur-xl p-6 rounded-xl border border-slate-700 hover:border-blue-500/50 transition-all"
            >
              <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center mb-4">
                <feature.icon className="w-6 h-6 text-blue-400" />
              </div>
              <h3 className="text-lg font-semibold text-white mb-2">{feature.title}</h3>
              <p className="text-slate-400">{feature.description}</p>
            </div>
          ))}
        </div>

        {/* Benefits Section */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            Key Benefits
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {benefits.map((benefit, index) => (
              <div
                key={index}
                className="bg-slate-800/50 backdrop-blur-xl p-8 rounded-xl border border-slate-700"
              >
                <div className="flex items-start space-x-4">
                  <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center flex-shrink-0">
                    <benefit.icon className="w-6 h-6 text-blue-400" />
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold text-white mb-4">{benefit.title}</h3>
                    <ul className="space-y-2">
                      {benefit.points.map((point, idx) => (
                        <li key={idx} className="flex items-start space-x-2 text-slate-300">
                          <CheckCircle className="w-4 h-4 text-blue-400 mt-1" />
                          <span>{point}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Implementation Process */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            Our Implementation Process
          </h2>
          <div className="grid md:grid-cols-4 gap-8">
            {processSteps.map((step, index) => (
              <div key={index} className="relative">
                <div className="bg-slate-800/50 backdrop-blur-xl p-6 rounded-xl border border-slate-700">
                  <div className="flex items-center justify-center mb-4">
                    <div className="relative">
                      <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center">
                        <step.icon className="w-6 h-6 text-blue-400" />
                      </div>
                      {index < processSteps.length - 1 && (
                        <div className="hidden md:block absolute top-1/2 -right-36 transform -translate-y-1/2 w-24 border-t-2 border-slate-700" />
                      )}
                    </div>
                  </div>
                  <h3 className="text-lg font-semibold text-white text-center mb-2">{step.title}</h3>
                  <p className="text-slate-400 text-center">{step.description}</p>
                </div>
                {index < processSteps.length - 1 && (
                  <div className="md:hidden flex justify-center mt-4">
                    <ArrowRight className="w-6 h-6 text-slate-600" />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>

        {/* CTA Section */}
        <div className="text-center bg-blue-500/10 rounded-xl p-8 border border-blue-500/50">
          <h2 className="text-2xl font-semibold text-white mb-4">
            Ready to Build Your Secure Foundation?
          </h2>
          <p className="text-slate-300 mb-6 max-w-2xl mx-auto">
            Let's discuss how we can help you implement a secure, compliant, and scalable landing zone for your organisation.
          </p>
          <button
            onClick={() => handleChatWithNia('enterprise landing zone')}
            className="bg-blue-500 hover:bg-blue-600 text-white px-8 py-3 rounded-lg transition-colors"
          >
            Chat with Nia
          </button>
        </div>
      </div>
      <ChatModal 
        show={showChatModal}
        onClose={() => setShowChatModal(false)}
        initialMessage={chatMessage}
        />
    </div>
  );
};

export default LandingZone;