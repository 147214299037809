import React from 'react';
import { Shield, Lock, Cookie, Server, Globe, Mail } from 'lucide-react';

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-slate-950">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <div className="flex justify-center mb-8">
            <img 
              src="/images/gClouds-logo.png" 
              alt="gClouds" 
              className="h-16 md:h-20"
            />
          </div>
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
            Privacy Policy
          </h1>
          <p className="text-xl text-slate-400 max-w-3xl mx-auto mb-8">
            How we collect, use, and protect your information
          </p>
        </div>

        {/* Main Content */}
        <div className="max-w-4xl mx-auto space-y-12">
          {/* Introduction */}
          <section className="bg-slate-900/50 backdrop-blur-xl p-8 rounded-xl border border-slate-800">
            <h2 className="text-2xl font-semibold text-white mb-4 flex items-center gap-2">
              <Shield className="w-6 h-6 text-blue-400" />
              Overview
            </h2>
            <p className="text-slate-300 mb-4">
              This Privacy Policy explains how gClouds IT Consultancy Ltd ("we", "our", or "us") collects, uses, and protects your personal information when you use our website and AI chat service at www.gclouds.co.uk.
            </p>
            <div className="bg-blue-500/10 border border-blue-500/20 rounded-lg p-4">
              <p className="text-blue-400">Last Updated: February 13, 2025</p>
            </div>
          </section>

          {/* Data Collection */}
          <section className="bg-slate-900/50 backdrop-blur-xl p-8 rounded-xl border border-slate-800">
            <h2 className="text-2xl font-semibold text-white mb-4 flex items-center gap-2">
              <Lock className="w-6 h-6 text-blue-400" />
              Information We Collect
            </h2>
            <div className="space-y-4">
              <h3 className="text-lg font-medium text-white">Automatically Collected Information:</h3>
              <ul className="list-disc list-inside text-slate-300 space-y-2 ml-4">
                <li>Session cookies for chat functionality (365-day retention)</li>
                <li>Chat conversation history (retained with session)</li>
                <li>Usage analytics and technical information</li>
                <li>IP address and browser information</li>
              </ul>

              <h3 className="text-lg font-medium text-white mt-6">Information You Provide:</h3>
              <ul className="list-disc list-inside text-slate-300 space-y-2 ml-4">
                <li>Email address (when booking consultations or sending quotes)</li>
                <li>Name and company details (optional)</li>
                <li>Chat messages and queries</li>
                <li>Uploaded documents for AI chat context ( Demo is temporarily deactivated, you will not see this option when Demo is not active.) </li>
              </ul>
            </div>
          </section>

          {/* Cookie Usage */}
          <section className="bg-slate-900/50 backdrop-blur-xl p-8 rounded-xl border border-slate-800">
            <h2 className="text-2xl font-semibold text-white mb-4 flex items-center gap-2">
              <Cookie className="w-6 h-6 text-blue-400" />
              Cookie Usage
            </h2>
            <p className="text-slate-300 mb-4">
              We use cookies to enhance your experience on our website:
            </p>
            <ul className="list-disc list-inside text-slate-300 space-y-2 ml-4">
              <li>Session cookies to maintain chat context (365-day expiration)</li>
              <li>Analytics cookies to improve our service</li>
            </ul>
          </section>

          {/* Data Storage */}
          <section className="bg-slate-900/50 backdrop-blur-xl p-8 rounded-xl border border-slate-800">
            <h2 className="text-2xl font-semibold text-white mb-4 flex items-center gap-2">
              <Server className="w-6 h-6 text-blue-400" />
              How We Store Your Data
            </h2>
            <p className="text-slate-300 mb-4">
              Your data is securely stored on Google Cloud Platform with the following measures:
            </p>
            <ul className="list-disc list-inside text-slate-300 space-y-2 ml-4">
              <li>End-to-end encryption for all data transfers</li>
              <li>Secure access controls and authentication</li>
              <li>Regular security audits and monitoring</li>
              <li>Data retention policies (365-day session retention)</li>
            </ul>
          </section>

          {/* Data Sharing */}
          <section className="bg-slate-900/50 backdrop-blur-xl p-8 rounded-xl border border-slate-800">
            <h2 className="text-2xl font-semibold text-white mb-4 flex items-center gap-2">
              <Globe className="w-6 h-6 text-blue-400" />
              Data Sharing and Third Parties
            </h2>
            <p className="text-slate-300 mb-4">
              We only share your information with:
            </p>
            <ul className="list-disc list-inside text-slate-300 space-y-2 ml-4">
              <li>Legal authorities when required by law</li>
            </ul>
            <p className="text-slate-300 mt-4">
              We do not sell or rent your personal information to third parties.
            </p>
          </section>

          {/* Contact Information */}
          <section className="bg-slate-900/50 backdrop-blur-xl p-8 rounded-xl border border-slate-800">
            <h2 className="text-2xl font-semibold text-white mb-4 flex items-center gap-2">
              <Mail className="w-6 h-6 text-blue-400" />
              Contact Us
            </h2>
            <p className="text-slate-300 mb-4">
              For any privacy-related questions or concerns, please contact us via:
            </p>
            <div className="space-y-2 text-slate-300">
              <div className="space-y-2 text-slate-300">
                <div className="flex items-center gap-2">
                  <span>Email:</span>
                  <button
                    onClick={() => window.location.href = 'mailto:legal@gclouds.co.uk'}
                    className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg transition-colors inline-flex items-center space-x-2"
                  >
                    <Mail className="w-4 h-4" />
                    <span>Contact Us</span>
                  </button>
                </div>
                <p>Address: Hatfield, Hertfordshire, UK</p>
                <p>Company Registration: 12220173 (England & Wales)</p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;