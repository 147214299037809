import React, { useState } from 'react';
import { ArrowRight } from 'lucide-react';
import {
  X
} from 'lucide-react';
import AIChatBox from '../../AIChatBox';

const ChatModal = ({ show, onClose, initialMessage }) => {
  if (!show) return null;
  return (
    <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
      <div className="bg-slate-900 rounded-xl max-w-4xl w-full">
        <div className="flex justify-between items-center p-6 border-b border-slate-800">
          <h2 className="text-2xl font-semibold text-white">Chat with Nia</h2>
          <button onClick={onClose} className="text-slate-400 hover:text-white">
            <X className="w-6 h-6" />
          </button>
        </div>
        <div className="p-6">
          <AIChatBox initialMessageModal={initialMessage} />
        </div>
      </div>
    </div>
  );
};

const Expertise = () => {
  const [showChatModal, setShowChatModal] = useState(false);
  const [chatMessage, setChatMessage] = useState('');
  const handleChatWithNia = (topic) => {
    const message = `Hi Nia, I'm interested in ${topic}. Could you tell me more about how you could help us?`;
    setShowChatModal(true);
    setChatMessage(message);
  };

  const expertiseAreas = [
    {
      area: "Cloud Migration",
      description: "We make the journey to the cloud easier by delivering seamless transition to Google Cloud—from architecture to migration execution.",
      deliverables: [
        "Infrastructure assessment and planning",
        "Workload modernisation strategy",
        "Zero-downtime migration",
        "VMware Engine implementation",
        "GKE migration and optimisation"
      ]
    },
    {
      area: "DevOps & Infrastructure",
      description: "Modernise your development practices and infrastructure with automated pipelines and secure cloud architecture.",
      deliverables: [
        "CI/CD pipeline automation",
        "Infrastructure as Code",
        "Security controls implementation",
        "High-availability architecture",
        "Disaster recovery planning"
      ]
    },
    {
      area: "Machine Learning",
      description: "Implement AI solutions using Google Cloud's machine learning services for advanced analytics and automation.",
      deliverables: [
        "Custom ML model development",
        "Vertex AI implementation",
        "Document AI solutions",
        "ML pipeline automation"
      ]
    },
    {
      area: "Data Analytics",
      description: "Transform your data into actionable insights with modern data platform solutions and real-time analytics.",
      deliverables: [
        "Data warehouse modernisation",
        "BigQuery implementation",
        "Real-time analytics",
        "Data governance"
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-slate-950">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <div className="flex justify-center mb-8">
            <img 
              src="/images/google-cloud-partner.png" 
              alt="Google Cloud Partner" 
              className="h-16 md:h-20"
            />
          </div>
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
            Google Cloud Expertise
          </h1>
          <p className="text-xl text-slate-400 max-w-3xl mx-auto">
            Expert implementation of Google Cloud solutions to transform your business
          </p>
        </div>

        {/* Expertise Grid */}
        <div className="mb-16">
          <div className="grid grid-cols-1 gap-8">
            {expertiseAreas.map((expertise, index) => (
              <div 
                key={index}
                className="bg-slate-800/50 backdrop-blur-xl rounded-xl border border-slate-700 overflow-hidden"
              >
                <div className="grid md:grid-cols-3 divide-x divide-slate-700">
                  {/* Area */}
                  <div className="p-6">
                    <h3 className="text-xl font-semibold text-white mb-2">{expertise.area}</h3>
                  </div>

                  {/* Description */}
                  <div className="p-6 border-t md:border-t-0 border-slate-700">
                    <p className="text-slate-300">{expertise.description}</p>
                  </div>

                  {/* Deliverables */}
                  <div className="p-6 border-t md:border-t-0 border-slate-700">
                    <ul className="space-y-2">
                      {expertise.deliverables.map((deliverable, idx) => (
                        <li key={idx} className="flex items-start space-x-2">
                          <ArrowRight className="w-4 h-4 text-blue-400 mt-1 flex-shrink-0" />
                          <span className="text-slate-300">{deliverable}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* CTA Section */}
        <div className="text-center bg-blue-500/10 rounded-xl p-8 border border-blue-500/50">
          <h2 className="text-2xl font-semibold text-white mb-4">
            Ready to Start Your Cloud Journey?
          </h2>
          <p className="text-slate-300 mb-6">
            Let's discuss how we can help transform your business with Google Cloud
          </p>
          <button
            onClick={() => handleChatWithNia('cloud transformation')}
            className="bg-blue-500 hover:bg-blue-600 text-white px-8 py-3 rounded-lg transition-colors"
          >
            Chat with Nia
          </button>
        </div>
      </div>
      <ChatModal 
        show={showChatModal}
        onClose={() => setShowChatModal(false)}
        initialMessage={chatMessage}
        />
    </div>
  );
};

export default Expertise;