import React, { useState } from 'react';
import {
  Shield,
  Users,
  Award,
  Clock,
  Brain,
  Cloud,
  CheckCircle,
  Building2,
  Headphones,
  GraduationCap,
  ArrowRight,
  Mail,
  Globe,
  HeadphonesIcon,
  X
} from 'lucide-react';
import AIChatBox from '../../AIChatBox';

const ChatModal = ({ show, onClose, initialMessage }) => {
  if (!show) return null;
  return (
    <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
      <div className="bg-slate-900 rounded-xl max-w-4xl w-full">
        <div className="flex justify-between items-center p-6 border-b border-slate-800">
          <h2 className="text-2xl font-semibold text-white">Chat with Nia</h2>
          <button onClick={onClose} className="text-slate-400 hover:text-white">
            <X className="w-6 h-6" />
          </button>
        </div>
        <div className="p-6">
          <AIChatBox initialMessageModal={initialMessage} />
        </div>
      </div>
    </div>
  );
};

const WhygClouds = () => {
  const [showChatModal, setShowChatModal] = useState(false);
  const [chatMessage, setChatMessage] = useState('');

  const handleChatWithNia = (topic) => {
    const message = `Hi Nia, I'm interested in working with gClouds for ${topic}. Could you tell me more about your expertise and how you can help my organisation?`;
    setShowChatModal(true);
    setChatMessage(message);
  };

  const benefits = [
    {
      icon: Shield,
      title: "Official Google Cloud Partner",
      description: "ISV/Technology, Service, and Reseller Partner in UK & Ireland"
    },
    {
      icon: Users,
      title: "Expert Team",
      description: "Certified professionals with proven implementation experience"
    },
    {
      icon: Award,
      title: "Proven Track Record",
      description: "Successful deployments for enterprise and SMB clients"
    },
    {
      icon: Clock,
      title: "24/7 Support",
      description: "Round-the-clock technical support and account management"
    },
    {
      icon: Brain,
      title: "AI-First Approach",
      description: "Cutting-edge AI solutions and implementation expertise"
    },
    {
      icon: Cloud,
      title: "Full-Service Support",
      description: "End-to-end solutions from migration to optimisation"
    }
  ];

  const capabilities = [
    {
      icon: CheckCircle,
      title: "Enterprise Solutions",
      description: "Comprehensive cloud transformation for large organisations",
      examples: [
        "Cloud migration and modernisation",
        "Security and compliance implementation",
        "Enterprise workspace deployment"
      ]
    },
    {
      icon: Building2,
      title: "SMB Excellence",
      description: "Right-sized solutions for growing businesses",
      examples: [
        "Cost-effective cloud adoption",
        "Productivity enhancement",
        "Scalable infrastructure"
      ]
    },
    {
      icon: Headphones,
      title: "Dedicated Support",
      description: "Personalised assistance at every step",
      examples: [
        "Technical consultation",
        "Implementation support",
        "Ongoing optimisation"
      ]
    },
    {
      icon: GraduationCap,
      title: "Training & Enablement",
      description: "Empower your team with expert knowledge",
      examples: [
        "Customised training programs",
        "Best practices workshops",
        "Certification preparation"
      ]
    }
  ];

  const partnerships = [
    { 
      value: "UK & I", 
      label: "Google Cloud Platform Service Partner",
      icon: Cloud
    },
    { 
      value: "Official", 
      label: "Google Workspace Reseller",
      icon: Mail 
    },
    { 
      value: "Global", 
      label: "Technology Partner",
      icon: Globe
    },
    { 
      value: "24/7", 
      label: "Technical Support",
      icon: HeadphonesIcon
    }
  ];

  return (
    <div className="flex-1 bg-slate-950">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <div className="flex justify-center mb-8">
            <img 
              src="/images/google-cloud-partner.png" 
              alt="Google Cloud Partner" 
              className="h-16 md:h-20"
            />
          </div>
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
            Why Choose gClouds?
          </h1>
          <p className="text-xl text-slate-400 max-w-3xl mx-auto mb-8">
            Your trusted Google Cloud partner in the UK & Ireland, delivering expert solutions and support for your digital transformation journey
          </p>
          <button
            onClick={() => handleChatWithNia('cloud solutions')}
            className="bg-blue-500 hover:bg-blue-600 text-white px-8 py-3 rounded-lg transition-colors"
          >
            Talk to Nia
          </button>
        </div>

        {/* Partnerships Section */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mb-16">
          {partnerships.map((item, index) => (
            <div key={index} className="bg-slate-800/50 backdrop-blur-xl p-6 rounded-xl border border-slate-700">
              <div className="flex flex-col items-center text-center">
                {index === 0 ? (
                  <div className="h-12 mb-4">
                    <img 
                      src="/images/google-cloud-partner.png" 
                      alt="Google Cloud Partner" 
                      className="h-full"
                    />
                  </div>
                ) : (
                  <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center mb-4">
                    <item.icon className="w-6 h-6 text-blue-400" />
                  </div>
                )}
                <div className="text-xl font-semibold text-blue-400 mb-2">
                  {item.value}
                </div>
                <div className="text-slate-300 text-sm">
                  {item.label}
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Benefits Grid */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            The gClouds Advantage
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {benefits.map((benefit, index) => (
              <div
                key={index}
                className="bg-slate-800/50 backdrop-blur-xl p-6 rounded-xl border border-slate-700 hover:border-blue-500/50 transition-all"
              >
                <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center mb-4">
                  <benefit.icon className="w-6 h-6 text-blue-400" />
                </div>
                <h3 className="text-lg font-semibold text-white mb-2">{benefit.title}</h3>
                <p className="text-slate-400">{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Capabilities Section */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            Our Capabilities
          </h2>
          <div className="grid md:grid-cols-2 gap-8">
            {capabilities.map((capability, index) => (
              <div
                key={index}
                className="bg-slate-800/50 backdrop-blur-xl p-8 rounded-xl border border-slate-700"
              >
                <div className="flex items-start space-x-4">
                  <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center flex-shrink-0">
                    <capability.icon className="w-6 h-6 text-blue-400" />
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold text-white mb-2">{capability.title}</h3>
                    <p className="text-slate-400 mb-4">{capability.description}</p>
                    <ul className="space-y-2">
                      {capability.examples.map((example, idx) => (
                        <li key={idx} className="flex items-start space-x-2 text-slate-300">
                          <ArrowRight className="w-4 h-4 text-blue-400 mt-1" />
                          <span>{example}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* CTA Section */}
        <div className="text-center bg-blue-500/10 rounded-xl p-8 border border-blue-500/50">
          <h2 className="text-2xl font-semibold text-white mb-4">
            Ready to Transform Your Business?
          </h2>
          <p className="text-slate-300 mb-6 max-w-2xl mx-auto">
            Let's discuss how gClouds can help you achieve your cloud goals with expert guidance and support.
          </p>
          <button
            onClick={() => handleChatWithNia('digital transformation')}
            className="bg-blue-500 hover:bg-blue-600 text-white px-8 py-3 rounded-lg transition-colors"
          >
            Chat with Nia
          </button>
        </div>
      </div>
      <ChatModal 
        show={showChatModal}
        onClose={() => setShowChatModal(false)}
        initialMessage={chatMessage}
        />
    </div>
  );
};

export default WhygClouds;