import React, { useState } from 'react';
import {
  Building,
  FileText,
  Cloud,
  Search,
  Clock,
  DollarSign,
  CheckCircle,
  Scale,
  Lock,
  Database,
  Zap,
  X
} from 'lucide-react';
import AIChatBox from '../../AIChatBox';

const ChatModal = ({ show, onClose, initialMessage }) => {
  if (!show) return null;
  return (
    <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
      <div className="bg-slate-900 rounded-xl max-w-4xl w-full">
        <div className="flex justify-between items-center p-6 border-b border-slate-800">
          <h2 className="text-2xl font-semibold text-white">Chat with Nia</h2>
          <button onClick={onClose} className="text-slate-400 hover:text-white">
            <X className="w-6 h-6" />
          </button>
        </div>
        <div className="p-6">
          <AIChatBox initialMessageModal={initialMessage} />
        </div>
      </div>
    </div>
  );
};

const SMB = () => {
  const [showChatModal, setShowChatModal] = useState(false);
  const [chatMessage, setChatMessage] = useState('');

  const handleChatWithNia = (topic) => {
    const message = `Hi Nia, I'm interested in your digital archiving solution for small businesses. Could you tell me more about how you could help our organisation implement something similar?`;
    setShowChatModal(true);
    setChatMessage(message);
  };

  const achievements = [
    {
      icon: FileText,
      title: "Documents Archived",
      value: "1M+",
      description: "Legal documents digitally archived"
    },
    {
      icon: Cloud,
      title: "Storage Saved",
      value: "70%",
      description: "Reduction in physical storage needs"
    },
    {
      icon: Search,
      title: "Search Speed",
      value: "< 3s",
      description: "Average document retrieval time"
    },
    {
      icon: DollarSign,
      title: "Cost Savings",
      value: "45%",
      description: "Reduction in operational costs"
    }
  ];

  const benefits = [
    {
      icon: Scale,
      title: "Legal Compliance",
      points: [
        "GDPR compliance",
        "Document retention policies",
        "Audit trail tracking",
        "Secure access controls"
      ]
    },
    {
      icon: Zap,
      title: "Operational Efficiency",
      points: [
        "Instant document retrieval",
        "Automated workflows",
        "Reduced manual handling",
        "Mobile accessibility"
      ]
    },
    {
      icon: Lock,
      title: "Data Security",
      points: [
        "End-to-end encryption",
        "Access monitoring",
        "Backup redundancy",
        "Disaster recovery"
      ]
    }
  ];

  const implementation = [
    {
      title: "Challenge",
      description: "A growing legal firm needed to modernise their document management system while ensuring compliance and security.",
      points: [
        "Large volume of physical documents",
        "Complex compliance requirements",
        "Limited IT resources",
        "Budget constraints"
      ]
    },
    {
      title: "Solution",
      description: "We implemented a cloud-based digital archiving solution with automated workflows and advanced search capabilities.",
      points: [
        "Cloud document management",
        "OCR text recognition",
        "Automated categorisation",
        "Mobile access solution"
      ]
    },
    {
      title: "Results",
      description: "Transformed document management processes while reducing costs and improving accessibility.",
      points: [
        "70% reduced storage costs",
        "90% faster document retrieval",
        "100% compliance maintained",
        "Zero data loss incidents"
      ]
    }
  ];

  const features = [
    {
      icon: Database,
      title: "Smart Storage",
      description: "Intelligent document organisation with automated categorisation"
    },
    {
      icon: Search,
      title: "Quick Search",
      description: "Advanced search with OCR and metadata indexing"
    },
    {
      icon: Lock,
      title: "Secure Access",
      description: "Role-based access control and activity monitoring"
    },
    {
      icon: Clock,
      title: "Auto-Retention",
      description: "Automated document retention and disposal policies"
    }
  ];

  return (
    <div className="min-h-screen bg-slate-950">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
            Legal Sector Success Story
          </h1>
          <p className="text-xl text-slate-400 max-w-3xl mx-auto mb-8">
            How we helped a law firm transform their document management with a digital archiving solution
          </p>
          <button
            onClick={() => handleChatWithNia('digital archiving')}
            className="bg-blue-500 hover:bg-blue-600 text-white px-8 py-3 rounded-lg transition-colors"
          >
            Talk to Nia
          </button>
        </div>

        {/* Key Achievements */}
        <div className="grid md:grid-cols-4 gap-8 mb-16">
          {achievements.map((achievement, index) => (
            <div
              key={index}
              className="bg-slate-800/50 backdrop-blur-xl p-6 rounded-xl border border-slate-700"
            >
              <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center mb-4">
                <achievement.icon className="w-6 h-6 text-blue-400" />
              </div>
              <div className="text-3xl font-bold text-white mb-2">{achievement.value}</div>
              <div className="text-lg font-semibold text-white mb-2">{achievement.title}</div>
              <p className="text-slate-400">{achievement.description}</p>
            </div>
          ))}
        </div>

        {/* Implementation Story */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            Implementation Story
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {implementation.map((phase, index) => (
              <div
                key={index}
                className="bg-slate-800/50 backdrop-blur-xl p-8 rounded-xl border border-slate-700"
              >
                <h3 className="text-xl font-semibold text-white mb-4">{phase.title}</h3>
                <p className="text-slate-400 mb-6">{phase.description}</p>
                <ul className="space-y-3">
                  {phase.points.map((point, idx) => (
                    <li key={idx} className="flex items-start space-x-2">
                      <CheckCircle className="w-4 h-4 text-blue-400 mt-1" />
                      <span className="text-slate-300">{point}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        {/* Solution Features */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            Key Features
          </h2>
          <div className="grid md:grid-cols-4 gap-8">
            {features.map((feature, index) => (
              <div
                key={index}
                className="bg-slate-800/50 backdrop-blur-xl p-6 rounded-xl border border-slate-700 hover:border-blue-500/50 transition-all"
              >
                <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center mb-4">
                  <feature.icon className="w-6 h-6 text-blue-400" />
                </div>
                <h3 className="text-lg font-semibold text-white mb-2">{feature.title}</h3>
                <p className="text-slate-400">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Benefits Delivered */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            Benefits Delivered
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {benefits.map((benefit, index) => (
              <div
                key={index}
                className="bg-slate-800/50 backdrop-blur-xl p-8 rounded-xl border border-slate-700"
              >
                <div className="flex items-start space-x-4">
                  <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center flex-shrink-0">
                    <benefit.icon className="w-6 h-6 text-blue-400" />
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold text-white mb-4">{benefit.title}</h3>
                    <ul className="space-y-3">
                      {benefit.points.map((point, idx) => (
                        <li key={idx} className="flex items-start space-x-2">
                          <CheckCircle className="w-4 h-4 text-blue-400 mt-1" />
                          <span className="text-slate-300">{point}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Testimonial Section */}
        <div className="mb-16">
          <div className="max-w-3xl mx-auto bg-slate-800/50 backdrop-blur-xl p-8 rounded-xl border border-slate-700">
            <div className="flex items-center space-x-4 mb-6">
              <div className="w-12 h-12 bg-blue-500/10 rounded-full flex items-center justify-center">
                <Building className="w-6 h-6 text-blue-400" />
              </div>
              <div>
                <div className="text-lg font-semibold text-white">Legal Sector Client</div>
                <div className="text-slate-400">Small Law Firm</div>
              </div>
            </div>
            <blockquote className="text-lg text-slate-300 italic mb-6">
              "The digital archiving solution has transformed how we manage documents. The time saved on document retrieval alone has made a significant impact on our productivity."
            </blockquote>
          </div>
        </div>

        {/* CTA Section */}
        <div className="text-center bg-blue-500/10 rounded-xl p-8 border border-blue-500/50">
          <h2 className="text-2xl font-semibold text-white mb-4">
            Ready to Modernise Your Document Management?
          </h2>
          <p className="text-slate-300 mb-6 max-w-2xl mx-auto">
            Let's discuss how we can help you implement a secure and efficient digital archiving solution for your organisation.
          </p>
          <div className="flex flex-col sm:flex-row justify-center gap-4">
            <button
              onClick={() => handleChatWithNia('digital archiving solution')}
              className="bg-blue-500 hover:bg-blue-600 text-white px-8 py-3 rounded-lg transition-colors"
            >
              Chat with Nia
            </button>
            <button
              onClick={() => handleChatWithNia('document management demo')}
              className="bg-slate-700 hover:bg-slate-600 text-white px-8 py-3 rounded-lg transition-colors"
            >
              Request Demo
            </button>
          </div>
        </div>
      </div>
      <ChatModal 
        show={showChatModal}
        onClose={() => setShowChatModal(false)}
        initialMessage={chatMessage}
        />
    </div>
  );
};

export default SMB;