import React, { useState, useEffect, useRef } from 'react';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebaseConfig';

const VoiceCallTest = () => {
  const [logs, setLogs] = useState([]);
  const [status, setStatus] = useState('Ready');
  const [isCallActive, setIsCallActive] = useState(false);
  const [transcript, setTranscript] = useState('');
  
  // Refs
  const audioContextRef = useRef(null);
  const processorRef = useRef(null);
  const streamRef = useRef(null);
  const audioChunksRef = useRef([]);
  
  const float32ToBase64 = (float32Array) => {
    // Convert to 16-bit PCM first (more compact and what Speech API expects)
    const buffer = new ArrayBuffer(float32Array.length * 2);
    const view = new DataView(buffer);
    
    for (let i = 0; i < float32Array.length; i++) {
      // Clamp values between -1 and 1, then convert to 16-bit
      const val = Math.max(-1, Math.min(1, float32Array[i]));
      const val16 = Math.floor(val < 0 ? val * 0x8000 : val * 0x7FFF);
      view.setInt16(i * 2, val16, true); // Little endian
    }
    
    // Convert buffer to base64
    const bytes = new Uint8Array(buffer);
    let binary = '';
    for (let i = 0; i < bytes.byteLength; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  const addLog = (message) => {
    setLogs(prev => [...prev, `${new Date().toISOString().substr(11, 8)} - ${message}`]);
  };
  
  const startCall = async () => {
    try {
      addLog('Requesting microphone access...');
      setStatus('Requesting microphone...');
      
      // Get user media
      const stream = await navigator.mediaDevices.getUserMedia({ 
        audio: { 
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true
        }
      });
      
      streamRef.current = stream;
      
      // Test createCallSession function
      addLog('Creating call session...');
      const createCallSession = httpsCallable(functions, 'createCallSession');
      const sessionResponse = await createCallSession({
        sessionId: `test_${Date.now()}`
      });
      
      addLog(`Call session created: ${sessionResponse.data.callId}`);
      
      // Set up audio processing
      addLog('Setting up audio processing...');
      await setupAudioProcessing(stream);
      
      setIsCallActive(true);
      setStatus('Call active - speak to test');
      
    } catch (error) {
      addLog(`ERROR: ${error.message}`);
      setStatus(`Error: ${error.message}`);
    }
  };
  
  const setupAudioProcessing = async (stream) => {
    try {
      // Create audio context
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      audioContextRef.current = audioContext;
      
      // Create media stream source
      const source = audioContext.createMediaStreamSource(stream);
      
      // Create script processor
      const processor = audioContext.createScriptProcessor(4096, 1, 1);
      processorRef.current = processor;
      
      // Connect nodes
      source.connect(processor);
      processor.connect(audioContext.destination);
      
      let silenceStart = null;
      let isSpeaking = false;
      
      // Process audio
      processor.onaudioprocess = (e) => {
        const input = e.inputBuffer.getChannelData(0);
        const sum = input.reduce((acc, val) => acc + Math.abs(val), 0);
        const avg = sum / input.length;
        
        // Detect speech
        if (avg > 0.01) { // Adjust threshold as needed
          if (!isSpeaking) {
            addLog('Speech detected');
            isSpeaking = true;
            audioChunksRef.current = [];
          }
          
          silenceStart = null;
          
          // Add audio data to chunks
          audioChunksRef.current.push(new Float32Array(input));
          
        } else if (isSpeaking) {
          // Detect silence after speech
          if (silenceStart === null) {
            silenceStart = Date.now();
          } else if (Date.now() - silenceStart > 1500) { // 1.5s of silence
            addLog('Speech ended, processing...');
            isSpeaking = false;
            processSpeech(audioChunksRef.current);
            audioChunksRef.current = [];
          }
        }
      };
      
    } catch (error) {
      addLog(`Audio setup error: ${error.message}`);
      throw error;
    }
  };
  
  const processSpeech = async (audioChunks) => {
    try {
      addLog(`Processing speech (${audioChunks.length} chunks)`);
      
      // Limit the amount of audio we send (3 seconds max for testing)
      const maxSamples = 16000 * 3; // 3 seconds at 16kHz
      
      // Combine audio chunks
      const totalLength = Math.min(
        audioChunks.reduce((acc, chunk) => acc + chunk.length, 0),
        maxSamples
      );
      
      const audioData = new Float32Array(totalLength);
      let offset = 0;
      
      for (const chunk of audioChunks) {
        if (offset >= maxSamples) break;
        const bytesToCopy = Math.min(chunk.length, maxSamples - offset);
        audioData.set(chunk.slice(0, bytesToCopy), offset);
        offset += bytesToCopy;
      }
      
      // Convert to base64
      const audioBase64 = float32ToBase64(audioData);
      
      addLog(`Sending to speech recognition (${audioBase64.length} chars in base64)`);
      const processSpeechStream = httpsCallable(functions, 'processSpeechStream');
      const result = await processSpeechStream({ 
        audioBase64, 
        sessionId: 'test_session' 
      });
      
      if (result.data && result.data.transcript) {
        const text = result.data.transcript;
        addLog(`Transcript: "${text}"`);
        setTranscript(text);
      } else {
        addLog('No transcript returned');
      }
      
    } catch (error) {
      addLog(`Speech processing error: ${error.message}`);
    }
  };
  
  const endCall = () => {
    addLog('Ending call');
    setStatus('Call ended');
    setIsCallActive(false);
    
    // Clean up
    if (processorRef.current) {
      processorRef.current.disconnect();
    }
    
    if (audioContextRef.current) {
      audioContextRef.current.close();
    }
    
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
    }
    
    setTimeout(() => {
      setStatus('Ready');
    }, 2000);
  };
  
  // Clean up on unmount
  useEffect(() => {
    return () => {
      if (isCallActive) {
        endCall();
      }
    };
  }, [isCallActive]);
  
  return (
    <div style={{ maxWidth: '800px', margin: '0 auto', padding: '20px' }}>
      <h1>WebRTC Voice Call Test</h1>
      
      <div style={{ marginBottom: '20px' }}>
        <div>Status: <strong>{status}</strong></div>
        {transcript && (
          <div style={{ marginTop: '10px' }}>
            <div>Last Transcript:</div>
            <div style={{ padding: '10px', background: '#f0f0f0', borderRadius: '4px' }}>
              {transcript}
            </div>
          </div>
        )}
      </div>
      
      <div style={{ marginBottom: '20px' }}>
        {!isCallActive ? (
          <button 
            onClick={startCall}
            style={{ 
              padding: '10px 20px',
              background: '#4CAF50',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer'
            }}
          >
            Start Test Call
          </button>
        ) : (
          <button 
            onClick={endCall}
            style={{ 
              padding: '10px 20px',
              background: '#f44336',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer'
            }}
          >
            End Call
          </button>
        )}
      </div>
      
      <div>
        <h3>Logs:</h3>
        <div 
          style={{ 
            height: '300px', 
            overflowY: 'scroll', 
            border: '1px solid #ccc',
            padding: '10px',
            fontFamily: 'monospace',
            fontSize: '12px'
          }}
        >
          {logs.map((log, i) => (
            <div key={i}>{log}</div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VoiceCallTest;