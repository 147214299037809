import React, { useState } from 'react';
import { Shield, Database, Lock, Users, Brain, Cloud, Check, X } from 'lucide-react';
import AIChatBox from '../../AIChatBox';

const ChatModal = ({ show, onClose, initialMessage }) => {
  if (!show) return null;
  return (
    <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
      <div className="bg-slate-900 rounded-xl max-w-4xl w-full">
        <div className="flex justify-between items-center p-6 border-b border-slate-800">
          <h2 className="text-2xl font-semibold text-white">Chat with Nia</h2>
          <button onClick={onClose} className="text-slate-400 hover:text-white">
            <X className="w-6 h-6" />
          </button>
        </div>
        <div className="p-6">
          <AIChatBox initialMessageModal={initialMessage} />
        </div>
      </div>
    </div>
  );
};

const EnterprisePlans = () => {
  const [showChatModal, setShowChatModal] = useState(false);
  const [chatMessage, setChatMessage] = useState('');

  const handleChatWithNia = (plan) => {
    const message = `Hi Nia, I'm interested in Google Workspace ${plan}. Could you help me understand the pricing and features for my organisation?`;
    setShowChatModal(true);
    setChatMessage(message);
  };

  const plans = [
    {
      name: 'Enterprise Standard',
      features: [
        'Unlimited cloud storage',
        'Enterprise-grade security controls',
        'Advanced data loss prevention',
        'eDiscovery and retention',
        '500 participant video meetings',
        'Noise cancellation and meeting recordings',
        'Priority support'
      ]
    },
    {
      name: 'Enterprise Plus',
      features: [
        'All Enterprise Standard features',
        'Advanced enterprise controls',
        'S/MIME encryption',
        'Data regions',
        'Enterprise endpoint management',
        '1000 participant video meetings',
        'Enhanced support',
        'Security center'
      ],
      highlighted: true
    }
  ];

  const features = [
    {
      icon: Shield,
      title: 'Advanced Security',
      description: 'Enterprise-grade security with advanced controls and DLP'
    },
    {
      icon: Database,
      title: 'Unlimited Storage',
      description: 'No storage limits for your organisation'
    },
    {
      icon: Lock,
      title: 'Data Protection',
      description: 'Advanced data loss prevention and eDiscovery'
    },
    {
      icon: Users,
      title: 'Enhanced Collaboration',
      description: 'Advanced meeting features and controls'
    },
    {
      icon: Brain,
      title: 'AI-Powered Tools',
      description: 'Smart features to boost productivity'
    },
    {
      icon: Cloud,
      title: 'Cloud Integration',
      description: 'Seamless integration with Google Cloud'
    }
  ];

  return (
    <div className="min-h-screen bg-slate-950">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
            Google Workspace Enterprise
          </h1>
          <p className="text-xl text-slate-400 max-w-3xl mx-auto">
            Advanced security and control for your organisation
          </p>
        </div>

        {/* Feature Grid */}
        <div className="grid md:grid-cols-3 gap-8 mb-16">
          {features.map((feature, index) => (
            <div
              key={index}
              className="bg-slate-800/50 backdrop-blur-xl p-6 rounded-xl border border-slate-700 hover:border-blue-500/50 transition-all"
            >
              <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center mb-4">
                <feature.icon className="w-6 h-6 text-blue-400" />
              </div>
              <h3 className="text-lg font-semibold text-white mb-2">{feature.title}</h3>
              <p className="text-slate-400">{feature.description}</p>
            </div>
          ))}
        </div>

        {/* Plan Cards */}
        <div className="grid md:grid-cols-2 gap-8 mb-16">
          {plans.map((plan) => (
            <div
              key={plan.name}
              className={`relative rounded-xl p-8 ${
                plan.highlighted
                  ? 'bg-blue-500/10 border-2 border-blue-500'
                  : 'bg-slate-800/50 border border-slate-700'
              }`}
            >
              {plan.highlighted && (
                <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
                  <span className="bg-blue-500 text-white px-4 py-1 rounded-full text-sm">
                    Recommended
                  </span>
                </div>
              )}

              <h3 className="text-2xl font-semibold text-white mb-6">{plan.name}</h3>

              <ul className="space-y-4 mb-8">
                {plan.features.map((feature, index) => (
                  <li key={index} className="flex items-start space-x-3">
                    <Check className="w-5 h-5 text-blue-400 mt-0.5" />
                    <span className="text-slate-300">{feature}</span>
                  </li>
                ))}
              </ul>

              <button
                onClick={() => handleChatWithNia(plan.name)}
                className="w-full bg-blue-500 hover:bg-blue-600 text-white px-6 py-3 rounded-lg transition-colors"
              >
                Chat with Nia for Pricing
              </button>
            </div>
          ))}
        </div>

        {/* Contact Section */}
        <div className="text-center bg-slate-800/50 rounded-xl p-8 border border-slate-700">
          <h2 className="text-2xl font-semibold text-white mb-4">
            Need help choosing the right plan?
          </h2>
          <p className="text-slate-400 mb-6">
            Our team is ready to help you find the perfect solution for your organisation
          </p>
          <button
            onClick={() => handleChatWithNia('Enterprise')}
            className="bg-blue-500 hover:bg-blue-600 text-white px-8 py-3 rounded-lg transition-colors"
          >
            Talk to Nia
          </button>
        </div>
      </div>
      <ChatModal 
        show={showChatModal}
        onClose={() => setShowChatModal(false)}
        initialMessage={chatMessage}
        />
    </div>
  );
};

export default EnterprisePlans;