// /products/marketplace/ethereum

import React, { useState } from 'react';
import { 
  Code,
  Server,
  Database,
  Terminal,
  Shield,
  X,
  CheckCircle,
  Blocks,
  Monitor,
  Settings,
  Network
} from 'lucide-react';
import AIChatBox from '../../AIChatBox';

const ChatModal = ({ show, onClose, initialMessage }) => {
  if (!show) return null;
  return (
    <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
      <div className="bg-slate-900 rounded-xl max-w-4xl w-full">
        <div className="flex justify-between items-center p-6 border-b border-slate-800">
          <h2 className="text-2xl font-semibold text-white">Chat with Nia</h2>
          <button onClick={onClose} className="text-slate-400 hover:text-white">
            <X className="w-6 h-6" />
          </button>
        </div>
        <div className="p-6">
          <AIChatBox initialMessageModal={initialMessage} />
        </div>
      </div>
    </div>
  );
};

const EthereumDevSuite = () => {
  const [showChatModal, setShowChatModal] = useState(false);
  const [chatMessage, setChatMessage] = useState('');

  const handleChatWithNia = () => {
    const message = `Hi Nia, I'm interested in the Ethereum Developer Suite. Could you tell me more about its features and when it will be available?`;
    setShowChatModal(true);
    setChatMessage(message);
  };

  const toolCategories = [
    {
      title: "Core Development Tools",
      icon: Code,
      tools: [
        "Git (latest stable version)",
        "Node.js and npm (LTS version)",
        "Python 3.x",
        "Go (latest stable version)",
        "Visual Studio Code with Solidity extensions",
        "Docker and Docker Compose"
      ]
    },
    {
      title: "Ethereum Frameworks",
      icon: Blocks,
      tools: [
        "Hardhat Development Environment",
        "Truffle Suite",
        "Ganache CLI and UI",
        "Remix IDE (local installation)",
        "Web3.js and Ethers.js",
        "OpenZeppelin contracts library"
      ]
    },
    {
      title: "Smart Contract Tools",
      icon: Shield,
      tools: [
        "Solidity compiler (solc)",
        "Vyper compiler",
        "Slither security analyzer",
        "Mythril security analysis tool",
        "Solhint linter",
        "Testing frameworks (Mocha, Chai, Waffle)"
      ]
    },
    {
      title: "Blockchain Nodes",
      icon: Server,
      tools: [
        "Geth (Go Ethereum)",
        "OpenEthereum (formerly Parity)",
        "Local testnet configuration",
        "Infura API integration setup",
        "MetaMask integration",
        "ENS tooling"
      ]
    },
    {
      title: "Databases & Storage",
      icon: Database,
      tools: [
        "MongoDB",
        "PostgreSQL",
        "Redis",
        "IPFS daemon",
        "Local block explorer",
        "EthStats"
      ]
    },
    {
      title: "Monitoring & Analysis",
      icon: Monitor,
      tools: [
        "Ethereum Gas Reporter",
        "Tenderly CLI",
        "Grafana + Prometheus setup",
        "JSON-RPC tools",
        "Network monitoring tools",
        "Performance analytics"
      ]
    }
  ];

  const features = [
    {
      icon: Terminal,
      title: "Pre-configured Environment",
      description: "All tools and dependencies pre-installed and configured"
    },
    {
      icon: Network,
      title: "Network Ready",
      description: "Pre-configured network settings and standard ports"
    },
    {
      icon: Settings,
      title: "Development Templates",
      description: "Sample projects and smart contract templates included"
    }
  ];

  const specifications = [
    {
      title: "System Requirements",
      items: [
        "Ubuntu Server 22.04 LTS",
        "Recommended: 4+ CPU cores",
        "Minimum 8GB RAM",
        "100GB+ storage"
      ]
    },
    {
      title: "Pre-configured Elements",
      items: [
        "Environment variables setup",
        "Network configurations",
        "Development ports allocation",
        "Security settings"
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-slate-950">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Hero Section */}
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="lg:col-span-7">
            <div className="inline-flex items-center space-x-2 bg-blue-500/10 rounded-full px-4 py-1 mb-6">
              <span className="relative flex h-3 w-3">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-400 opacity-75"></span>
                <span className="relative inline-flex rounded-full h-3 w-3 bg-blue-500"></span>
              </span>
              <span className="text-blue-400 text-sm font-medium">Coming Soon</span>
            </div>
            <h1 className="text-4xl font-bold text-white mb-6">
              Ethereum Developer Suite
            </h1>
            <p className="text-xl text-slate-400 mb-8 max-w-3xl">
              Complete development environment for Ethereum blockchain with pre-configured tools, frameworks, and dependencies. Everything you need to start building decentralized applications.
            </p>
            <div className="space-x-4">
              <button
                onClick={handleChatWithNia}
                className="bg-blue-500 hover:bg-blue-600 text-white px-8 py-3 rounded-lg transition-colors"
              >
                Chat with Nia
              </button>
            </div>
          </div>

          {/* Product Highlights Sidebar */}
          <div className="lg:col-span-5 mt-8 lg:mt-0">
            <div className="bg-slate-900/50 backdrop-blur-xl rounded-xl border border-slate-800 p-6">
              <h3 className="text-lg font-semibold text-white mb-4">Key Features</h3>
              <ul className="space-y-4">
                <li className="flex items-start space-x-3">
                  <CheckCircle className="w-5 h-5 text-blue-400 mt-1" />
                  <span className="text-slate-300">
                    Complete Ethereum development environment
                  </span>
                </li>
                <li className="flex items-start space-x-3">
                  <CheckCircle className="w-5 h-5 text-blue-400 mt-1" />
                  <span className="text-slate-300">
                    Pre-installed and configured tools
                  </span>
                </li>
                <li className="flex items-start space-x-3">
                  <CheckCircle className="w-5 h-5 text-blue-400 mt-1" />
                  <span className="text-slate-300">
                    Security analysis and testing frameworks
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Tool Categories Grid */}
        <div className="mt-24">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            Included Tools & Frameworks
          </h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {toolCategories.map((category, index) => (
              <div
                key={index}
                className="bg-slate-900/50 backdrop-blur-xl p-8 rounded-xl border border-slate-800"
              >
                <div className="flex items-start space-x-4">
                  <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center flex-shrink-0">
                    <category.icon className="w-6 h-6 text-blue-400" />
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold text-white mb-4">{category.title}</h3>
                    <ul className="space-y-2">
                      {category.tools.map((tool, idx) => (
                        <li key={idx} className="flex items-start space-x-2 text-slate-300">
                          <CheckCircle className="w-4 h-4 text-blue-400 mt-1" />
                          <span>{tool}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Features Section */}
        <div className="mt-24">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            Features
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <div
                key={index}
                className="bg-slate-900/50 backdrop-blur-xl p-6 rounded-xl border border-slate-800"
              >
                <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center mb-4">
                  <feature.icon className="w-6 h-6 text-blue-400" />
                </div>
                <h3 className="text-lg font-semibold text-white mb-2">{feature.title}</h3>
                <p className="text-slate-400">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Specifications Grid */}
        <div className="mt-24">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            Specifications
          </h2>
          <div className="grid md:grid-cols-2 gap-8">
            {specifications.map((spec, index) => (
              <div
                key={index}
                className="bg-slate-900/50 backdrop-blur-xl p-8 rounded-xl border border-slate-800"
              >
                <h3 className="text-xl font-semibold text-white mb-4">{spec.title}</h3>
                <ul className="space-y-2">
                  {spec.items.map((item, idx) => (
                    <li key={idx} className="flex items-start space-x-2 text-slate-300">
                      <CheckCircle className="w-4 h-4 text-blue-400 mt-1" />
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        {/* Bottom CTA */}
        <div className="mt-24 text-center bg-blue-500/10 rounded-xl p-8 border border-blue-500/50">
          <h2 className="text-2xl font-semibold text-white mb-4">
            Interested in Our Ethereum Developer Suite?
          </h2>
          <p className="text-slate-300 mb-6 max-w-2xl mx-auto">
            Chat with Nia to learn more about our upcoming Ethereum development environment and be notified when it launches.
          </p>
          <button
            onClick={handleChatWithNia}
            className="bg-blue-500 hover:bg-blue-600 text-white px-8 py-3 rounded-lg transition-colors"
          >
            Chat with Nia
          </button>
        </div>
      </div>

      <ChatModal 
        show={showChatModal}
        onClose={() => setShowChatModal(false)}
        initialMessage={chatMessage}
      />
    </div>
  );
};

export default EthereumDevSuite;