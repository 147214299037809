import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../Auth';
import { db } from '../firebaseConfig';
import { collection, query, where, getDocs, orderBy, limit } from 'firebase/firestore';
import { 
  Users, 
  Settings, 
  BarChart, 
  Shield, 
  Bell, 
  Database,
  ChevronRight,
  LogOut,
  Clock,
  CheckCircle,
  ArrowLeft
} from 'lucide-react';

const AdminPanel = () => {
  const { currentUser, userRoles, signOut } = useAuth();
  const navigate = useNavigate();
  const [stats, setStats] = useState({
    totalUsers: 0,
    pendingUsers: 0,
    activeUsers: 0,
    adminUsers: 0
  });
  const [isLoading, setIsLoading] = useState(true);

  // Check if user is authenticated and has admin role
  useEffect(() => {
    if (!currentUser) {
      navigate('/login');
      return;
    }
    
    if (!userRoles.includes('admin')) {
      navigate('/home');
      return;
    }
    
    fetchStats();
  }, [currentUser, userRoles, navigate]);

  // Fetch statistics for the dashboard
  const fetchStats = async () => {
    try {
      setIsLoading(true);
      const usersRef = collection(db, 'users');
      
      // Get total users count
      const usersSnapshot = await getDocs(usersRef);
      const totalUsers = usersSnapshot.size;
      
      // Get pending users
      const pendingQuery = query(
        usersRef, 
        where('status', '==', 'pending')
      );
      const pendingSnapshot = await getDocs(pendingQuery);
      const pendingUsers = pendingSnapshot.size;
      
      // Get active users
      const activeQuery = query(
        usersRef, 
        where('status', '==', 'active')
      );
      const activeSnapshot = await getDocs(activeQuery);
      const activeUsers = activeSnapshot.size;
      
      // Get admin users
      const adminQuery = query(
        usersRef, 
        where('roles', 'array-contains', 'admin')
      );
      const adminSnapshot = await getDocs(adminQuery);
      const adminUsers = adminSnapshot.size;
      
      setStats({
        totalUsers,
        pendingUsers,
        activeUsers,
        adminUsers
      });
    } catch (error) {
      console.error('Error fetching stats:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  // Admin panel cards data
  const adminCards = [
    {
      title: 'User Management',
      description: 'View, approve, and manage user accounts',
      icon: <Users className="w-6 h-6 text-blue-400" />,
      link: '/admin/users',
      color: 'bg-blue-600 hover:bg-blue-700'
    },
    {
      title: 'Site Settings',
      description: 'Configure global application settings',
      icon: <Settings className="w-6 h-6 text-purple-400" />,
      link: '/admin/settings',
      color: 'bg-purple-600 hover:bg-purple-700',
      disabled: true
    },
    {
      title: 'Analytics & Reports',
      description: 'View site usage and performance metrics',
      icon: <BarChart className="w-6 h-6 text-green-400" />,
      link: '/admin/analytics',
      color: 'bg-green-600 hover:bg-green-700',
      disabled: true
    },
    {
      title: 'Security',
      description: 'Manage access controls and permissions',
      icon: <Shield className="w-6 h-6 text-red-400" />,
      link: '/admin/security',
      color: 'bg-red-600 hover:bg-red-700',
      disabled: true
    },
    {
      title: 'Notifications',
      description: 'Configure system notifications and alerts',
      icon: <Bell className="w-6 h-6 text-yellow-400" />,
      link: '/admin/notifications',
      color: 'bg-yellow-600 hover:bg-yellow-700',
      disabled: true
    },
    {
      title: 'Database Management',
      description: 'View and manage database records',
      icon: <Database className="w-6 h-6 text-indigo-400" />,
      link: '/admin/database',
      color: 'bg-indigo-600 hover:bg-indigo-700',
      disabled: true
    }
  ];

  // Recent activity data (could be fetched from Firestore in a real implementation)
  const recentActivity = [];

  return (
    <div className="min-h-screen bg-slate-950 text-white">
      <div className="container mx-auto px-4 py-8">
      <div className="mb-6">
          <button
            onClick={() => navigate('/home')}
            className="flex items-center text-slate-300 hover:text-white transition-colors"
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back to Home
          </button>
        </div>
        <div className="flex justify-between items-center mb-8">
          <div>
            <h1 className="text-3xl font-bold">Admin Dashboard</h1>
            <p className="text-slate-400 mt-2">Manage all aspects of your application</p>
          </div>
          
          <div className="flex items-center space-x-4">
            <div className="text-sm text-slate-300">
              Logged in as <span className="font-medium">{currentUser?.email}</span>
            </div>
            <button
              onClick={handleSignOut}
              className="flex items-center text-slate-300 hover:text-white px-3 py-1.5 rounded-lg bg-slate-800 hover:bg-slate-700 transition-colors"
            >
              <LogOut className="w-4 h-4 mr-2" />
              <span>Sign Out</span>
            </button>
          </div>
        </div>
        
        {/* Quick Stats */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-8">
          <div className="bg-slate-900 border border-slate-800 rounded-lg p-4">
            <div className="text-slate-400 text-sm">Total Users</div>
            <div className="text-2xl font-bold">
              {isLoading ? '—' : stats.totalUsers}
            </div>
          </div>
          <div className="bg-slate-900 border border-slate-800 rounded-lg p-4">
            <div className="text-slate-400 text-sm flex items-center">
              <Clock className="w-4 h-4 mr-1" /> Pending Approvals
            </div>
            <div className="text-2xl font-bold text-yellow-400">
              {isLoading ? '—' : stats.pendingUsers}
            </div>
          </div>
          <div className="bg-slate-900 border border-slate-800 rounded-lg p-4">
            <div className="text-slate-400 text-sm flex items-center">
              <CheckCircle className="w-4 h-4 mr-1" /> Active Users
            </div>
            <div className="text-2xl font-bold text-green-400">
              {isLoading ? '—' : stats.activeUsers}
            </div>
          </div>
          <div className="bg-slate-900 border border-slate-800 rounded-lg p-4">
            <div className="text-slate-400 text-sm flex items-center">
              <Shield className="w-4 h-4 mr-1" /> Administrators
            </div>
            <div className="text-2xl font-bold text-purple-400">
              {isLoading ? '—' : stats.adminUsers}
            </div>
          </div>
        </div>
        
        {/* Admin Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {adminCards.map((card, index) => (
            <div key={index} className="bg-slate-900 border border-slate-800 rounded-lg overflow-hidden">
              <div className="p-6">
                <div className="flex items-center space-x-3 mb-4">
                  <div className="p-2 bg-slate-800 rounded-lg">
                    {card.icon}
                  </div>
                  <h2 className="text-xl font-semibold">{card.title}</h2>
                </div>
                
                <p className="text-slate-300 mb-4">
                  {card.description}
                </p>
                
                {card.disabled ? (
                  <button
                    disabled
                    className="flex items-center justify-between bg-slate-700 cursor-not-allowed opacity-50 px-4 py-2 rounded-md text-sm font-medium mt-6 w-full"
                  >
                    <span>Coming Soon</span>
                    <ChevronRight className="w-4 h-4" />
                  </button>
                ) : (
                  <Link
                    to={card.link}
                    className={`flex items-center justify-between ${card.color} px-4 py-2 rounded-md text-sm font-medium mt-6 w-full`}
                  >
                    <span>Manage</span>
                    <ChevronRight className="w-4 h-4" />
                  </Link>
                )}
              </div>
            </div>
          ))}
        </div>
        
        {/* Recent Activity Section */}
        <div className="mt-8">
          <div className="bg-slate-900 border border-slate-800 rounded-lg overflow-hidden">
            <div className="p-4 border-b border-slate-800">
              <h2 className="font-semibold">Recent Activity</h2>
            </div>
            <div className="p-4">
              {recentActivity.length > 0 ? (
                <div className="divide-y divide-slate-800">
                  {recentActivity.map((activity, index) => (
                    <div key={index} className="py-3">
                      <div className="flex items-start">
                        <div className="mr-3">
                          {/* Activity icon would go here */}
                        </div>
                        <div>
                          <p className="text-sm">{activity.description}</p>
                          <p className="text-xs text-slate-400">{activity.timestamp}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-slate-400 text-center p-4">No recent activity to display</p>
              )}
            </div>
          </div>
        </div>
        
        <div className="mt-8 text-center text-sm text-slate-500">
          <p>
            Note: Only the User Management section is currently active. Other sections are coming soon.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;