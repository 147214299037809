// src/components/pages/UserView.js
// A component that shows details for a single user with approval capabilities
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { useAuth } from '../Auth';
import { AlertCircle, CheckCircle, XCircle, ArrowLeft } from 'lucide-react';

const UserView = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const { currentUser, userRoles } = useAuth();
  
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRole, setSelectedRole] = useState('user');
  const [approving, setApproving] = useState(false);
  const [success, setSuccess] = useState(null);
  
  // Check admin access
  useEffect(() => {
    if (!currentUser || !userRoles.includes('admin')) {
      navigate('/login');
    }
  }, [currentUser, userRoles, navigate]);
  
  // Fetch user data
  useEffect(() => {
    const fetchUser = async () => {
      try {
        setLoading(true);
        const userRef = doc(db, 'users', userId);
        const userSnap = await getDoc(userRef);
        
        if (userSnap.exists()) {
          setUser({ id: userSnap.id, ...userSnap.data() });
        } else {
          setError('User not found');
        }
      } catch (err) {
        console.error('Error fetching user:', err);
        setError('Failed to load user data');
      } finally {
        setLoading(false);
      }
    };
    
    if (userId) {
      fetchUser();
    }
  }, [userId]);
  
  const handleApprove = async () => {
    try {
      setApproving(true);
      setError(null);
      
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, {
        status: 'active',
        roles: [selectedRole],
        approvedAt: new Date(),
        approvedBy: currentUser.uid
      });
      
      setSuccess(`User approved with role: ${selectedRole}`);
      // Update local state
      setUser(prev => ({
        ...prev,
        status: 'active',
        roles: [selectedRole]
      }));
    } catch (err) {
      console.error('Error approving user:', err);
      setError('Failed to approve user');
    } finally {
      setApproving(false);
    }
  };
  
  const handleReject = async () => {
    try {
      setApproving(true);
      setError(null);
      
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, {
        status: 'rejected',
        rejectedAt: new Date(),
        rejectedBy: currentUser.uid
      });
      
      setSuccess('User access request rejected');
      // Update local state
      setUser(prev => ({
        ...prev,
        status: 'rejected'
      }));
    } catch (err) {
      console.error('Error rejecting user:', err);
      setError('Failed to reject user');
    } finally {
      setApproving(false);
    }
  };
  
  if (loading) {
    return (
      <div className="min-h-screen bg-slate-950 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }
  
  return (
    <div className="min-h-screen bg-slate-950 p-6">
      <div className="max-w-4xl mx-auto">
        <button 
          onClick={() => navigate('/admin/users')}
          className="mb-6 flex items-center text-slate-300 hover:text-white"
        >
          <ArrowLeft className="w-4 h-4 mr-2" />
          Back to User List
        </button>
        
        <div className="bg-slate-900 rounded-lg overflow-hidden">
          <div className="p-6 border-b border-slate-800">
            <h1 className="text-2xl font-bold text-white">User Details</h1>
          </div>
          
          {error && (
            <div className="bg-red-500/10 border border-red-500/20 rounded-lg m-6 p-4 flex items-start">
              <AlertCircle className="w-5 h-5 text-red-500 mr-2 flex-shrink-0 mt-0.5" />
              <span className="text-red-400">{error}</span>
            </div>
          )}
          
          {success && (
            <div className="bg-green-500/10 border border-green-500/20 rounded-lg m-6 p-4 flex items-start">
              <CheckCircle className="w-5 h-5 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
              <span className="text-green-400">{success}</span>
            </div>
          )}
          
          {user && (
            <div className="p-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
                <div>
                  <h2 className="text-lg font-semibold text-white mb-4">User Information</h2>
                  
                  <div className="space-y-4">
                    <div>
                      <p className="text-sm text-slate-400">Email</p>
                      <p className="font-medium text-white">{user.email}</p>
                    </div>
                    
                    <div>
                      <p className="text-sm text-slate-400">Name</p>
                      <p className="font-medium text-white">{user.fullName || 'Not provided'}</p>
                    </div>
                    
                    <div>
                      <p className="text-sm text-slate-400">Company</p>
                      <p className="font-medium text-white">{user.company || 'Not specified'}</p>
                    </div>
                    
                    <div>
                      <p className="text-sm text-slate-400">Status</p>
                      <div className="mt-1">
                        {user.status === 'pending' && (
                          <span className="inline-flex items-center rounded-full bg-yellow-500/10 px-2.5 py-0.5 text-xs font-medium text-yellow-400">
                            Pending Approval
                          </span>
                        )}
                        {user.status === 'active' && (
                          <span className="inline-flex items-center rounded-full bg-green-500/10 px-2.5 py-0.5 text-xs font-medium text-green-400">
                            Active
                          </span>
                        )}
                        {user.status === 'rejected' && (
                          <span className="inline-flex items-center rounded-full bg-red-500/10 px-2.5 py-0.5 text-xs font-medium text-red-400">
                            Rejected
                          </span>
                        )}
                      </div>
                    </div>
                    
                    <div>
                      <p className="text-sm text-slate-400">Created At</p>
                      <p className="font-medium text-white">
                        {user.createdAt ? new Date(user.createdAt.toDate()).toLocaleString() : 'Unknown'}
                      </p>
                    </div>
                  </div>
                </div>
                
                <div>
                  <h2 className="text-lg font-semibold text-white mb-4">Access Management</h2>
                  
                  {user.status === 'pending' && (
                    <div className="space-y-6">
                      <div>
                        <label className="block text-sm font-medium text-slate-300 mb-2">
                          Select Role
                        </label>
                        <select
                          value={selectedRole}
                          onChange={(e) => setSelectedRole(e.target.value)}
                          className="w-full px-3 py-2 bg-slate-800 border border-slate-700 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                          <option value="user">Standard User</option>
                          <option value="strategy">Strategy User</option>
                          <option value="admin">Administrator</option>
                        </select>
                        <p className="mt-1 text-xs text-slate-400">
                          This determines what sections of the application the user can access.
                        </p>
                      </div>
                      
                      <div className="flex space-x-4">
                        <button
                          onClick={handleApprove}
                          disabled={approving}
                          className="flex-1 flex justify-center items-center py-2 px-4 rounded-md text-white font-medium bg-green-600 hover:bg-green-700 disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                          {approving ? 'Processing...' : 'Approve User'}
                        </button>
                        
                        <button
                          onClick={handleReject}
                          disabled={approving}
                          className="flex-1 flex justify-center items-center py-2 px-4 rounded-md text-white font-medium bg-red-600 hover:bg-red-700 disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                          {approving ? 'Processing...' : 'Reject User'}
                        </button>
                      </div>
                    </div>
                  )}
                  
                  {user.status === 'active' && (
                    <div className="p-4 bg-green-500/10 border border-green-500/20 rounded-lg">
                      <div className="flex items-start">
                        <CheckCircle className="w-5 h-5 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
                        <div>
                          <p className="font-medium text-green-400">User Already Approved</p>
                          <p className="text-sm text-slate-300 mt-1">
                            Current role: {user.roles && user.roles.join(', ')}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  
                  {user.status === 'rejected' && (
                    <div className="p-4 bg-red-500/10 border border-red-500/20 rounded-lg">
                      <div className="flex items-start">
                        <XCircle className="w-5 h-5 text-red-500 mr-2 flex-shrink-0 mt-0.5" />
                        <div>
                          <p className="font-medium text-red-400">User Access Rejected</p>
                          <button 
                            onClick={() => {
                              setUser(prev => ({...prev, status: 'pending'}));
                              setSuccess(null);
                            }}
                            className="text-sm text-blue-400 hover:underline mt-2"
                          >
                            Reconsider approval?
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserView;