import React, { useState, useEffect } from 'react';
import { ArrowRight, ExternalLink } from 'lucide-react';

const QuickLink = ({ title, isActive, onClick }) => (
  <button 
    onClick={onClick}
    className={`w-full text-left px-4 py-2 text-sm transition-colors rounded-lg ${
      isActive 
        ? 'bg-slate-800 text-white' 
        : 'text-slate-300 hover:bg-slate-800/50 hover:text-white'
    }`}
  >
    {title}
  </button>
);

const ContentSection = ({ section }) => (
  <div className="grid grid-cols-2 gap-6 p-6">
    {section.items?.map((item, idx) => (
      <a 
        key={idx}
        href={item.link}
        target={item.link.startsWith('http') ? '_blank' : '_self'}
        rel={item.link.startsWith('http') ? 'noopener noreferrer' : ''}
        className="group block hover:bg-slate-800/50 rounded-lg p-4 transition-colors"
      >
        <div className="flex items-start space-x-4">
          {item.icon && (
            <div className="flex-shrink-0 w-12 h-12 rounded-lg bg-blue-500/10 
                          flex items-center justify-center text-blue-400 
                          group-hover:bg-blue-500/20 transition-colors">
              <item.icon className="w-6 h-6" />
            </div>
          )}
          <div className="flex-1">
            <div className="flex items-center space-x-2">
              <h4 className="text-sm font-medium text-white group-hover:text-blue-400 transition-colors">
                {item.title}
              </h4>
              {item.link.startsWith('http') && (
                <ExternalLink className="w-4 h-4 text-slate-400 group-hover:text-blue-400" />
              )}
            </div>
            <p className="mt-1 text-sm text-slate-400">
              {item.description}
            </p>
          </div>
        </div>
      </a>
    ))}
  </div>
);

const FeaturedContent = ({ features }) => (
  <div className="bg-slate-800/30 p-6 rounded-lg h-full">
    {features?.map((feature, index) => (
      <div key={index} className="mb-6 last:mb-0">
        <h4 className="text-sm font-medium text-white mb-2">
          {feature.title}
        </h4>
        <p className="text-sm text-slate-400 mb-3">
          {feature.description}
        </p>
        {feature.link && (
          <a 
            href={feature.link}
            className="inline-flex items-center text-sm text-blue-400 
                     hover:text-blue-300 font-medium"
          >
            Learn more
            <ArrowRight className="w-4 h-4 ml-1" />
          </a>
        )}
      </div>
    ))}
  </div>
);

const MegaMenu = ({ sections = [], features = [], links = [] }) => {
  const [activeLink, setActiveLink] = useState(null);
  
  useEffect(() => {
    if (links.length > 0 && !activeLink) {
      setActiveLink(links[0].id);
    }
  }, [links, activeLink]);

  const activeSection = sections?.find(section => 
    section.id === activeLink
  ) || sections[0];

  return (
    <div className="grid grid-cols-12 gap-6 p-4">
      <div className="col-span-3 border-r border-slate-700">
        <nav className="space-y-1 py-2">
          {links?.map((link) => (
            <QuickLink
              key={link.id}
              title={link.title}
              isActive={activeLink === link.id}
              onClick={() => setActiveLink(link.id)}
            />
          ))}
        </nav>
      </div>

      <div className="col-span-6">
        {activeSection && <ContentSection section={activeSection} />}
      </div>

      <div className="col-span-3">
        <FeaturedContent features={features} />
      </div>
    </div>
  );
};

export default MegaMenu;