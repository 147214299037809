// src/solutions/infrastructure/oracle/index.js
import React, { useState } from 'react';
import AIChatBox from '../../../components/AIChatBox';
import { 
  Database,
  Shield,
  Server,
  Network,
  Lock,
  X,
  CheckCircle
} from 'lucide-react';

const ChatModal = ({ show, onClose, initialMessage }) => {
  if (!show) return null;
  return (
    <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
      <div className="bg-slate-900 rounded-xl max-w-4xl w-full">
        <div className="flex justify-between items-center p-6 border-b border-slate-800">
          <h2 className="text-2xl font-semibold text-white">Chat with Nia</h2>
          <button onClick={onClose} className="text-slate-400 hover:text-white">
            <X className="w-6 h-6" />
          </button>
        </div>
        <div className="p-6">
          <AIChatBox initialMessageModal={initialMessage} />
        </div>
      </div>
    </div>
  );
};

const Oracle = () => {
  const [showChatModal, setShowChatModal] = useState(false);
  const [chatMessage, setChatMessage] = useState('');

  const handleChatWithNia = () => {
    const message = `Hi Nia, I'm interested in running Oracle workloads on Google Cloud. Could you tell me more about your Bare Metal Solution and licensing benefits?`;
    setShowChatModal(true);
    setChatMessage(message);
  };

  const solutions = [
    {
      icon: Server,
      title: "Bare Metal Solution",
      description: "Dedicated Oracle-certified hardware with ultra-low latency connection to Google Cloud"
    },
    {
      icon: Database,
      title: "Database Migration",
      description: "Zero-downtime migration of Oracle databases with automated tooling"
    },
    {
      icon: Network,
      title: "Hybrid Connectivity",
      description: "Direct interconnect between Oracle and Google Cloud services"
    }
  ];

  const features = [
    {
      icon: Lock,
      title: "Oracle Licensing Benefits",
      points: [
        "Bring your own Oracle licenses",
        "License portability",
        "Core-based licensing optimisation",
        "Flexible deployment options"
      ]
    },
    {
      icon: Shield,
      title: "Enterprise Reliability",
      points: [
        "99.99% availability SLA",
        "Automated backups",
        "Disaster recovery",
        "Multi-region deployment"
      ]
    }
  ];

  const specifications = [
    {
      title: "Bare Metal Configurations",
      points: [
        "Up to 112 CPU cores",
        "Up to 3TB memory",
        "NVMe and SSD storage",
        "RAC-certified hardware"
      ]
    },
    {
      title: "Performance Features",
      points: [
        "<2ms latency to Google Cloud",
        "40Gbps+ throughput",
        "Dedicated networking",
        "Direct cloud connectivity"
      ]
    }
  ];

  const deployments = [
    {
      title: "Database Deployment Options",
      description: "Flexible Oracle Database deployment configurations",
      points: [
        "Single instance databases",
        "Real Application Clusters (RAC)",
        "Data Guard configurations",
        "Exadata compatible"
      ]
    },
    {
      title: "High Availability Options",
      description: "Enterprise-grade reliability and redundancy",
      points: [
        "Active-active configurations",
        "Automated failover",
        "Cross-region replication",
        "Backup and recovery"
      ]
    },
    {
      title: "Application Integration",
      description: "Seamless integration with cloud services",
      points: [
        "Cloud SQL connectivity",
        "BigQuery federation",
        "API integration",
        "Service mesh support"
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-slate-950">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <div className="flex justify-center mb-8">
            <img 
              src="/images/google-cloud-partner.png" 
              alt="Google Cloud Partner" 
              className="h-16 md:h-20"
            />
          </div>
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
            Oracle on Google Cloud
          </h1>
          <p className="text-xl text-slate-400 max-w-3xl mx-auto mb-8">
            Run Oracle workloads on certified hardware with ultra-low latency access to Google Cloud services
          </p>
          <button
            onClick={handleChatWithNia}
            className="bg-blue-500 hover:bg-blue-600 text-white px-8 py-3 rounded-lg transition-colors"
          >
            Talk to Nia
          </button>
        </div>

        {/* Solutions Grid */}
        <div className="grid md:grid-cols-3 gap-8 mb-16">
          {solutions.map((solution, index) => (
            <div
              key={index}
              className="bg-slate-900/50 backdrop-blur-xl p-6 rounded-xl border border-slate-800 hover:border-blue-500/50 transition-all"
            >
              <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center mb-4">
                <solution.icon className="w-6 h-6 text-blue-400" />
              </div>
              <h3 className="text-lg font-semibold text-white mb-2">{solution.title}</h3>
              <p className="text-slate-400">{solution.description}</p>
            </div>
          ))}
        </div>

        {/* Features Section */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            Enterprise Features
          </h2>
          <div className="grid md:grid-cols-2 gap-8">
            {features.map((feature, index) => (
              <div
                key={index}
                className="bg-slate-900/50 backdrop-blur-xl p-8 rounded-xl border border-slate-800"
              >
                <div className="flex items-start space-x-4">
                  <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center flex-shrink-0">
                    <feature.icon className="w-6 h-6 text-blue-400" />
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold text-white mb-4">{feature.title}</h3>
                    <ul className="space-y-2">
                      {feature.points.map((point, idx) => (
                        <li key={idx} className="flex items-start space-x-2 text-slate-300">
                          <CheckCircle className="w-4 h-4 text-blue-400 mt-1" />
                          <span>{point}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Specifications Section */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            Technical Specifications
          </h2>
          <div className="grid md:grid-cols-2 gap-8">
            {specifications.map((spec, index) => (
              <div
                key={index}
                className="bg-slate-900/50 backdrop-blur-xl p-8 rounded-xl border border-slate-800"
              >
                <h3 className="text-xl font-semibold text-white mb-4">{spec.title}</h3>
                <ul className="space-y-2">
                  {spec.points.map((point, idx) => (
                    <li key={idx} className="flex items-start space-x-2 text-slate-300">
                      <CheckCircle className="w-4 h-4 text-blue-400 mt-1" />
                      <span>{point}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        {/* Deployment Options */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            Deployment Options
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {deployments.map((deployment, index) => (
              <div
                key={index}
                className="bg-slate-900/50 backdrop-blur-xl p-8 rounded-xl border border-slate-800"
              >
                <h3 className="text-xl font-semibold text-white mb-4">{deployment.title}</h3>
                <p className="text-slate-400 mb-6">{deployment.description}</p>
                <ul className="space-y-2">
                  {deployment.points.map((point, idx) => (
                    <li key={idx} className="flex items-start space-x-2 text-slate-300">
                      <CheckCircle className="w-4 h-4 text-blue-400 mt-1" />
                      <span>{point}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        {/* CTA Section */}
        <div className="text-center bg-blue-500/10 rounded-xl p-8 border border-blue-500/50">
          <h2 className="text-2xl font-semibold text-white mb-4">
            Ready to Run Oracle on Google Cloud?
          </h2>
          <p className="text-slate-300 mb-6 max-w-2xl mx-auto">
            Let's discuss your requirements and find the optimal Oracle solution for your organisation.
          </p>
          <button
            onClick={handleChatWithNia}
            className="bg-blue-500 hover:bg-blue-600 text-white px-8 py-3 rounded-lg transition-colors"
          >
            Chat with Nia
          </button>
        </div>
      </div>

      <ChatModal 
        show={showChatModal}
        onClose={() => setShowChatModal(false)}
        initialMessage={chatMessage}
      />
    </div>
  );
};

export default Oracle;