import React, { useState } from 'react';
import {
  Brain,
  Code,
  MessageSquare,
  FileText,
  Image,
  Database,
  Shield,
  Server,
  Zap,
  ArrowRight,
  CheckCircle,
  Settings,
  X
} from 'lucide-react';
import AIChatBox from '../../AIChatBox';

const ChatModal = ({ show, onClose, initialMessage }) => {
  if (!show) return null;
  return (
    <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
      <div className="bg-slate-900 rounded-xl max-w-4xl w-full">
        <div className="flex justify-between items-center p-6 border-b border-slate-800">
          <h2 className="text-2xl font-semibold text-white">Chat with Nia</h2>
          <button onClick={onClose} className="text-slate-400 hover:text-white">
            <X className="w-6 h-6" />
          </button>
        </div>
        <div className="p-6">
          <AIChatBox initialMessageModal={initialMessage} />
        </div>
      </div>
    </div>
  );
};

const GenAIImplementation = () => {
  const [showChatModal, setShowChatModal] = useState(false);
  const [chatMessage, setChatMessage] = useState('');

  const handleChatWithNia = (topic) => {
    const message = `Hi Nia, I'm interested in implementing ${topic} in my organisation. Could you tell me more about how you could help us leverage Google's Generative AI capabilities?`;
    setShowChatModal(true);
    setChatMessage(message);
  };

  const solutions = [
    {
      icon: MessageSquare,
      title: "Conversational AI",
      description: "Intelligent chatbots and virtual assistants powered by Google's Gemini API",
      features: [
        "Natural language understanding",
        "Context-aware responses",
        "Multi-turn conversations",
        "Knowledge base integration"
      ]
    },
    {
      icon: FileText,
      title: "Content Generation",
      description: "Automated content creation and processing using PaLM API",
      features: [
        "Document summarisation",
        "Report generation",
        "Product descriptions",
        "Marketing copy"
      ]
    },
    {
      icon: Code,
      title: "Code Generation",
      description: "AI-assisted development with Codey on Vertex AI",
      features: [
        "Code completion",
        "Documentation generation",
        "Code review",
        "Bug detection"
      ]
    },
    {
      icon: Image,
      title: "Image Generation",
      description: "Create and edit images with Imagen on Vertex AI",
      features: [
        "Image creation",
        "Style transfer",
        "Image editing",
        "Visual content generation"
      ]
    }
  ];

  const capabilities = [
    {
      icon: Database,
      title: "Vertex AI Integration",
      points: [
        "Custom model training",
        "Model deployment",
        "API integration",
        "Performance monitoring"
      ]
    },
    {
      icon: Shield,
      title: "Enterprise Security",
      points: [
        "Data encryption",
        "Access controls",
        "Compliance monitoring",
        "Audit logging"
      ]
    },
    {
      icon: Server,
      title: "Scalable Infrastructure",
      points: [
        "Load balancing",
        "Auto-scaling",
        "High availability",
        "Disaster recovery"
      ]
    }
  ];

  const industries = [
    {
      title: "Financial Services",
      description: "AI-powered risk assessment and fraud detection",
      use_cases: [
        "Document processing",
        "Risk analysis",
        "Customer service automation",
        "Fraud detection"
      ]
    },
    {
      title: "Healthcare",
      description: "Intelligent medical documentation and analysis",
      use_cases: [
        "Clinical documentation",
        "Patient communication",
        "Research analysis",
        "Administrative automation"
      ]
    },
    {
      title: "Retail",
      description: "Enhanced customer experience and operations",
      use_cases: [
        "Product recommendations",
        "Customer support",
        "Inventory optimisation",
        "Market analysis"
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-slate-950">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
            Generative AI Solutions
          </h1>
          <p className="text-xl text-slate-400 max-w-3xl mx-auto mb-8">
            Transform your business with Google's cutting-edge Generative AI technologies
          </p>
          <button
            onClick={() => handleChatWithNia('Generative AI solutions')}
            className="bg-blue-500 hover:bg-blue-600 text-white px-8 py-3 rounded-lg transition-colors"
          >
            Talk to Nia
          </button>
        </div>

        {/* Solutions Grid */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            Our Solutions
          </h2>
          <div className="grid md:grid-cols-2 gap-8">
            {solutions.map((solution, index) => (
              <div
                key={index}
                className="bg-slate-800/50 backdrop-blur-xl p-8 rounded-xl border border-slate-700 hover:border-blue-500/50 transition-all"
              >
                <div className="flex items-start space-x-4 mb-6">
                  <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center flex-shrink-0">
                    <solution.icon className="w-6 h-6 text-blue-400" />
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold text-white mb-2">{solution.title}</h3>
                    <p className="text-slate-400">{solution.description}</p>
                  </div>
                </div>
                <ul className="space-y-2">
                  {solution.features.map((feature, idx) => (
                    <li key={idx} className="flex items-start space-x-2 text-slate-300">
                      <CheckCircle className="w-4 h-4 text-blue-400 mt-1" />
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        {/* Capabilities Section */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            Enterprise Capabilities
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {capabilities.map((capability, index) => (
              <div
                key={index}
                className="bg-slate-800/50 backdrop-blur-xl p-8 rounded-xl border border-slate-700"
              >
                <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center mb-6">
                  <capability.icon className="w-6 h-6 text-blue-400" />
                </div>
                <h3 className="text-xl font-semibold text-white mb-4">{capability.title}</h3>
                <ul className="space-y-2">
                  {capability.points.map((point, idx) => (
                    <li key={idx} className="flex items-start space-x-2 text-slate-300">
                      <ArrowRight className="w-4 h-4 text-blue-400 mt-1" />
                      <span>{point}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        {/* Industry Solutions */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            Industry Solutions
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {industries.map((industry, index) => (
              <div
                key={index}
                className="bg-slate-800/50 backdrop-blur-xl p-8 rounded-xl border border-slate-700"
              >
                <h3 className="text-xl font-semibold text-white mb-4">{industry.title}</h3>
                <p className="text-slate-400 mb-6">{industry.description}</p>
                <ul className="space-y-2">
                  {industry.use_cases.map((use_case, idx) => (
                    <li key={idx} className="flex items-start space-x-2 text-slate-300">
                      <CheckCircle className="w-4 h-4 text-blue-400 mt-1" />
                      <span>{use_case}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        {/* Implementation Process */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            Implementation Process
          </h2>
          <div className="max-w-4xl mx-auto">
            <div className="grid gap-8">
              {[
                {
                  step: "1",
                  title: "Assessment",
                  description: "Evaluate your needs and identify optimal AI solutions",
                  icon: Brain
                },
                {
                  step: "2",
                  title: "Solution Design",
                  description: "Design custom AI implementation strategy",
                  icon: Settings
                },
                {
                  step: "3",
                  title: "Development",
                  description: "Implement and integrate AI solutions",
                  icon: Code
                },
                {
                  step: "4",
                  title: "Deployment",
                  description: "Launch and monitor performance",
                  icon: Zap
                }
              ].map((phase, index) => (
                <div 
                  key={index}
                  className="relative flex items-start bg-slate-800/50 backdrop-blur-xl p-6 rounded-xl border border-slate-700"
                >
                  <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center flex-shrink-0 mr-6">
                    <phase.icon className="w-6 h-6 text-blue-400" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold text-white mb-2">{phase.title}</h3>
                    <p className="text-slate-400">{phase.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* CTA Section */}
        <div className="text-center bg-blue-500/10 rounded-xl p-8 border border-blue-500/50">
          <h2 className="text-2xl font-semibold text-white mb-4">
            Ready to Implement AI Solutions?
          </h2>
          <p className="text-slate-300 mb-6 max-w-2xl mx-auto">
            Let's discuss how we can help you leverage Google's Generative AI capabilities for your business.
          </p>
          <div className="flex flex-col sm:flex-row justify-center gap-4">
            <button
              onClick={() => handleChatWithNia('Generative AI consultation')}
              className="bg-blue-500 hover:bg-blue-600 text-white px-8 py-3 rounded-lg transition-colors"
            >
              Chat with Nia
            </button>
            <button
              onClick={() => handleChatWithNia('Gen-AI demo request')}
              className="bg-slate-700 hover:bg-slate-600 text-white px-8 py-3 rounded-lg transition-colors"
            >
              Request Demo
            </button>
          </div>
        </div>
      </div>
      <ChatModal 
        show={showChatModal}
        onClose={() => setShowChatModal(false)}
        initialMessage={chatMessage}
        />
    </div>
  );
};

export default GenAIImplementation;