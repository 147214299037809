// src/components/NiaCall/NiaLiveCall.js
import React, { useState, useEffect, useRef } from 'react';
import { useLiveAPIContext } from './LiveAPIContext';
import { 
  CallContainer, 
  CallButton,
  CallControls,
  StatusText 
} from './styles';

const NiaLiveCall = () => {
  const { client, isLoading, error, setConfig } = useLiveAPIContext();
  const [status, setStatus] = useState('Initializing...');
  const [isSupported, setIsSupported] = useState(false);
  
  useEffect(() => {
    // Check if the Live API is available in the SDK
    const checkSupport = async () => {
      if (!client) return;
      
      if (client.experimental && client.experimental.liveSessions) {
        setIsSupported(true);
        setStatus('Live API is supported');
      } else {
        setIsSupported(false);
        setStatus('Live API is not yet available in the JavaScript SDK');
        console.log('Available client methods:', Object.keys(client));
        
        if (client.experimental) {
          console.log('Experimental features:', Object.keys(client.experimental));
        }
      }
    };
    
    if (!isLoading && !error && client) {
      checkSupport();
    } else if (isLoading) {
      setStatus('Loading...');
    } else if (error) {
      setStatus(`Error: ${error.message}`);
    }
  }, [client, isLoading, error]);
  
  return (
    <CallContainer>
      <StatusText>{status}</StatusText>
      
      <div className="p-4 mb-4 bg-blue-100 border border-blue-400 text-blue-700 rounded">
        <p>The Gemini Live API for voice chat is currently in experimental preview.</p>
        <p className="mt-2">JavaScript SDK support is still being developed.</p>
        <p className="mt-2">Check the <a 
          href="https://ai.google.dev/gemini-api/docs/live" 
          target="_blank" 
          rel="noopener noreferrer"
          className="underline"
        >
          official documentation
        </a> for updates.</p>
      </div>
      
      <CallControls>
        <CallButton 
          onClick={() => alert('The Live API for voice chat is not yet available in the JavaScript SDK. We are working on adding this feature soon!')} 
          disabled={!isSupported}
          aria-label="Start call"
        >
          <span role="img" aria-hidden="true">📞</span>
        </CallButton>
      </CallControls>
    </CallContainer>
  );
};

export default NiaLiveCall;