import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../Auth';
import { AlertCircle } from 'lucide-react';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [resetSent, setResetSent] = useState(false);
  const [isResetMode, setIsResetMode] = useState(false);
  
  const { currentUser, signIn, resetPassword } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  
  // Redirect if already logged in
  useEffect(() => {
    if (currentUser) {
      navigate(location.state?.from || '/home');
    }
  }, [currentUser, navigate, location]);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!email || !password) {
      setError('Please enter both email and password');
      return;
    }
    
    try {
      setIsLoading(true);
      setError('');
      
      await signIn(email, password);
      
      //force navigation after successful sign in
      navigate(location.state?.from || '/home');

      // Navigation will happen automatically due to the useEffect
    } catch (err) {
      setError(err.message || 'Failed to sign in');
      setIsLoading(false);
    }
  };
  
  const handleResetPassword = async (e) => {
    e.preventDefault();
    
    if (!email) {
      setError('Please enter your email address');
      return;
    }
    
    try {
      console.log("Attempting to send password reset to:", email);
      setIsLoading(true);
      setError('');
      
      await resetPassword(email);
      console.log("Password reset email sent successfully");
      setResetSent(true);
      setIsLoading(false);
    } catch (err) {
      console.error("Password reset error:", err);
      setError(err.message || 'Failed to send reset email');
      setIsLoading(false);
    }
  };
  
  const toggleResetMode = () => {
    setIsResetMode(!isResetMode);
    setError('');
    setResetSent(false);
  };
  
  return (
    <div className="min-h-screen bg-slate-950 flex items-center justify-center p-4">
      <div className="w-full max-w-md bg-slate-900 rounded-lg shadow-xl overflow-hidden">
        <div className="p-6">
          <div className="flex justify-center mb-6">
            <div className="flex items-center space-x-2">
              <div className="w-8 h-8 bg-blue-500 rounded-lg flex items-center justify-center text-white font-bold">N</div>
              <span className="font-bold text-lg text-white">Strategy Hub</span>
            </div>
          </div>
          
          <h2 className="text-xl font-bold text-white mb-6 text-center">
            {isResetMode ? 'Reset Password' : 'Sign in to your account'}
          </h2>
          
          {error && (
            <div className="bg-red-500/10 border border-red-500/20 rounded-lg p-3 mb-4 flex items-start">
              <AlertCircle className="w-5 h-5 text-red-500 mr-2 flex-shrink-0 mt-0.5" />
              <span className="text-red-400 text-sm">{error}</span>
            </div>
          )}
          
          {resetSent && (
            <div className="bg-green-500/10 border border-green-500/20 rounded-lg p-3 mb-4">
              <span className="text-green-400 text-sm">Password reset email sent. Please check your inbox.</span>
            </div>
          )}
          
          {isResetMode ? (
            <form onSubmit={handleResetPassword} className="space-y-4">
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-slate-300 mb-1">
                  Email address
                </label>
                <input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-3 py-2 bg-slate-800 border border-slate-700 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="name@company.com"
                />
              </div>
              
              <div>
                <button
                  type="submit"
                  disabled={isLoading}
                  className={`w-full py-2 px-4 rounded-md text-white font-medium ${
                    isLoading
                      ? 'bg-blue-600 opacity-70 cursor-not-allowed'
                      : 'bg-blue-600 hover:bg-blue-700'
                  }`}
                >
                  {isLoading ? 'Sending...' : 'Send Reset Link'}
                </button>
              </div>
              
              <div className="text-center mt-4">
                <button
                  type="button"
                  onClick={toggleResetMode}
                  className="text-blue-400 hover:text-blue-300 text-sm"
                >
                  Back to Sign In
                </button>
              </div>
            </form>
          ) : (
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-slate-300 mb-1">
                  Email address
                </label>
                <input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-3 py-2 bg-slate-800 border border-slate-700 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="name@company.com"
                />
              </div>
              
              <div>
                <label htmlFor="password" className="block text-sm font-medium text-slate-300 mb-1">
                  Password
                </label>
                <input
                  id="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full px-3 py-2 bg-slate-800 border border-slate-700 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="••••••••"
                />
              </div>
              
              <div className="flex items-center justify-end">
                <button
                  type="button"
                  onClick={toggleResetMode}
                  className="text-sm text-blue-400 hover:text-blue-300"
                >
                  Forgot password?
                </button>
              </div>
              
              <div>
                <button
                  type="submit"
                  disabled={isLoading}
                  className={`w-full py-2 px-4 rounded-md text-white font-medium ${
                    isLoading
                      ? 'bg-blue-600 opacity-70 cursor-not-allowed'
                      : 'bg-blue-600 hover:bg-blue-700'
                  }`}
                >
                  {isLoading ? 'Signing in...' : 'Sign in'}
                </button>
              </div>
            </form>
          )}
            <div className="mt-4 text-center">
              <p className="text-sm text-slate-400">
                  Don't have an account? 
              <button
              onClick={() => navigate('/signup')}
              className="ml-1 text-blue-400 hover:text-blue-300"
              >
              Sign up
              </button>
              </p>
            </div>
        </div>        
        <div className="px-6 py-4 bg-slate-800/50 border-t border-slate-800">
          <p className="text-sm text-slate-400 text-center">
            This portal is restricted to gClouds team members only.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;