import React, { useState } from 'react';
import {
  Shield,
  Users,
  Globe,
  CheckCircle,
  Building2,
  Server,
  Brain,
  Cloud,
  Mail,
  X
} from 'lucide-react';
import AIChatBox from '../../AIChatBox';

const ChatModal = ({ show, onClose, initialMessage }) => {
  if (!show) return null;
  return (
    <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
      <div className="bg-slate-900 rounded-xl max-w-4xl w-full">
        <div className="flex justify-between items-center p-6 border-b border-slate-800">
          <h2 className="text-2xl font-semibold text-white">Chat with Nia</h2>
          <button onClick={onClose} className="text-slate-400 hover:text-white">
            <X className="w-6 h-6" />
          </button>
        </div>
        <div className="p-6">
          <AIChatBox initialMessageModal={initialMessage} />
        </div>
      </div>
    </div>
  );
};

const Partnerships = () => {
  const [showChatModal, setShowChatModal] = useState(false);
  const [chatMessage, setChatMessage] = useState('');

  const handleChatWithNia = (topic) => {
    const message = `Hi Nia, I'm interested in exploring a partnership with gClouds for ${topic}. Could you tell me more about your partner program and benefits?`;
    setShowChatModal(true);
    setChatMessage(message);
  };

  const partnerships = [
    { 
      icon: Cloud,
      title: "Google Cloud Platform",
      description: "Service Partner in UK & Ireland",
      capabilities: [
        "Infrastructure modernisation",
        "Data analytics and AI/ML",
        "Application development",
        "Professional services delivery"
      ]
    },
    { 
      icon: Mail,
      title: "Google Workspace",
      description: "Official Reseller Partner",
      capabilities: [
        "License provisioning",
        "Enterprise deployment",
        "Migration",
        "Technical support"
      ]
    },
    { 
      icon: Brain,
      title: "Google Cloud Marketplace Solutions",
      description: "Technology Partner",
      capabilities: [
        "Microsoft SQL Server Developer Edition",
        "Microsoft SQL Server Express Edition",
        "Ethereum Developer Suit"
      ]
    }
  ];

  const benefits = [
    {
      icon: Shield,
      title: "Certified Expertise",
      description: "Google Cloud certified team with proven implementation experience"
    },
    {
      icon: Users,
      title: "Dedicated Support",
      description: "24/7 technical support and account management"
    },
    {
      icon: Globe,
      title: "Global Reach",
      description: "Supporting clients across UK, Europe, and worldwide"
    },
    {
      icon: Server,
      title: "Technical Resources",
      description: "Access to advanced tools, implementation guides and Google Cloud Partner training programs"
    }
  ];

  const capabilities = [
    {
      icon: Building2,
      title: "Enterprise Solutions",
      points: [
        "Large-scale migrations",
        "Security implementations",
        "Compliance frameworks",
        "Custom development"
      ]
    },
    {
      icon: Cloud,
      title: "Cloud Services",
      points: [
        "Infrastructure automation",
        "DevOps practices",
        "Managed services",
        "Cost optimisation"
      ]
    },
    {
      icon: Brain,
      title: "AI/ML Services",
      points: [
        "Generative AI Chatbots & Virtual Agents",
        "Enterprise Large Language Model Integration",
        "AI-Powered Business Process Automation",
        "End-to-End MLOps & Model Deployment"
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-slate-950">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <div className="flex justify-center mb-8">
            <img 
              src="/images/google-cloud-partner.png" 
              alt="Google Cloud Partner" 
              className="h-16 md:h-20"
            />
          </div>
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
            Partner with gClouds
          </h1>
          <p className="text-xl text-slate-400 max-w-3xl mx-auto mb-8">
            Official Google Cloud Partner delivering expert solutions and support in the UK & Ireland
          </p>
          <button
            onClick={() => handleChatWithNia('solution implementation')}
            className="bg-blue-500 hover:bg-blue-600 text-white px-8 py-3 rounded-lg transition-colors"
          >
            Talk to Nia
          </button>
        </div>

        {/* Partnerships Grid */}
        <div className="grid md:grid-cols-3 gap-8 mb-16">
          {partnerships.map((partnership, index) => (
            <div
              key={index}
              className="bg-slate-800/50 backdrop-blur-xl p-8 rounded-xl border border-slate-700"
            >
              <div className="flex items-start space-x-4">
                <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center flex-shrink-0">
                  <partnership.icon className="w-6 h-6 text-blue-400" />
                </div>
                <div>
                  <h3 className="text-xl font-semibold text-white mb-2">{partnership.title}</h3>
                  <p className="text-slate-400 mb-4">{partnership.description}</p>
                  <ul className="space-y-2">
                    {partnership.capabilities.map((capability, idx) => (
                      <li key={idx} className="flex items-start space-x-2">
                        <CheckCircle className="w-4 h-4 text-blue-400 mt-1" />
                        <span className="text-slate-300">{capability}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Benefits Grid */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            Partnership Benefits
          </h2>
          <div className="grid md:grid-cols-4 gap-8">
            {benefits.map((benefit, index) => (
              <div
                key={index}
                className="bg-slate-800/50 backdrop-blur-xl p-6 rounded-xl border border-slate-700 hover:border-blue-500/50 transition-all"
              >
                <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center mb-4">
                  <benefit.icon className="w-6 h-6 text-blue-400" />
                </div>
                <h3 className="text-lg font-semibold text-white mb-2">{benefit.title}</h3>
                <p className="text-slate-400">{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Capabilities Section */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            Technical Capabilities
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {capabilities.map((capability, index) => (
              <div
                key={index}
                className="bg-slate-800/50 backdrop-blur-xl p-8 rounded-xl border border-slate-700"
              >
                <div className="flex items-start space-x-4">
                  <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center flex-shrink-0">
                    <capability.icon className="w-6 h-6 text-blue-400" />
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold text-white mb-4">{capability.title}</h3>
                    <ul className="space-y-2">
                      {capability.points.map((point, idx) => (
                        <li key={idx} className="flex items-start space-x-2">
                          <CheckCircle className="w-4 h-4 text-blue-400 mt-1" />
                          <span className="text-slate-300">{point}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* CTA Section */}
        <div className="text-center bg-blue-500/10 rounded-xl p-8 border border-blue-500/50">
          <h2 className="text-2xl font-semibold text-white mb-4">
            Ready to Partner with Us?
          </h2>
          <p className="text-slate-300 mb-6 max-w-2xl mx-auto">
            Let's discuss how we can work together to deliver exceptional cloud solutions for your customers.
          </p>
          <button
            onClick={() => handleChatWithNia('partnership opportunities')}
            className="bg-blue-500 hover:bg-blue-600 text-white px-8 py-3 rounded-lg transition-colors"
          >
            Chat with Nia
          </button>
        </div>
      </div>
      <ChatModal 
        show={showChatModal}
        onClose={() => setShowChatModal(false)}
        initialMessage={chatMessage}
        />
    </div>
  );
};

export default Partnerships;