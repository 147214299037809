import React, { useState } from 'react';
import {
  MessageSquare,
  Brain,
  Zap,
  Users,
  Globe,
  Database,
  Lock,
  BarChart,
  Calendar,
  FileText,
  CheckCircle,
  X
} from 'lucide-react';
import AIChatBox from '../../AIChatBox';

const ChatModal = ({ show, onClose, initialMessage }) => {
  if (!show) return null;
  return (
    <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
      <div className="bg-slate-900 rounded-xl max-w-4xl w-full">
        <div className="flex justify-between items-center p-6 border-b border-slate-800">
          <h2 className="text-2xl font-semibold text-white">Chat with Nia</h2>
          <button onClick={onClose} className="text-slate-400 hover:text-white">
            <X className="w-6 h-6" />
          </button>
        </div>
        <div className="p-6">
          <AIChatBox initialMessageModal={initialMessage} />
        </div>
      </div>
    </div>
  );
};

const AIChat = () => {
  const [showChatModal, setShowChatModal] = useState(false);
  const [chatMessage, setChatMessage] = useState('');

  const handleChatWithNia = (topic) => {
    const message = `Hi Nia, I'm interested in implementing ${topic} for my organisation. Could you tell me more about your chatbot integration services and how you could help us?`;
    setShowChatModal(true);
    setChatMessage(message);
  };

  const features = [
    {
      icon: Brain,
      title: "AI-Powered Intelligence",
      description: "Advanced language models for natural conversations and accurate responses"
    },
    {
      icon: Database,
      title: "Custom Knowledge Base",
      description: "Integration with your business data and documentation"
    },
    {
      icon: Calendar,
      title: "Automated Calendar Events",
      description: "Direct calendar integration for meeting bookings"
    },
    {
      icon: Lock,
      title: "Enterprise Security",
      description: "Secure data handling and compliance measures"
    },
    {
      icon: Globe,
      title: "Multi-Channel Support",
      description: "Deploy across website and mobile apps"
    },
    {
      icon: BarChart,
      title: "Agent Based Intelligence",
      description: "Using Function Calls for advance chatbots. We make chatbot to do real world actions!"
    }
  ];

  const capabilities = [
    {
      icon: MessageSquare,
      title: "Intelligent Conversations",
      points: [
        "Natural language understanding",
        "Context retention",
        "Personalised responses",
        "Multi-turn dialogue"
      ]
    },
    {
      icon: Users,
      title: "Sales Enablement",
      points: [
        "Product recommendations",
        "Pricing information",
        "Meeting scheduling",
        "Quote generation"
      ]
    },
    {
      icon: Zap,
      title: "Business Integration",
      points: [
        "CRM integration",
        "Calendar management",
        "Document access",
        "Email notifications"
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-slate-950">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
            AI Chat Solutions
          </h1>
          <p className="text-xl text-slate-400 max-w-3xl mx-auto mb-8">
            Transform your customer engagement with an AI assistant like Nia
          </p>
        </div>

        {/* Live Demo Section */}
        <div className="mb-16">
          <div className="text-center mb-8">
            <h2 className="text-2xl font-semibold text-white mb-4">
              Meet Nia - Your AI Sales Assistant
            </h2>
            <p className="text-slate-400 max-w-2xl mx-auto">
              Experience our AI technology firsthand. Nia is helping customers right now, just as your AI assistant could.
            </p>
          </div>
          
          <div className="max-w-2xl mx-auto">
            <AIChatBox />
          </div>
        </div>

        {/* Features Grid */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            Key Features
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <div
                key={index}
                className="bg-slate-800/50 backdrop-blur-xl p-6 rounded-xl border border-slate-700 hover:border-blue-500/50 transition-all"
              >
                <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center mb-4">
                  <feature.icon className="w-6 h-6 text-blue-400" />
                </div>
                <h3 className="text-lg font-semibold text-white mb-2">{feature.title}</h3>
                <p className="text-slate-400">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Capabilities Section */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            AI Capabilities
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {capabilities.map((capability, index) => (
              <div
                key={index}
                className="bg-slate-800/50 backdrop-blur-xl p-8 rounded-xl border border-slate-700"
              >
                <div className="flex items-start space-x-4">
                  <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center flex-shrink-0">
                    <capability.icon className="w-6 h-6 text-blue-400" />
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold text-white mb-4">{capability.title}</h3>
                    <ul className="space-y-2">
                      {capability.points.map((point, idx) => (
                        <li key={idx} className="flex items-start space-x-2 text-slate-300">
                          <CheckCircle className="w-4 h-4 text-blue-400 mt-1" />
                          <span>{point}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Implementation Process */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            Getting Started
          </h2>
          <div className="max-w-3xl mx-auto bg-slate-800/50 backdrop-blur-xl rounded-xl border border-slate-700 p-8">
            <div className="space-y-6">
              <div className="flex items-start space-x-4">
                <div className="w-8 h-8 bg-blue-500/10 rounded-lg flex items-center justify-center flex-shrink-0">
                  <FileText className="w-4 h-4 text-blue-400" />
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-white mb-2">1. Requirements Analysis</h3>
                  <p className="text-slate-400">We'll analyze your business needs and define the AI assistant's scope</p>
                </div>
              </div>
              <div className="flex items-start space-x-4">
                <div className="w-8 h-8 bg-blue-500/10 rounded-lg flex items-center justify-center flex-shrink-0">
                  <Database className="w-4 h-4 text-blue-400" />
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-white mb-2">2. Knowledge Integration</h3>
                  <p className="text-slate-400">Setup your custom knowledge base and business logic</p>
                </div>
              </div>
              <div className="flex items-start space-x-4">
                <div className="w-8 h-8 bg-blue-500/10 rounded-lg flex items-center justify-center flex-shrink-0">
                  <Users className="w-4 h-4 text-blue-400" />
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-white mb-2">3. Training & Testing</h3>
                  <p className="text-slate-400">Fine-tune the AI with your specific use cases</p>
                </div>
              </div>
              <div className="flex items-start space-x-4">
                <div className="w-8 h-8 bg-blue-500/10 rounded-lg flex items-center justify-center flex-shrink-0">
                  <Globe className="w-4 h-4 text-blue-400" />
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-white mb-2">4. Deployment</h3>
                  <p className="text-slate-400">Launch your AI assistant across your chosen platforms</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* CTA Section */}
        <div className="text-center bg-blue-500/10 rounded-xl p-8 border border-blue-500/50">
          <h2 className="text-2xl font-semibold text-white mb-4">
            Ready to Create Your Own AI Assistant?
          </h2>
          <p className="text-slate-300 mb-6 max-w-2xl mx-auto">
            Let's discuss how we can create a customised AI chat solution for your business, just like Nia.
          </p>
          <div className="flex flex-col sm:flex-row justify-center gap-4">
          <button
            onClick={() => handleChatWithNia('AI chatbot implementation')}
            className="bg-blue-500 hover:bg-blue-600 text-white px-8 py-3 rounded-lg transition-colors"
          >
            Chat with Nia
          </button>
          <button
              onClick={() => handleChatWithNia('chatbot demo')}
              className="bg-slate-700 hover:bg-slate-600 text-white px-8 py-3 rounded-lg transition-colors"
            >
              Request Demo
            </button>
          </div>
        </div>
      </div>
      <ChatModal 
        show={showChatModal}
        onClose={() => setShowChatModal(false)}
        initialMessage={chatMessage}
        />
    </div>
  );
};

export default AIChat;