import styled from 'styled-components';

export const CallContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
  max-width: 300px;
  margin: 0 auto;
`;

export const VoiceVisualizer = styled.div`
  width: 150px;
  height: 40px;
  background: ${props => props.active ? '#333' : '#eee'};
  border-radius: 8px;
  margin: 10px 0;
  position: relative;
  overflow: hidden;
  
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      90deg,
      rgba(76, 175, 80, 0.2) 0%,
      rgba(76, 175, 80, 0.4) 50%,
      rgba(76, 175, 80, 0.2) 100%
    );
    animation: ${props => props.active ? 'voice-wave 1.5s infinite ease-in-out' : 'none'};
  }
  
  @keyframes voice-wave {
    0%, 100% {
      clip-path: polygon(
        0% 60%, 10% 55%, 20% 50%, 30% 45%, 40% 40%, 
        50% 45%, 60% 50%, 70% 55%, 80% 60%, 90% 55%, 100% 50%
      );
    }
    50% {
      clip-path: polygon(
        0% 50%, 10% 45%, 20% 40%, 30% 50%, 40% 60%, 
        50% 50%, 60% 40%, 70% 45%, 80% 50%, 90% 55%, 100% 60%
      );
    }
  }
`;

export const StatusText = styled.div`
  margin-bottom: 20px;
  font-weight: 500;
  text-align: center;
`;

export const CallTimer = styled.div`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 16px;
  color: #333;
  font-family: monospace;
`;

export const CallControls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

const BaseButton = styled.button`
  border: none;
  cursor: pointer;
  margin: 5px;
  font-size: 16px;
  transition: all 0.2s;
  
  &:active {
    transform: scale(0.95);
  }
`;

export const CallButton = styled(BaseButton)`
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-color: #4CAF50;
  color: white;
  font-size: 24px;
`;

export const HangupButton = styled(BaseButton)`
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-color: #f44336;
  color: white;
  font-size: 24px;
`;

export const MuteButton = styled(BaseButton)`
  width: 100px;
  height: 40px;
  border-radius: 20px;
  background-color: #eee;
  color: #333;
`;