import React, { useState } from 'react';
import AIChatBox from '../../components/AIChatBox';
import {
  CheckCircle,
  X,
  MessageSquare,
  Shield,
  Lock,
  Database
} from 'lucide-react';

const ChatModal = ({ show, onClose, initialMessage }) => {
  if (!show) return null;
  return (
    <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
      <div className="bg-slate-900 rounded-xl max-w-4xl w-full">
        <div className="flex justify-between items-center p-6 border-b border-slate-800">
          <h2 className="text-2xl font-semibold text-white">Chat with Nia</h2>
          <button onClick={onClose} className="text-slate-400 hover:text-white">
            <X className="w-6 h-6" />
          </button>
        </div>
        <div className="p-6">
          <AIChatBox initialMessageModal={initialMessage} />
        </div>
      </div>
    </div>
  );
};

const SecurityServices = () => {
  const [showChatModal, setShowChatModal] = useState(false);
  const [chatMessage, setChatMessage] = useState('');

  const handleScheduleConsultant = (topic) => {
    const message = `Hi Nia, I'm interested in for the ${topic}. Could you please schedule a meeting with an expert?`;
    setShowChatModal(true);
    setChatMessage(message);
  };

  const handleChatWithNia = (topic) => {
    const message = `Hi Nia, I'm interested in ${topic}. Could you help me understand how you could enhance our cloud security?`;
    setShowChatModal(true);
    setChatMessage(message);
  };

  const keyDeliverables = [
    "Security Assessment Report",
    "Security Architecture Review",
    "Compliance Gap Analysis",
    "Mitigation Roadmap",
    "Executive Summary",
    "Implementation Guide"
  ];

  const gCloudsTeam = [
    "Professional Cloud Security Engineer",
    "Professional Cloud Architect",
    "Security Operations Specialist"
  ];

  return (
    <div className="min-h-screen bg-slate-950">
      <div className="max-w-5xl mx-auto px-4 py-16">
        {/* Hero Section */}
        <div className="mb-16">
          <h1 className="text-4xl font-bold text-white mb-4">
            Cloud Security Services
          </h1>
          <p className="text-xl text-slate-400 mb-6">
            Comprehensive security assessment and implementation services to enhance your Google Cloud security posture, monitor threats, and protect sensitive data. Our experts help you implement security best practices and maintain compliance.
          </p>
          <div className="space-x-4">
            <button 
              onClick={() => handleScheduleConsultant('Cloud Security Services')}
              className="bg-blue-500 text-white px-6 py-3 rounded-lg hover:bg-blue-600 transition-colors"
            >
              Consult with an expert
            </button>
          </div>
        </div>

        {/* What Your Team Gets */}
        <div className="bg-slate-900/50 rounded-xl p-8 mb-16 backdrop-blur-xl border border-slate-800">
          <h2 className="text-2xl font-semibold text-white mb-6">What we deliver</h2>
          
          <div className="space-y-6">
            <div className="flex items-start space-x-4">
              <Shield className="w-6 h-6 text-blue-400 mt-1 flex-shrink-0" />
              <div>
                <h3 className="font-semibold text-white">Security Operations & Monitoring</h3>
                <p className="text-slate-400">Comprehensive review of GCP logging and monitoring capabilities, identifying coverage gaps and improving threat detection capabilities.</p>
              </div>
            </div>

            <div className="flex items-start space-x-4">
              <Lock className="w-6 h-6 text-blue-400 mt-1 flex-shrink-0" />
              <div>
                <h3 className="font-semibold text-white">Security Posture Management</h3>
                <p className="text-slate-400">In-depth analysis of security configurations, best practices, and risk assessment with actionable recommendations for improvement.</p>
              </div>
            </div>

            <div className="flex items-start space-x-4">
              <Database className="w-6 h-6 text-blue-400 mt-1 flex-shrink-0" />
              <div>
                <h3 className="font-semibold text-white">Workspace Data Protection</h3>
                <p className="text-slate-400">Enhanced security controls for sensitive data in Google Workspace, ensuring compliance and secure collaboration.</p>
              </div>
            </div>
          </div>
        </div>

        {/* Service Offerings */}
        <div className="grid md:grid-cols-2 gap-8 mb-16">
          {/* Cloud Security Monitoring */}
          <div className="border border-slate-800 rounded-xl p-6 bg-slate-900/50 backdrop-blur-xl">
            <h3 className="text-xl font-semibold text-white mb-4">Cloud Security Monitoring</h3>
            <ul className="space-y-2">
              <li className="flex items-center space-x-2">
                <CheckCircle className="w-4 h-4 text-blue-400" />
                <span className="text-slate-300">Security Operations Review</span>
              </li>
              <li className="flex items-center space-x-2">
                <CheckCircle className="w-4 h-4 text-blue-400" />
                <span className="text-slate-300">Threat Analysis & Detection</span>
              </li>
              <li className="flex items-center space-x-2">
                <CheckCircle className="w-4 h-4 text-blue-400" />
                <span className="text-slate-300">Log Architecture Design</span>
              </li>
              <li className="flex items-center space-x-2">
                <CheckCircle className="w-4 h-4 text-blue-400" />
                <span className="text-slate-300">4-week delivery</span>
              </li>
              <li className="flex items-center space-x-2">
                <CheckCircle className="w-4 h-4 text-blue-400" />
                <span className="text-slate-300">Starting from £15K</span>
              </li>
            </ul>
          </div>

          {/* Security Posture Review */}
          <div className="border border-slate-800 rounded-xl p-6 bg-slate-900/50 backdrop-blur-xl">
            <h3 className="text-xl font-semibold text-white mb-4">Security Posture Review</h3>
            <ul className="space-y-2">
              <li className="flex items-center space-x-2">
                <CheckCircle className="w-4 h-4 text-blue-400" />
                <span className="text-slate-300">Configuration Review</span>
              </li>
              <li className="flex items-center space-x-2">
                <CheckCircle className="w-4 h-4 text-blue-400" />
                <span className="text-slate-300">Best Practices Assessment</span>
              </li>
              <li className="flex items-center space-x-2">
                <CheckCircle className="w-4 h-4 text-blue-400" />
                <span className="text-slate-300">Mitigation Roadmap</span>
              </li>
              <li className="flex items-center space-x-2">
                <CheckCircle className="w-4 h-4 text-blue-400" />
                <span className="text-slate-300">4-week delivery</span>
              </li>
              <li className="flex items-center space-x-2">
                <CheckCircle className="w-4 h-4 text-blue-400" />
                <span className="text-slate-300">Starting from £15K</span>
              </li>
            </ul>
          </div>
        </div>

        {/* Key Deliverables */}
        <div className="grid md:grid-cols-2 gap-8 mb-16">
          {/* Deliverables */}
          <div className="border border-slate-800 rounded-xl p-6 bg-slate-900/50 backdrop-blur-xl">
            <h3 className="text-xl font-semibold text-white mb-4">Key deliverables</h3>
            <ul className="space-y-2">
              {keyDeliverables.map((deliverable, index) => (
                <li key={index} className="flex items-center space-x-2">
                  <CheckCircle className="w-4 h-4 text-blue-400" />
                  <span className="text-slate-300">{deliverable}</span>
                </li>
              ))}
            </ul>
          </div>

          {/* Team */}
          <div className="border border-slate-800 rounded-xl p-6 bg-slate-900/50 backdrop-blur-xl">
            <h3 className="text-xl font-semibold text-white mb-4">Team composition</h3>
            <ul className="space-y-2">
              {gCloudsTeam.map((role, index) => (
                <li key={index} className="flex items-center space-x-2">
                  <CheckCircle className="w-4 h-4 text-blue-400" />
                  <span className="text-slate-300">{role}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Additional Service */}
        <div className="border border-slate-800 rounded-xl p-6 bg-slate-900/50 backdrop-blur-xl mb-16">
          <div className="flex items-center space-x-3 mb-4">
            <Database className="w-6 h-6 text-blue-400" />
            <h3 className="text-xl font-semibold text-white">Workspace Data Protection</h3>
          </div>
          <p className="text-slate-400 mb-4">Enhanced security controls for sensitive data in Google Workspace, ensuring compliance and secure collaboration between multiple parties.</p>
          <ul className="space-y-2 mb-4">
            <li className="flex items-center space-x-2">
              <CheckCircle className="w-4 h-4 text-blue-400" />
              <span className="text-slate-300">3-day delivery</span>
            </li>
            <li className="flex items-center space-x-2">
              <CheckCircle className="w-4 h-4 text-blue-400" />
              <span className="text-slate-300">Starting from £1.5K</span>
            </li>
          </ul>
        </div>

        {/* Bottom CTA */}
        <div className="text-center bg-blue-500/10 rounded-xl p-8 border border-blue-500/50">
          <h2 className="text-2xl font-semibold text-white mb-4">
            Ready to Enhance Your Cloud Security?
          </h2>
          <div className="space-x-4">
            <button 
              onClick={() => handleScheduleConsultant('Cloud Security Services')}
              className="bg-blue-500 text-white px-8 py-3 rounded-lg hover:bg-blue-600 transition-colors"
            >
              Consult with an expert
            </button>
            <button
              onClick={() => handleChatWithNia('Cloud Security Services')}
              className="inline-flex items-center bg-blue-500/10 text-blue-400 px-8 py-3 rounded-lg hover:bg-blue-500/20 transition-colors"
            >
              <MessageSquare className="mr-2 w-5 h-5" />
              Chat with Nia
            </button>
          </div>
        </div>
      </div>

      <ChatModal 
        show={showChatModal}
        onClose={() => setShowChatModal(false)}
        initialMessage={chatMessage}
      />
    </div>
  );
};

export default SecurityServices;