import React, { useState } from 'react';
import {
  MessageSquare,
  Mail,
  Clock,
  Shield,
  Users,
  CheckCircle,
  AlertTriangle,
  X,
  Zap
} from 'lucide-react';
import SupportForm from './SupportForm';
import AIChatBox from '../../AIChatBox';

const Modal = ({ show, onClose, children }) => {
  if (!show) return null;
  
  return (
    <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
      <div className="bg-slate-900 rounded-xl max-w-4xl w-full max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center p-6 border-b border-slate-800">
          <h2 className="text-2xl font-semibold text-white">Create Support Ticket</h2>
          <button onClick={onClose} className="text-slate-400 hover:text-white">
            <X className="w-6 h-6" />
          </button>
        </div>
        <div className="p-6">
          {children}
        </div>
      </div>
    </div>
  );
};

const ChatModal = ({ show, onClose, initialMessage }) => {
  if (!show) return null;
  return (
    <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
      <div className="bg-slate-900 rounded-xl max-w-4xl w-full">
        <div className="flex justify-between items-center p-6 border-b border-slate-800">
          <h2 className="text-2xl font-semibold text-white">Chat with Nia</h2>
          <button onClick={onClose} className="text-slate-400 hover:text-white">
            <X className="w-6 h-6" />
          </button>
        </div>
        <div className="p-6">
          <AIChatBox initialMessageModal={initialMessage} />
        </div>
      </div>
    </div>
  );
};

const Support = () => {
  const [showModal, setShowModal] = useState(false);
  const [showChatModal, setShowChatModal] = useState(false);
  const [chatMessage, setChatMessage] = useState('');
  const handleChatWithNia = (topic) => {
  const message = `Hi Nia, I'm interested in ${topic}. Could you help me understand the available support options and pricing?`;
  setShowChatModal(true);
  setChatMessage(message);
};

  const supportPlans = [
    {
      title: "Basic Support",
      description: "Reliable support for small businesses needing essential assistance.",
      features: [
        "Email support from 9am-5pm GMT",
        "Response by the next business day",
        "Access to a pool of engineers",
        "Billing and account enquiries"
      ],
      sla: "Next business day"
    },
    {
      title: "Business Support",
      description: "Comprehensive support for growing organisations requiring faster response times.",
      features: [
        "24/5 email support",
        "4-hour response time",
        "Dedicated support engineer",
        "Monthly performance review calls"
      ],
      sla: "4 hours",
      highlighted: true
    },
    {
      title: "Enterprise Support",
      description: "Elite, round-the-clock support for businesses with mission-critical needs.",
      features: [
        "24/7 priority support",
        "30-minute response time",
        "Dedicated technical account manager",
        "Proactive system monitoring"
      ],
      sla: "30 minutes"
    }
  ];

  const channels = [
    {
      icon: MessageSquare,
      title: "Sales Inquiries",
      description: "Chat with Nia for product information and pricing",
      action: () => handleChatWithNia('sales inquiry'),
      primary: true,
      buttonText: "Chat with Nia"
    },
    {
      icon: Mail,
      title: "Sales Email",
      description: "Contact our sales team directly",
      href: "mailto:gethelp@gclouds.co.uk",
      buttonText: "Email Sales Team"
    },
    {
      icon: AlertTriangle,
      title: "Technical Support",
      description: "Submit a support ticket for technical issues",
      //action: () => setShowModal(true),
      href: "https://forms.gle/mJk8VYQAg89uCnyC8",
      //primary: true,
      target: "_blank",
      rel: "noopener noreferrer",
      buttonText: "Create Ticket"
    },
    {
      icon: Mail,
      title: "Support Email",
      description: "Email our support team directly",
      href: "mailto:support@gclouds.co.uk",
      buttonText: "Email Support Team"
    }
  ];

  const features = [
    {
      icon: Shield,
      title: "Enterprise Security",
      description: "Secure ticket management and data handling"
    },
    {
      icon: Clock,
      title: "Fast Response",
      description: "Quick response times based on your support plan"
    },
    {
      icon: Users,
      title: "Expert Team",
      description: "Google Cloud certified support engineers"
    },
    {
      icon: Zap,
      title: "Proactive Support",
      description: "Issue prevention and best practices guidance"
    }
  ];

  return (
    <div className="min-h-screen bg-slate-950">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <div className="flex justify-center mb-8">
            <img 
              src="/images/google-cloud-partner.png" 
              alt="Google Cloud Partner" 
              className="h-16 md:h-20"
            />
          </div>
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
            Technical Support
          </h1>
          <p className="text-xl text-slate-400 max-w-3xl mx-auto mb-8">
            Get expert help from our certified Google Cloud engineers
          </p>
        </div>

        {/* Support Features */}
        <div className="grid md:grid-cols-4 gap-8 mb-16">
          {features.map((feature, index) => (
            <div
              key={index}
              className="bg-slate-900/50 backdrop-blur-xl p-6 rounded-xl border border-slate-800 hover:border-blue-500/50 transition-all"
            >
              <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center mb-4">
                <feature.icon className="w-6 h-6 text-blue-400" />
              </div>
              <h3 className="text-lg font-semibold text-white mb-2">{feature.title}</h3>
              <p className="text-slate-400">{feature.description}</p>
            </div>
          ))}
        </div>

        {/* Support Channels */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            Support Channels
          </h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
            {channels.map((channel, index) => (
              <div
                key={index}
                className="bg-slate-900/50 backdrop-blur-xl p-6 rounded-xl border border-slate-800 flex flex-col h-full"
              >
                <div className="flex-grow">
                  <div className="flex items-center space-x-3 mb-4">
                    <div className="w-10 h-10 bg-blue-500/10 rounded-lg flex items-center justify-center">
                      <channel.icon className="w-5 h-5 text-blue-400" />
                    </div>
                    <h3 className="text-lg font-semibold text-white mb-2">{channel.title}</h3>
                  </div>
                  <p className="text-slate-400 mb-4">{channel.description}</p>
                </div>
                {channel.href ? (
                  <a
                    href={channel.href}
                    target={channel.target}
                    className="inline-flex items-center justify-center w-full bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg transition-colors"
                  >
                    <span>{channel.buttonText}</span>
                  </a>
                ) : (
                  <button
                    onClick={channel.action}
                    className="inline-flex items-center justify-center w-full bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg transition-colors"
                  >
                    <span>{channel.buttonText}</span>
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>

        {/* Support Plans */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            Support Plans
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {supportPlans.map((plan, index) => (
              <div
                key={index}
                className={`relative bg-slate-900/50 backdrop-blur-xl p-8 rounded-xl border ${
                  plan.highlighted ? 'border-blue-500' : 'border-slate-800'
                }`}
              >
                {plan.highlighted && (
                  <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
                    <span className="bg-blue-500 text-white px-4 py-1 rounded-full text-sm">
                      Most Popular
                    </span>
                  </div>
                )}
                <h3 className="text-xl font-semibold text-white mb-2">{plan.title}</h3>
                <p className="text-slate-400 mb-4">{plan.description}</p>
                <div className="bg-slate-800/50 rounded-lg px-4 py-2 mb-6">
                  <div className="text-sm text-slate-400">Response Time SLA</div>
                  <div className="text-lg font-semibold text-blue-400">{plan.sla}</div>
                </div>
                <ul className="space-y-3 mb-6">
                  {plan.features.map((feature, idx) => (
                    <li key={idx} className="flex items-start space-x-2">
                      <CheckCircle className="w-4 h-4 text-blue-400 mt-1" />
                      <span className="text-slate-300">{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        {/* Bottom CTA */}
        <div className="mt-16 text-center bg-blue-500/10 rounded-xl p-8 border border-blue-500/50">
          <h2 className="text-2xl font-semibold text-white mb-4">
            Need Help Choosing a Support Plan?
          </h2>
          <p className="text-slate-300 mb-6 max-w-2xl mx-auto">
            Chat with Nia to understand which support plan best fits your organisation's needs
          </p>
          <button
            onClick={() => handleChatWithNia('support plan recommendation')}
            className="bg-blue-500 hover:bg-blue-600 text-white px-8 py-3 rounded-lg transition-colors"
          >
            Chat with Nia for Support Plans
          </button>
        </div>
      </div>
    <Modal show={showModal} onClose={() => setShowModal(false)}>
        <SupportForm />
    </Modal>

    <ChatModal 
      show={showChatModal}
      onClose={() => setShowChatModal(false)}
      initialMessage={chatMessage}
    />

    </div>
  );
};

export default Support;