import React, { useState, useEffect } from 'react';
import { Cookie, X } from 'lucide-react';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';

const COOKIE_NOTICE_KEY = 'gclouds_cookie_notice';
const COOKIE_EXPIRY = 14;

const CookieNotice = () => {
  const [showNotice, setShowNotice] = useState(false);

  useEffect(() => {
    // Show notice only if user hasn't dismissed it before
    const noticeAcknowledged = Cookies.get(COOKIE_NOTICE_KEY);
    if (!noticeAcknowledged) {
      // Slight delay to allow welcome message to appear first
      const timer = setTimeout(() => setShowNotice(true), 1000);
      return () => clearTimeout(timer);
    }
  }, []);

  const handleDismiss = () => {
    Cookies.set(COOKIE_NOTICE_KEY, 'gclouds_cookie_notice_accepted', { expires: COOKIE_EXPIRY });
    setShowNotice(false);
  };

  if (!showNotice) return null;

  return (
    <div className="fixed bottom-4 left-0 right-0 bg-slate-900/95 backdrop-blur-sm border border-slate-800 rounded-lg p-4 z-50 mx-4">
      <div className="max-w-7xl mx-auto flex flex-col sm:flex-row items-center justify-between gap-4">
        <div className="flex items-center space-x-4">
          <div className="w-10 h-10 bg-blue-500/10 rounded-lg flex items-center justify-center">
            <Cookie className="w-6 h-6 text-blue-400" />
          </div>
          <div className="text-sm text-slate-300">
            <p>
              This site uses cookies for essential chat functionality.
              See our <Link to="/privacy" className="text-blue-400 hover:text-blue-300">Privacy Policy</Link> for more details.
            </p>
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <button
            onClick={handleDismiss}
            className="bg-blue-500 hover:bg-blue-600 text-white px-6 py-2 rounded-lg transition-colors"
          >
            Got it
          </button>
          <button
            onClick={handleDismiss}
            className="text-slate-400 hover:text-white p-1 rounded-lg hover:bg-slate-800/50 transition-colors"
          >
            <X className="w-5 h-5" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieNotice;