import React, { useState } from 'react';
import AIChatBox from '../../components/AIChatBox';
import { 
  Cloud,
  Server, 
  Shield,
  Code,
  X,
  CheckCircle,
  FileCode,
  Database
} from 'lucide-react';

const ChatModal = ({ show, onClose, initialMessage }) => {
  if (!show) return null;
  return (
    <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
      <div className="bg-slate-900 rounded-xl max-w-4xl w-full">
        <div className="flex justify-between items-center p-6 border-b border-slate-800">
          <h2 className="text-2xl font-semibold text-white">Chat with Nia</h2>
          <button onClick={onClose} className="text-slate-400 hover:text-white">
            <X className="w-6 h-6" />
          </button>
        </div>
        <div className="p-6">
          <AIChatBox initialMessageModal={initialMessage} />
        </div>
      </div>
    </div>
  );
};

const Implementation = () => {
  const [showChatModal, setShowChatModal] = useState(false);
  const [chatMessage, setChatMessage] = useState('');

  const handleChatWithNia = () => {
    const message = `Hi Nia, I'm interested in Google Cloud implementation services. Could you tell me more about your approach and expertise?`;
    setShowChatModal(true);
    setChatMessage(message);
  };

  const services = [
    {
      icon: Cloud,
      title: "Cloud Migration",
      description: "End-to-end migration of applications and infrastructure"
    },
    {
      icon: Server,
      title: "Infrastructure Setup",
      description: "Secure and scalable cloud infrastructure implementation"
    },
    {
      icon: Database,
      title: "Data Migration",
      description: "Zero-downtime database migration and optimisation"
    }
  ];

  const deliverables = [
    {
      icon: FileCode,
      title: "Technical Documentation",
      points: [
        "Detailed architecture diagrams",
        "Technical design documents",
        "Implementation guides",
        "Operational runbooks"
      ]
    },
    {
      icon: Code,
      title: "Infrastructure as Code",
      points: [
        "Terraform templates",
        "Deployment scripts",
        "CI/CD pipelines",
        "Automation workflows"
      ]
    },
    {
      icon: Shield,
      title: "Security Implementation",
      points: [
        "Security controls setup",
        "IAM configuration",
        "Network security",
        "Compliance frameworks"
      ]
    }
  ];

  const phases = [
    {
      title: "Discovery & Planning",
      description: "Comprehensive assessment and implementation planning",
      points: [
        "Infrastructure assessment",
        "Dependencies mapping",
        "Migration strategy",
        "Resource planning"
      ]
    },
    {
      title: "Implementation",
      description: "Systematic execution of technical implementation",
      points: [
        "Environment setup",
        "Security configuration",
        "Workload migration",
        "Integration testing"
      ]
    },
    {
      title: "Optimisation",
      description: "Performance tuning and cost optimisation",
      points: [
        "Performance monitoring",
        "Cost analysis",
        "Resource optimisation",
        "Best practices implementation"
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-slate-950">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <div className="flex justify-center mb-8">
            <img 
              src="/images/google-cloud-partner.png" 
              alt="Google Cloud Partner" 
              className="h-16 md:h-20"
            />
          </div>
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
            Implementation Services
          </h1>
          <p className="text-xl text-slate-400 max-w-3xl mx-auto mb-8">
            End-to-end implementation of Google Cloud solutions with certified experts
          </p>
          <button
            onClick={handleChatWithNia}
            className="bg-blue-500 hover:bg-blue-600 text-white px-8 py-3 rounded-lg transition-colors"
          >
            Talk to Nia
          </button>
        </div>

        {/* Services Grid */}
        <div className="grid md:grid-cols-3 gap-8 mb-16">
          {services.map((service, index) => (
            <div
              key={index}
              className="bg-slate-900/50 backdrop-blur-xl p-6 rounded-xl border border-slate-800 hover:border-blue-500/50 transition-all"
            >
              <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center mb-4">
                <service.icon className="w-6 h-6 text-blue-400" />
              </div>
              <h3 className="text-lg font-semibold text-white mb-2">{service.title}</h3>
              <p className="text-slate-400">{service.description}</p>
            </div>
          ))}
        </div>

        {/* Deliverables Section */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            Key Deliverables
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {deliverables.map((deliverable, index) => (
              <div
                key={index}
                className="bg-slate-900/50 backdrop-blur-xl p-8 rounded-xl border border-slate-800"
              >
                <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center mb-4">
                  <deliverable.icon className="w-6 h-6 text-blue-400" />
                </div>
                <h3 className="text-xl font-semibold text-white mb-4">{deliverable.title}</h3>
                <ul className="space-y-2">
                  {deliverable.points.map((point, idx) => (
                    <li key={idx} className="flex items-start space-x-2 text-slate-300">
                      <CheckCircle className="w-4 h-4 text-blue-400 mt-1" />
                      <span>{point}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        {/* Implementation Phases */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            Implementation Phases
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {phases.map((phase, index) => (
              <div
                key={index}
                className="bg-slate-900/50 backdrop-blur-xl p-8 rounded-xl border border-slate-800"
              >
                <h3 className="text-xl font-semibold text-white mb-2">{phase.title}</h3>
                <p className="text-slate-400 mb-4">{phase.description}</p>
                <ul className="space-y-2">
                  {phase.points.map((point, idx) => (
                    <li key={idx} className="flex items-start space-x-2 text-slate-300">
                      <CheckCircle className="w-4 h-4 text-blue-400 mt-1" />
                      <span>{point}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        {/* CTA Section */}
        <div className="text-center bg-blue-500/10 rounded-xl p-8 border border-blue-500/50">
          <h2 className="text-2xl font-semibold text-white mb-4">
            Ready to Start Your Implementation?
          </h2>
          <p className="text-slate-300 mb-6 max-w-2xl mx-auto">
            Let's discuss your implementation requirements and create a tailored plan for your organisation.
          </p>
          <button
            onClick={handleChatWithNia}
            className="bg-blue-500 hover:bg-blue-600 text-white px-8 py-3 rounded-lg transition-colors"
          >
            Chat with Nia
          </button>
        </div>
      </div>

      <ChatModal 
        show={showChatModal}
        onClose={() => setShowChatModal(false)}
        initialMessage={chatMessage}
      />
    </div>
  );
};

export default Implementation;