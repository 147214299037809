import React, { useState } from 'react';

const NiaAvatar = ({ variant = 'header', state = 'idle' }) => {
  const [imageError, setImageError] = useState(false);

  // This function builds our classes based on variant and state
  const getAvatarClasses = () => {
    // Base classes that are always applied
    const baseClasses = [
      'relative',                    // For positioning
      'overflow-hidden',             // Ensures image stays within bounds
      'rounded',                     // Rounded corners
      'bg-avatar-glow',             // Subtle background glow
      'border',                      // Border for definition
      'border-avatar-border',        // Border color
      'transition-all',              // Smooth transitions
      'duration-300',                // Transition duration
    ];

    // Size classes based on variant
    const sizeClasses = variant === 'header' 
      ? ['w-avatar', 'h-avatar']        // Header size (32x45)
      : ['w-avatar-msg', 'h-avatar-msg']; // Message size (24x34)

    // State-based animation classes
    const stateClasses = {
      idle: [],
      thinking: ['animate-avatar-thinking'],
      speaking: ['animate-avatar-speaking'],
    };

    // Combine all our classes
    return [...baseClasses, ...sizeClasses, ...stateClasses[state]].join(' ');
  };

  // Handle image loading errors
  const handleImageError = () => {
    setImageError(true);
    console.warn('Avatar image failed to load');
  };

  return (
    <div className={getAvatarClasses()}>
      {imageError ? (
        // Fallback when image fails to load
        <div className="w-full h-full flex items-center justify-center">
          <span className="text-blue-400 text-xs font-medium">Nia</span>
        </div>
      ) : (
        <img
          src="/images/Nia5x32x32.png"
          alt="Nia - AI Sales Assistant"
          className="w-full h-full object-cover"
          onError={handleImageError}
        />
      )}
    </div>
  );
};

export default NiaAvatar;