// src/Home.js
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../Auth';
import { LogOut, ChevronRight, LayoutDashboard, User, Settings } from 'lucide-react';

const Home = () => {
  const { currentUser, userRoles, loading, signOut } = useAuth();
  const navigate = useNavigate();
  
  const handleSignOut = async () => {
    try {
      await signOut();
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };
  
  if (loading) {
    return <div className="loading-spinner">Loading...</div>;
  }
  
  const hasStrategyAccess = currentUser && 
    (userRoles.includes('admin') || userRoles.includes('strategy'));
  
  return (
    <div className="min-h-screen bg-slate-950 text-white">
      <header className="bg-slate-900 border-b border-slate-800">
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <div className="w-8 h-8 bg-blue-500 rounded-lg flex items-center justify-center text-white font-bold">N</div>
            <span className="font-bold text-lg">gClouds Portal</span>
          </div>
          
          {currentUser ? (
            <div className="flex items-center space-x-4">
              <div className="text-sm text-slate-300">
                {currentUser.email}
              </div>
              <button
                onClick={handleSignOut}
                className="flex items-center text-slate-300 hover:text-white"
              >
                <LogOut className="w-4 h-4 mr-1" />
                <span>Sign Out</span>
              </button>
            </div>
          ) : (
            <Link
              to="/login"
              className="bg-blue-600 hover:bg-blue-700 px-4 py-2 rounded-md text-sm font-medium"
            >
              Sign In
            </Link>
          )}
        </div>
      </header>
      
      <main className="container mx-auto px-4 py-12">
        <h1 className="text-3xl font-bold mb-8">Welcome to gClouds Portal</h1>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {hasStrategyAccess && (
            <div className="bg-slate-900 border border-slate-800 rounded-lg overflow-hidden">
              <div className="p-6">
                <div className="flex items-center space-x-3 mb-4">
                  <div className="p-2 bg-blue-500/20 rounded-lg">
                    <LayoutDashboard className="w-6 h-6 text-blue-400" />
                  </div>
                  <h2 className="text-xl font-semibold">Strategy Dashboard</h2>
                </div>
                
                <p className="text-slate-300 mb-4">
                  Access the AI-powered Strategy Dashboard to analyze customer conversations and develop effective sales strategies.
                </p>
                
                <Link
                  to="/dashboard"
                  className="flex items-center justify-between bg-blue-600 hover:bg-blue-700 px-4 py-2 rounded-md text-sm font-medium mt-6 w-full"
                >
                  <span>Go to Dashboard</span>
                  <ChevronRight className="w-4 h-4" />
                </Link>
              </div>
            </div>
          )}
          
          {currentUser && (
            <div className="bg-slate-900 border border-slate-800 rounded-lg overflow-hidden">
              <div className="p-6">
                <div className="flex items-center space-x-3 mb-4">
                  <div className="p-2 bg-green-500/20 rounded-lg">
                    <User className="w-6 h-6 text-green-400" />
                  </div>
                  <h2 className="text-xl font-semibold">My Profile</h2>
                </div>
                
                <p className="text-slate-300 mb-4">
                  View and manage your profile settings, roles, and preferences.
                </p>
                
                <Link
                  to="/profile"
                  className="flex items-center justify-between bg-green-600 hover:bg-green-700 px-4 py-2 rounded-md text-sm font-medium mt-6 w-full"
                >
                  <span>View Profile</span>
                  <ChevronRight className="w-4 h-4" />
                </Link>
              </div>
            </div>
          )}
          
          {currentUser && userRoles.includes('admin') && (
            <div className="bg-slate-900 border border-slate-800 rounded-lg overflow-hidden">
              <div className="p-6">
                <div className="flex items-center space-x-3 mb-4">
                  <div className="p-2 bg-purple-500/20 rounded-lg">
                    <Settings className="w-6 h-6 text-purple-400" />
                  </div>
                  <h2 className="text-xl font-semibold">Admin Panel</h2>
                </div>
                
                <p className="text-slate-300 mb-4">
                  Manage users, permissions, and system settings.
                </p>
                
                <Link
                  to="/admin"
                  className="flex items-center justify-between bg-purple-600 hover:bg-purple-700 px-4 py-2 rounded-md text-sm font-medium mt-6 w-full"
                >
                  <span>Admin Panel</span>
                  <ChevronRight className="w-4 h-4" />
                </Link>
              </div>
            </div>
          )}
        </div>
        
        {!currentUser && (
          <div className="mt-12 bg-slate-900/50 border border-slate-800 rounded-lg p-6">
            <h2 className="text-xl font-semibold mb-4">Sign In to Access Portal Features</h2>
            <p className="text-slate-300 mb-4">
              Please sign in to access the Strategy Dashboard and other portal features.
            </p>
            <Link
              to="/login"
              className="inline-block bg-blue-600 hover:bg-blue-700 px-4 py-2 rounded-md text-sm font-medium"
            >
              Sign In
            </Link>
          </div>
        )}
      </main>
    </div>
  );
};

export default Home;