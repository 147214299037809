// /products/gcp/compute-engine
import React, { useState } from 'react';
import { 
  Shield, 
  Cpu,
  Settings,
  X,
  CheckCircle,
  LineChart,
  Zap,
  Network
} from 'lucide-react';
import AIChatBox from '../../AIChatBox';

const ChatModal = ({ show, onClose, initialMessage }) => {
  if (!show) return null;
  return (
    <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
      <div className="bg-slate-900 rounded-xl max-w-4xl w-full">
        <div className="flex justify-between items-center p-6 border-b border-slate-800">
          <h2 className="text-2xl font-semibold text-white">Chat with Nia</h2>
          <button onClick={onClose} className="text-slate-400 hover:text-white">
            <X className="w-6 h-6" />
          </button>
        </div>
        <div className="p-6">
          <AIChatBox initialMessageModal={initialMessage} />
        </div>
      </div>
    </div>
  );
};

const ComputeEngine = () => {
  const [showChatModal, setShowChatModal] = useState(false);
  const [chatMessage, setChatMessage] = useState('');

  const handleChatWithNia = () => {
    const message = `Hi Nia, I'm interested in Google Compute Engine. Could you tell me more about the VM options and how they could benefit my organisation?`;
    setShowChatModal(true);
    setChatMessage(message);
  };

  const features = [
    {
      icon: Cpu,
      title: "High Performance",
      description: "Industry-leading price-performance across all workloads",
      points: [
        "Up to 824TB of memory",
        "Custom machine types",
        "GPU and TPU support",
        "Instance scaling options"
      ]
    },
    {
      icon: Shield,
      title: "Enterprise Security",
      description: "Comprehensive security from chip to the cloud",
      points: [
        "Shielded VMs",
        "Confidential Computing",
        "OS patch management",
        "Identity-based access"
      ]
    },
    {
      icon: Network,
      title: "Global Infrastructure",
      description: "Run workloads where you need them",
      points: [
        "Multiple regions worldwide",
        "Low-latency networking",
        "Load balancing",
        "Auto-scaling groups"
      ]
    }
  ];

  const machineTypes = [
    {
      type: "General-purpose",
      description: "Best price-performance ratio for common workloads",
      useCase: "Web servers, application servers, small-medium databases"
    },
    {
      type: "Memory-optimised",
      description: "Ultra-high memory workloads with the best price per GB of memory",
      useCase: "In-memory databases, analytics, large-scale enterprise applications"
    },
    {
      type: "Compute-optimised",
      description: "Highest performance per core with up to 3.1 GHz sustained all-core turbo",
      useCase: "Gaming, HPC, media transcoding"
    },
    {
      type: "Accelerator-optimised",
      description: "NVIDIA GPUs and Cloud TPUs for ML/AI workloads",
      useCase: "Machine learning training and inference, scientific computing"
    }
  ];

  const solutions = [
    {
      icon: Zap,
      title: "Rapid Deployment",
      description: "Deploy VMs in seconds with our cloud-init compatible startup scripts"
    },
    {
      icon: LineChart,
      title: "Predictive Autoscaling",
      description: "Automatically scale VM instances based on demand"
    },
    {
      icon: Settings,
      title: "Flexible Configuration",
      description: "Choose from predefined or custom machine types for optimal performance"
    }
  ];

  return (
    <div className="min-h-screen bg-slate-950">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Hero Section */}
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="lg:col-span-7">
            <h1 className="text-4xl font-bold text-white mb-6">
              Virtual machines for every workload
            </h1>
            <p className="text-xl text-slate-400 mb-8 max-w-3xl">
              Secure and customisable compute service with industry-leading price-performance, global scale, and built-in premium features.
            </p>
            <div className="space-x-4">
              <button
                id="compute-engine" 
                onClick={handleChatWithNia}
                className="bg-blue-500 hover:bg-blue-600 text-white px-8 py-3 rounded-lg transition-colors"
              >
                Chat with Nia
              </button>
            </div>
          </div>

          {/* Product Highlights Sidebar */}
          <div className="lg:col-span-5 mt-8 lg:mt-0">
            <div className="bg-slate-900/50 backdrop-blur-xl rounded-xl border border-slate-800 p-6">
              <h3 className="text-lg font-semibold text-white mb-4">Product Highlights</h3>
              <ul className="space-y-4">
                <li className="flex items-start space-x-3">
                  <CheckCircle className="w-5 h-5 text-blue-400 mt-1" />
                  <span className="text-slate-300">
                    N2D machine types with AMD EPYC™ processors
                  </span>
                </li>
                <li className="flex items-start space-x-3">
                  <CheckCircle className="w-5 h-5 text-blue-400 mt-1" />
                  <span className="text-slate-300">
                    Spot VMs with up to 91% cost savings
                  </span>
                </li>
                <li className="flex items-start space-x-3">
                  <CheckCircle className="w-5 h-5 text-blue-400 mt-1" />
                  <span className="text-slate-300">
                    Confidential Computing for sensitive workloads
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Machine Types Table */}
        <div className="mt-24">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            Machine Types
          </h2>
          <div className="overflow-x-auto">
            <table className="w-full border-collapse rounded-lg border border-slate-700">
              <thead>
                <tr className="bg-slate-800">
                  <th className="text-left p-4 text-white font-semibold border-b border-slate-700">Type</th>
                  <th className="text-left p-4 text-white font-semibold border-b border-slate-700">Description</th>
                  <th className="text-left p-4 text-white font-semibold border-b border-slate-700">Use Case</th>
                </tr>
              </thead>
              <tbody className="bg-slate-900/50">
                {machineTypes.map((machine, index) => (
                  <tr key={index}>
                    <td className="p-4 border-b border-slate-700">
                      <div className="font-medium text-white">{machine.type}</div>
                    </td>
                    <td className="p-4 border-b border-slate-700 text-slate-300">
                      {machine.description}
                    </td>
                    <td className="p-4 border-b border-slate-700 text-slate-300">
                      {machine.useCase}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Features Section */}
        <div className="mt-24">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            Enterprise Features
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <div
                key={index}
                className="bg-slate-900/50 backdrop-blur-xl p-8 rounded-xl border border-slate-800"
              >
                <div className="flex items-start space-x-4">
                  <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center flex-shrink-0">
                    <feature.icon className="w-6 h-6 text-blue-400" />
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold text-white mb-2">{feature.title}</h3>
                    <p className="text-slate-400 mb-4">{feature.description}</p>
                    <ul className="space-y-2">
                      {feature.points.map((point, idx) => (
                        <li key={idx} className="flex items-start space-x-2 text-slate-300">
                          <CheckCircle className="w-4 h-4 text-blue-400 mt-1" />
                          <span>{point}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Solutions Section */}
        <div className="mt-24">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            Key Solutions
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {solutions.map((solution, index) => (
              <div
                key={index}
                className="bg-slate-900/50 backdrop-blur-xl p-6 rounded-xl border border-slate-800"
              >
                <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center mb-4">
                  <solution.icon className="w-6 h-6 text-blue-400" />
                </div>
                <h3 className="text-lg font-semibold text-white mb-2">{solution.title}</h3>
                <p className="text-slate-400">{solution.description}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Bottom CTA */}
        <div className="mt-24 text-center bg-blue-500/10 rounded-xl p-8 border border-blue-500/50">
          <h2 className="text-2xl font-semibold text-white mb-4">
            Ready to Start with Compute Engine?
          </h2>
          <p className="text-slate-300 mb-6 max-w-2xl mx-auto">
            Chat with Nia to learn more about our compute solutions and find the perfect configuration for your workloads.
          </p>
          <button
            onClick={handleChatWithNia}
            className="bg-blue-500 hover:bg-blue-600 text-white px-8 py-3 rounded-lg transition-colors"
          >
            Chat with Nia
          </button>
        </div>
      </div>

      <ChatModal 
        show={showChatModal}
        onClose={() => setShowChatModal(false)}
        initialMessage={chatMessage}
      />
    </div>
  );
};

export default ComputeEngine;