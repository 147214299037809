import React, { useState } from 'react';
import { 
  Database, 
  Shield, 
  Cloud,
  FileText,
  BarChart,
  X,
  CheckCircle,
  Server
} from 'lucide-react';
import AIChatBox from '../../AIChatBox';

const ChatModal = ({ show, onClose, initialMessage }) => {
  if (!show) return null;
  return (
    <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
      <div className="bg-slate-900 rounded-xl max-w-4xl w-full">
        <div className="flex justify-between items-center p-6 border-b border-slate-800">
          <h2 className="text-2xl font-semibold text-white">Chat with Nia</h2>
          <button onClick={onClose} className="text-slate-400 hover:text-white">
            <X className="w-6 h-6" />
          </button>
        </div>
        <div className="p-6">
          <AIChatBox initialMessageModal={initialMessage} />
        </div>
      </div>
    </div>
  );
};

const CloudStorage = () => {
  const [showChatModal, setShowChatModal] = useState(false);
  const [chatMessage, setChatMessage] = useState('');

  const handleChatWithNia = () => {
    const message = `Hi Nia, I'm interested in Google Cloud Storage solutions. Could you tell me more about the storage options and how they could benefit my organisation?`;
    setShowChatModal(true);
    setChatMessage(message);
  };

  const features = [
    {
      icon: Database,
      title: "Flexible Storage Classes",
      description: "Standard, Nearline, Coldline, and Archive storage options to optimize costs",
      points: [
        "Automatically transition to lower cost storage classes",
        "Policy-based data lifecycle management",
        "Instant access to any storage class"
      ]
    },
    {
      icon: Shield,
      title: "Enterprise Security",
      description: "Built-in security and compliance features",
      points: [
        "Customer-managed encryption keys",
        "Object versioning",
        "IAM integration",
        "Audit logging"
      ]
    },
    {
      icon: Server,
      title: "Global Scale",
      description: "Store and access data from anywhere",
      points: [
        "Multi-region availability",
        "99.999999999% durability",
        "Low-latency access",
        "Automatic replication"
      ]
    }
  ];

  const integrations = [
    {
      icon: FileText,
      title: "BigQuery Integration",
      description: "Directly query data stored in Cloud Storage using BigQuery SQL"
    },
    {
      icon: Cloud,
      title: "AI & ML Ready",
      description: "Seamless integration with Vertex AI and machine learning tools"
    },
    {
      icon: BarChart,
      title: "Analytics Tools",
      description: "Connect with data analytics and processing services"
    }
  ];

  return (
    <div className="min-h-screen bg-slate-950">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Hero Section */}
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="lg:col-span-7">
            <h1 className="text-4xl font-bold text-white mb-6">
              Object storage for companies of all sizes
            </h1>
            <p className="text-xl text-slate-400 mb-8 max-w-3xl">
              Cloud Storage is a managed service for storing unstructured data. Store any amount of data and retrieve it as often as you like.
            </p>
            <div className="space-x-4">
              <button
                onClick={handleChatWithNia}
                className="bg-blue-500 hover:bg-blue-600 text-white px-8 py-3 rounded-lg transition-colors"
              >
                Chat with Nia
              </button>
            </div>
          </div>

          {/* Product Highlights Sidebar */}
          <div className="lg:col-span-5 mt-8 lg:mt-0">
            <div className="bg-slate-900/50 backdrop-blur-xl rounded-xl border border-slate-800 p-6">
              <h3 className="text-lg font-semibold text-white mb-4">Product Highlights</h3>
              <ul className="space-y-4">
                <li className="flex items-start space-x-3">
                  <CheckCircle className="w-5 h-5 text-blue-400 mt-1" />
                  <span className="text-slate-300">
                    Automatically transition to lower-cost storage classes
                  </span>
                </li>
                <li className="flex items-start space-x-3">
                  <CheckCircle className="w-5 h-5 text-blue-400 mt-1" />
                  <span className="text-slate-300">
                    Standard, nearline, coldline, and archive storage options
                  </span>
                </li>
                <li className="flex items-start space-x-3">
                  <CheckCircle className="w-5 h-5 text-blue-400 mt-1" />
                  <span className="text-slate-300">
                    Fast, low-cost, highly durable archive and backup storage
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Features Section */}
        <div className="mt-24">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            Enterprise Features
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <div
                key={index}
                className="bg-slate-900/50 backdrop-blur-xl p-8 rounded-xl border border-slate-800"
              >
                <div className="flex items-start space-x-4">
                  <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center flex-shrink-0">
                    <feature.icon className="w-6 h-6 text-blue-400" />
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold text-white mb-2">{feature.title}</h3>
                    <p className="text-slate-400 mb-4">{feature.description}</p>
                    <ul className="space-y-2">
                      {feature.points.map((point, idx) => (
                        <li key={idx} className="flex items-start space-x-2 text-slate-300">
                          <CheckCircle className="w-4 h-4 text-blue-400 mt-1" />
                          <span>{point}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Storage Options Table */}
        <div className="mt-24">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            Storage Options
          </h2>
          <div className="overflow-x-auto">
            <table className="w-full border-collapse rounded-lg border border-slate-700">
              <thead>
                <tr className="bg-slate-800">
                  <th className="text-left p-4 text-white font-semibold border-b border-slate-700">Storage Type</th>
                  <th className="text-left p-4 text-white font-semibold border-b border-slate-700">Description</th>
                  <th className="text-left p-4 text-white font-semibold border-b border-slate-700">Use Case</th>
                </tr>
              </thead>
              <tbody className="bg-slate-900/50">
                <tr>
                  <td className="p-4 border-b border-slate-700">
                    <div className="font-medium text-white">Standard Storage</div>
                  </td>
                  <td className="p-4 border-b border-slate-700 text-slate-300">
                    Storage for data that is frequently accessed ("hot" data) and/or stored for only brief periods of time.
                  </td>
                  <td className="p-4 border-b border-slate-700 text-slate-300">
                    "Hot" data, including websites, streaming videos, and mobile apps.
                  </td>
                </tr>
                <tr>
                  <td className="p-4 border-b border-slate-700">
                    <div className="font-medium text-white">Nearline Storage</div>
                  </td>
                  <td className="p-4 border-b border-slate-700 text-slate-300">
                    Low cost, highly durable storage service for storing infrequently accessed data.
                  </td>
                  <td className="p-4 border-b border-slate-700 text-slate-300">
                    Data that can be stored for 30 days.
                  </td>
                </tr>
                <tr>
                  <td className="p-4 border-b border-slate-700">
                    <div className="font-medium text-white">Coldline Storage</div>
                  </td>
                  <td className="p-4 border-b border-slate-700 text-slate-300">
                    A very low cost, highly durable storage service for storing infrequently accessed data.
                  </td>
                  <td className="p-4 border-b border-slate-700 text-slate-300">
                    Data that can be stored for 90 days.
                  </td>
                </tr>
                <tr>
                  <td className="p-4">
                    <div className="font-medium text-white">Archive Storage</div>
                  </td>
                  <td className="p-4 text-slate-300">
                    The lowest cost, highly durable storage service for data archiving, online backup, and disaster recovery.
                  </td>
                  <td className="p-4 text-slate-300">
                    Data that can be stored for 365 days.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* Integrations Section */}
        <div className="mt-24">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            Powerful Integrations
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {integrations.map((integration, index) => (
              <div
                key={index}
                className="bg-slate-900/50 backdrop-blur-xl p-6 rounded-xl border border-slate-800"
              >
                <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center mb-4">
                  <integration.icon className="w-6 h-6 text-blue-400" />
                </div>
                <h3 className="text-lg font-semibold text-white mb-2">{integration.title}</h3>
                <p className="text-slate-400">{integration.description}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Bottom CTA */}
        <div className="mt-24 text-center bg-blue-500/10 rounded-xl p-8 border border-blue-500/50">
          <h2 className="text-2xl font-semibold text-white mb-4">
            Ready to Get Started with Cloud Storage?
          </h2>
          <p className="text-slate-300 mb-6 max-w-2xl mx-auto">
            Chat with Nia to learn more about our storage solutions and find the perfect fit for your organisation.
          </p>
          <button
            onClick={handleChatWithNia}
            className="bg-blue-500 hover:bg-blue-600 text-white px-8 py-3 rounded-lg transition-colors"
          >
            Chat with Nia
          </button>
        </div>
      </div>

      <ChatModal 
        show={showChatModal}
        onClose={() => setShowChatModal(false)}
        initialMessage={chatMessage}
      />
    </div>
  );
};

export default CloudStorage;