import React from 'react';
import { Cloud, Database, Network, Lock, Server, Globe, Monitor, Calendar, Shield, Brain } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import AIChatBox from './components/AIChatBox';
// import ChatGuidance from './components/ChatGuidance';

const navigateToSection = (navigate, path, sectionId) => {
  navigate(path);
  setTimeout(() => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, 100); // Delay to allow page transition
};

const GCloudsPortal = () => {
  const CALENDAR_LINK = process.env.REACT_APP_CALENDAR_LINK;
  const navigate = useNavigate();
  // Services structure
  const services = {
    cloud: [
      {
        icon: <Cloud />,
        title: "Cloud Migration",
        description: "Accelerate your journey to Google Cloud with our expert migration services",
        features: [
          "Infrastructure Assessment",
          "Migration Planning",
          "Deployment & Testing",
          "Post-Migration Support"
        ]
      },
      {
        icon: <Database />,
        title: "Data Analytics",
        description: "Transform your data into actionable insights with GCP's powerful analytics tools",
        features: [
          "BigQuery Implementation",
          "Data Warehouse Migration",
          "Analytics Setup",
          "ML/AI Integration"
        ]
      },
      {
        icon: <Network />,
        title: "Infrastructure Modernisation",
        description: "Optimise your infrastructure with modern cloud-native solutions",
        features: [
          "Cloud Architecture Design",
          "Container Orchestration",
          "Serverless Solutions",
          "Cost Optimisation"
        ]
      }
    ],
    solutions: [
      {
        icon: <Lock />,
        title: "Security & Compliance",
        description: "Comprehensive security solutions for GCP environments",
        features: [
          "Security Assessment",
          "Compliance Implementation",
          "Threat Detection",
          "Identity Management"
        ]
      },
      {
        icon: <Server />,
        title: "DevOps & SRE",
        description: "Streamline your development and operations with modern practices",
        features: [
          "CI/CD Implementation",
          "Infrastructure as Code",
          "Monitoring & Alerting",
          "SRE Best Practices"
        ]
      },
      {
        icon: <Monitor />,
        title: "Managed Services",
        description: "24/7 support and management for your GCP infrastructure",
        features: [
          "Proactive Monitoring",
          "Incident Response",
          "Performance Optimisation",
          "Cost Management"
        ]
      }
    ]
  };

  return (
    <div className="min-h-screen bg-slate-950 text-white">
      <main className="relative">

        
        {/* Mobile Layout */}
        <div className="md:hidden flex flex-col min-h-screen">
          {/* Partner logo Block */}
          <section className="relative flex justify-end items-center">
            <div className="absolute inset-0 bg-gradient-to-br from-blue-500/10 via-purple-500/10 to-slate-950" />

              <img 
                src="/images/google-cloud-partner.png" 
                alt="Google Cloud Partner" 
                className="h-12"
              />
          </section>
          {/* Welcome Block */}
          <section className="bg-slate-800 p-6">
            <div className="flex items-start space-x-4">
              <div className="w-16 h-16 rounded-full bg-blue-500 flex-shrink-0 flex items-center justify-center">
                <Cloud className="w-8 h-8 text-white" />
              </div>
              <div className="space-y-2">
                <h1 className="text-2xl font-bold text-white">Hi, I'm Nia</h1>
                <p className="text-slate-300">Welcome to gClouds! I'm here to help you explore our Google Cloud solutions.</p>
                <p className="text-slate-400 text-sm">Let's chat below about your needs...</p>
              </div>
            </div>
          </section>

          {/* Chat Interface */}
          <section id="chat" className="flex-1 bg-slate-800/80 p-4">
            <AIChatBox />
          </section>

          {/* Discovery Section */}
          <section className="bg-slate-800 p-6">
            <h2 className="text-xl font-bold text-white mb-4">Explore Our Products</h2>
            <div className="space-y-4">
              {/* Google Workspace Card */}
              <div className="bg-slate-900 p-4 rounded-lg">
                <div className="flex justify-between items-start">
                  <div>
                    <h3 className="text-white font-medium">Google Workspace</h3>
                    <p className="text-slate-400">From £5.49/user/month</p>
                    <p className="text-xs text-green-400">Save 7% vs direct Google pricing</p>
                  </div>
                  <button 
                  onClick={() => navigateToSection(navigate, "/products/workspace/business", "business-starter-annual")}
                  className="bg-blue-500 text-white px-4 py-2 rounded-lg text-sm"
                  >
                    Learn More
                  </button>
                </div>
              </div>

              {/* AppSheet Card */}
              <div className="bg-slate-900 p-4 rounded-lg">
                <div className="flex justify-between items-start">
                  <div>
                    <h3 className="text-white font-medium">AppSheet</h3>
                    <p className="text-slate-400">From £7.75/user/month</p>
                    <p className="text-xs text-green-400">Save 7.7% vs direct Google pricing</p>
                  </div>
                  <button 
                  onClick={() => navigateToSection(navigate, "/products/workspace/appsheet", "appsheet-core")}
                  className="bg-blue-500 text-white px-4 py-2 rounded-lg text-sm"
                  >
                    Learn More
                  </button>
                </div>
              </div>

              {/* GCP Card */}
              <div className="bg-slate-900 p-4 rounded-lg">
                <div className="flex justify-between items-start">
                  <div>
                    <h3 className="text-white font-medium">Google Cloud Platform</h3>
                    <p className="text-slate-400">Pay as you go</p>
                  </div>
                  <button
                  onClick={() => navigateToSection(navigate, "/products/gcp/compute-engine", "compute-engine")}
                  className="bg-blue-500 text-white px-4 py-2 rounded-lg text-sm"
                  >
                    Learn More
                  </button>
                </div>
              </div>
            </div>
          </section>

          {/* Quick Actions */}
          <section className="bg-slate-900 p-6 border-t border-slate-800">
            <div className="flex space-x-4">
              <a 
                href={CALENDAR_LINK}
                target="_blank"
                rel="noopener noreferrer"
                className="flex-1 bg-blue-500 text-white px-4 py-3 rounded-lg text-center flex items-center justify-center space-x-2"
              >
                <Calendar className="w-5 h-5" />
                <span>Book Consultation</span>
              </a>
            </div>
          </section>
        </div>

        {/* Desktop Layout */}
        <div className="hidden md:block">
          <div className="relative pt-32 pb-20 px-6">
            <div className="max-w-7xl mx-auto">
              {/* Partner Logo and Header - keep text-center only for these */}
              <div className="text-center mb-16">
                <div className="absolute top-8 right-8 bg-white/10 backdrop-blur-xl rounded-xl p-4 border border-white/20">        
                  <img 
                    src="/images/google-cloud-partner.png" 
                    alt="Google Cloud Partner" 
                    className="h-12"
                  />
                </div>
                <div className="inline-flex items-center space-x-2 bg-blue-500/10 rounded-full px-4 py-2 mb-8">
                  <Shield className="w-5 h-5 text-blue-400" />
                  <span className="text-blue-400">Official Google Cloud Partner in the UK & Ireland</span>
                </div>
                
                <h1 className="text-5xl md:text-7xl font-bold mb-8">
                  Transform Your Business with{' '}
                  <span className="bg-gradient-to-r from-blue-400 to-violet-400 bg-clip-text text-transparent">
                    Google Cloud
                  </span>
                </h1>
                
                <p className="text-xl text-gray-300 mb-12 max-w-3xl mx-auto">
                  UK's Google Cloud consultancy helping businesses achieve more with cloud technology
                </p>
                <div className="mt-8 flex justify-center space-x-4">
                  <a
                    href={CALENDAR_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-slate-700 hover:bg-slate-600 text-white px-8 py-3 rounded-lg transition-colors flex items-center space-x-2"
                  >
                    <Calendar className="w-5 h-5" />
                    <span>Book Consultation</span>
                  </a>
                </div>
              </div>

              {/* Chat Section */}
              <div className="max-w-2xl mx-auto space-y-8">
                <AIChatBox />
              {/* Chat Guidance window 
                <section className="flex-1 bg-slate-800/80 p-4 rounded-xl border border-slate-700/50">
                <ChatGuidance />
                </section>
              */}
              </div>
            </div>
          </div>
        </div>
      
      {/* Main */}
      </main>
      {/* Services Section */}
      <section className="py-24 bg-slate-900/50">
        <div className="max-w-7xl mx-auto px-6">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold mb-4">Google Cloud Solutions</h2>
            <p className="text-gray-400 max-w-2xl mx-auto">
              Comprehensive services to help you succeed with Google Cloud Platform
            </p>
          </div>

          {/* Cloud Services */}
          <div className="mb-16">
            <h3 className="text-2xl font-semibold mb-8 text-blue-400">Cloud Services</h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {services.cloud.map((service, index) => (
                <ServiceCard key={index} {...service} />
              ))}
            </div>
          </div>

          {/* Solutions */}
          <div>
            <h3 className="text-2xl font-semibold mb-8 text-blue-400">Solutions</h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {services.solutions.map((service, index) => (
                <ServiceCard key={index} {...service} />
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Client Logos Section  192x64 pixels*/}
      <section className="py-16 bg-slate-900/30 overflow-hidden">
        <div className="max-w-7xl mx-auto px-6">
          <div className="text-center mb-12">
            <h2 className="text-2xl font-semibold text-white mb-4">Trusted by Leading Organisations</h2>
            <p className="text-gray-400">Companies that have transformed their business with our Google Cloud solutions</p>
          </div>
  
          <div className="relative">
            {/* Gradient Overlays */}
            <div className="absolute left-0 top-0 bottom-0 w-40 bg-gradient-to-r from-slate-900/30 to-transparent z-10"></div>
            <div className="absolute right-0 top-0 bottom-0 w-40 bg-gradient-to-l from-slate-900/30 to-transparent z-10"></div>
            
            {/* Scrolling Container */}
            <div className="flex overflow-hidden">
              {/* First Set of Logos */}
              <div className="flex animate-scroll">
                <div className="flex-shrink-0 w-48 h-16 mx-8 bg-slate-800/50 rounded-lg flex items-center justify-center px-6 hover:bg-slate-800/80 transition-colors">
                <img 
                    src="/images/LBG-logo.png"
                    alt="LBG"
                    className="w-full h-auto object-contain"
                  />
                </div>
                <div className="flex-shrink-0 w-48 h-16 mx-8 bg-slate-800/50 rounded-lg flex items-center justify-center px-6 hover:bg-slate-800/80 transition-colors">
                <img 
                    src="/images/wipro-logo.png"
                    alt="Wipro"
                    className="w-full h-auto object-contain"
                  />
                </div>
                <div className="flex-shrink-0 w-48 h-16 mx-8 bg-slate-800/50 rounded-lg flex items-center justify-center px-6 hover:bg-slate-800/80 transition-colors">
                <img 
                    src="/images/grafana-labs-logo.png"
                    alt="GraphanaLabs"
                    className="w-full h-auto object-contain"
                  />
                </div>
                <div className="flex-shrink-0 w-48 h-16 mx-8 bg-slate-800/50 rounded-lg flex items-center justify-center px-6 hover:bg-slate-800/80 transition-colors">
                <img 
                    src="/images/victoria-beckham-ltd-logo.jpg"
                    alt="VictoriaBeckhamLtd"
                    className="w-full h-auto object-contain"
                  />
                </div>
                <div className="flex-shrink-0 w-48 h-16 mx-8 bg-slate-800/50 rounded-lg flex items-center justify-center px-6 hover:bg-slate-800/80 transition-colors">
                <img 
                    src="/images/selmoni-logo.png"
                    alt="Selmoni"
                    className="w-full h-auto object-contain"
                  />
                </div>
                <div className="flex-shrink-0 w-48 h-16 mx-8 bg-slate-800/50 rounded-lg flex items-center justify-center px-6 hover:bg-slate-800/80 transition-colors">
                <img 
                    src="/images/mancini-legal-logo.png"
                    alt="ManiciniLegal"
                    className="w-full h-auto object-contain"
                  />
                </div>
              </div>
              
              {/* Duplicate Set for Seamless Loop */}
              <div className="flex animate-scroll">
                <div className="flex-shrink-0 w-48 h-16 mx-8 bg-slate-800/50 rounded-lg flex items-center justify-center px-6 hover:bg-slate-800/80 transition-colors">
                <img 
                    src="/images/BrainFinance-logo.svg"
                    alt="BrainFinance"
                    className="w-full h-auto object-contain"
                  />
                </div>
                <div className="flex-shrink-0 w-48 h-16 mx-8 bg-slate-800/50 rounded-lg flex items-center justify-center px-6 hover:bg-slate-800/80 transition-colors">
                <img 
                    src="/images/trust-logo.jpg"
                    alt="Trust"
                    className="w-full h-auto object-contain"
                  />
                </div>
                <div className="flex-shrink-0 w-48 h-16 mx-8 bg-slate-800/50 rounded-lg flex items-center justify-center px-6 hover:bg-slate-800/80 transition-colors">
                <img 
                    src="/images/collibra-logo.svg"
                    alt="Collibra"
                    className="w-full h-auto object-contain"
                  />
                </div>
                <div className="flex-shrink-0 w-48 h-16 mx-8 bg-slate-800/50 rounded-lg flex items-center justify-center px-6 hover:bg-slate-800/80 transition-colors">
                <img 
                    src="/images/crewvie-logo.png"
                    alt="CreweVie"
                    className="w-full h-auto object-contain"
                  />
                </div>
                <div className="flex-shrink-0 w-48 h-16 mx-8 bg-slate-800/50 rounded-lg flex items-center justify-center px-6 hover:bg-slate-800/80 transition-colors">
                <img 
                    src="/images/dxwand-logo.svg"
                    alt="DXWand"
                    className="w-full h-auto object-contain"
                  />
                </div>
                <div className="flex-shrink-0 w-48 h-16 mx-8 bg-slate-800/50 rounded-lg flex items-center justify-center px-6 hover:bg-slate-800/80 transition-colors">
                <img 
                    src="/images/lbmum-logo.png"
                    alt="LBmum"
                    className="w-full h-auto object-contain"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Why Choose Section */}
      <section className="py-24 bg-slate-900">
        <div className="max-w-7xl mx-auto px-6">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold mb-4">Why Choose gClouds</h2>
            <p className="text-gray-400 max-w-2xl mx-auto">
              Expert team of Google Cloud certified professionals ready to help you succeed
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <FeatureCard
              icon={<Shield />}
              title="Official Partner"
              description="UK-based Google Cloud Partner with proven expertise"
            />
            <FeatureCard
              icon={<Brain />}
              title="AI-First Approach"
              description="Cutting-edge AI solutions and support"
            />
            <FeatureCard
              icon={<Globe />}
              title="Full-Service Support"
              description="End-to-end solutions from migration to optimisation"
            />
          </div>
        </div>
      </section>
    </div>
  );
};
// Service Card Component
const ServiceCard = ({ icon, title, description, features }) => (
    <div className="bg-slate-900/50 backdrop-blur-xl rounded-xl p-8 border border-white/10 hover:border-blue-500/50 transition-colors">
      <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center mb-6 text-blue-400">
        {icon}
      </div>
      <h3 className="text-xl font-semibold mb-4">{title}</h3>
      <p className="text-gray-400 mb-6">{description}</p>
      <ul className="space-y-3">
        {features.map((feature, index) => (
          <li key={index} className="flex items-start space-x-2 text-gray-300">
            <Shield className="w-5 h-5 text-blue-400 mt-1" />
            <span>{feature}</span>
          </li>
        ))}
      </ul>
    </div>
  );
  
  // Feature Card Component
  const FeatureCard = ({ icon, title, description }) => (
    <div className="bg-slate-900/50 backdrop-blur-xl rounded-xl p-8 border border-white/10">
      <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center mb-6 text-blue-400">
        {icon}
      </div>
      <h3 className="text-xl font-semibold mb-4">{title}</h3>
      <p className="text-gray-400">{description}</p>
    </div>
  );
export default GCloudsPortal;