// src/Auth.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, db } from './firebaseConfig';
import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  onAuthStateChanged,
  sendPasswordResetEmail,
  signOut 
} from 'firebase/auth';
import { doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';

// Create Auth Context
const AuthContext = createContext();

// Custom hook to use auth context
export const useAuth = () => {
  return useContext(AuthContext);
};

// Auth provider component
export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [userRoles, setUserRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Sign up function
  const signUpUser = async (email, password) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      await setDoc(doc(db, "users", userCredential.user.uid), {
        email: email,
        roles: ["user"],
        createdAt: serverTimestamp()
      });
      return userCredential;
    } catch (err) {
      console.error("Error in signup:", err);
      setError(err.message);
      throw err;
    }
  };

  // Sign in function
  const signInUser = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const userRef = doc(db, 'users', userCredential.user.uid);
      const userSnap = await getDoc(userRef);
      
      if (userSnap.exists() && userSnap.data().status === 'pending') {
        await signOut(auth);
        throw new Error('Your account is pending approval. Please check your email for updates.');
      }
      return userCredential;
    } catch (err) {
      console.error("Error in signin:", err);
      setError(err.message);
      throw err;
    }
  };

  // Reset password function
  const resetUserPassword = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
      return { success: true };
    } catch (err) {
      console.error("Error in password reset:", err);
      setError(err.message);
      throw err;
    }
  };

  // Sign out function
  const signOutUser = async () => {
    try {
      await signOut(auth);
    } catch (err) {
      console.error("Error in signout:", err);
      setError(err.message);
      throw err;
    }
  };

  // Listen for auth state changes
  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 10000); // 10-second fallback
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      try {
        if (user) {
          setCurrentUser(user);
          const userRef = doc(db, 'users', user.uid);
          const userSnap = await getDoc(userRef);
          
          if (userSnap.exists()) {
            setUserRoles(userSnap.data().roles || []);
          } else {
            await setDoc(userRef, {
              email: user.email,
              roles: ['user'],
              createdAt: serverTimestamp()
            });
            setUserRoles(['user']);
          }
        } else {
          setCurrentUser(null);
          setUserRoles([]);
        }
      } catch (err) {
        console.error('Error fetching user roles:', err);
        setUserRoles(['user']); // Default role on error
      } finally {
        setLoading(false);
      }
      clearTimeout(timer);
    });

    return () => {
      unsubscribe();
      clearTimeout(timer);
    };
  }, []);

  const value = {
    currentUser,
    userRoles,
    loading,
    error,
    signIn: signInUser,
    signOut: signOutUser,
    signUp: signUpUser,
    resetPassword: resetUserPassword
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

// Login/Signup form component (unchanged except for import)
export function AuthForm({ onSuccess }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSignUp, setIsSignUp] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  
  const { signIn, signUp } = useAuth();

  const handleAuth = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);
    
    try {
      if (isSignUp) {
        await signUp(email, password);
      } else {
        await signIn(email, password);
      }
      if (onSuccess) onSuccess();
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="auth-container">
      <form onSubmit={handleAuth} className="auth-form">
        <h2>{isSignUp ? 'Sign Up' : 'Log In'}</h2>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          required
        />
        <button type="submit" disabled={isLoading}>
          {isLoading 
            ? (isSignUp ? 'Creating Account...' : 'Signing In...') 
            : (isSignUp ? 'Sign Up' : 'Log In')}
        </button>
        {error && <p className="error">{error}</p>}
      </form>
      <button onClick={() => setIsSignUp(!isSignUp)} className="auth-toggle">
        {isSignUp ? 'Already have an account? Log In' : "Don't have an account? Sign Up"}
      </button>
    </div>
  );
}