import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import ComparisonTable from './components/products/workspace/ComparisonTable';
import BusinessPlans from './components/products/workspace/Business';
import EnterprisePlans from './components/products/workspace/Enterprise';
import Navigation from './components/Navigation';
import AppsheetProduct from './components/products/workspace/Appsheet_product';
import Storage from './components/products/gcp/Storage';
import ComputeEngine from './components/products/gcp/ComputeEngine.js';
import AppsheetSolution from './components/solutions/application/Appsheet_solution';
import WhygClouds from './components/overview/company/WhygClouds';
import Expertise from './components/overview/company/Expertise';
import Support from './components/overview/company/Support';
import Contact from './components/overview/company/Contact';
import LandingZone from './components/overview/usecases/LandingZone';
import Enterprise from './components/overview/success-stories/Enterprise';
import SMB from './components/overview/success-stories/SMB';
import Migration from './components/overview/success-stories/Migration';
import Partnerships from './components/overview/partnerships/Partnerships';
import AiChat from './components/solutions/ai/AiChat';
import GenAIImplementation from './components/solutions/ai/GenAI'; 
import AppDevelopment from './components/solutions/application/AppDevelopment';
import SmartContracts from './components/solutions/blockchain/SmartContracts';
import AppMigration from './components/solutions/infrastructure/Migration';
import Oracle from './components/solutions/infrastructure/Oracle';
import VirtualDesktops from './components/solutions/infrastructure/VirtualDesktops';
import Implementation from './components/services/Implementation';
import GenAIAutomate from './components/services/GenAIAutomate';
import PoCDevelopment from './components/services/PoCDevelopment';
import Consulting from './components/services/Consulting';
import AIConsulting from './components/services/AIConsulting';
import SecurityServices from './components/services/SecurityServices';
import Footer from './components/Footer';
import GCloudsPortal from './web.js';
import './App.css';
import AILanding from './components/ai/AILanding.js';
import SQLServer from './components/products/marketplace/SQLServer.js';
import EthereumDevSuite from './components/products/marketplace/Ethereum.js';
import PrivacyPolicy from './components/Privacy.js';
import CookieNotice from './components/CookieNotice.js';
import NiaProductPage from './components/ai/NiaProductPage.js';
//import StrategyDashboard from './components/dashboard/StrategyDashboard';
import { AuthProvider } from './Auth';
import Login from './pages/Login';
import Home from './pages/Home';
import Signup from './pages/Signup';
import UserView from './pages/UserView';
import UsersL from './pages/Users';
import NotFound from './pages/NotFound';
//import Profile from './pages/Profile';
import ChangePassword from './pages/ChangePassword';
import AdminPanel from './pages/AdminPanel.js';
import ProtectedRoute from './components/dashboard/ProtectedRoute';
import ErrorBoundary from './pages/ErrorBoundary';
import CallDemo from './pages/CallDemo';
import VoiceCallTest from './pages/VoiceCallTest';
import NiaLiveCall from './components/NiaCall/NiaLiveCall';


const StrategyDashboard = React.lazy(() => import('./components/dashboard/StrategyDashboard'));
const Profile = React.lazy(() => import('./pages/Profile'));

function AppContent() {
  const location = useLocation();
  const showFooter = !location.pathname.startsWith('/admin') && location.pathname !== '/dashboard';

  return (
    <>
      {/* Navigation Section */}
      <header className="sticky top-0 z-50 w-full bg-slate-900/95 backdrop-blur-sm border-b border-slate-800">
        <Navigation />
      </header>

      {/* Main Content Section */}
      <main className="flex-1 w-full relative">
        {/* Background Gradient */}
        <div className="absolute inset-0 bg-gradient-to-br from-blue-500/10 via-purple-500/10 to-slate-950 pointer-events-none" />
        
        {/* Content Container */}
        <div className="relative">
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
            <Route path="/why-gclouds" element={<WhygClouds />} />
              <Route path="/expertise" element={<Expertise />} />
              <Route path="/support" element={<Support />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/solutions/landing-zone" element={<LandingZone />} />
              <Route path="/products/workspace/compare" element={<ComparisonTable />} />
              <Route path="/products/workspace/business" element={<BusinessPlans />} />
              <Route path="/products/workspace/enterprise" element={<EnterprisePlans />} />
              <Route path="/products/workspace/appsheet" element={<AppsheetProduct />} />
              <Route path="/products/gcp/storage" element={<Storage />} />
              <Route path="/products/gcp/compute-engine" element={<ComputeEngine />} />
              <Route path="/products/marketplace/sql-server" element={<SQLServer />} />
              <Route path="/products/marketplace/ethereum" element={<EthereumDevSuite />} />
              <Route path="/solutions/application/appsheet" element={<AppsheetSolution />} />
              <Route path="/solutions/app-development" element={<AppDevelopment />} />
              <Route path="/success-stories/enterprise" element={<Enterprise />} />
              <Route path="/success-stories/smb" element={<SMB />} />
              <Route path="/success-stories/migration" element={<Migration />} />
              <Route path="/partnerships" element={<Partnerships />} />
              <Route path="/solutions/ai-chat" element={<AiChat />} />
              <Route path="/solutions/gen-ai" element={<GenAIImplementation />} />
              <Route path="/solutions/blockchain/smart-contracts" element={<SmartContracts />} />
              <Route path="/solutions/infrastructure/migration" element={<AppMigration />} />
              <Route path="/solutions/infrastructure/oracle" element={<Oracle />} />
              <Route path="/solutions/infrastructure/virtual-desktops" element={<VirtualDesktops />} />
              <Route path="/services/implementation" element={<Implementation />} />
              <Route path="/services/gen-ai-automate" element={<GenAIAutomate />} />
              <Route path="/services/poc" element={<PoCDevelopment />} />
              <Route path="/services/consulting" element={<Consulting />} />
              <Route path="/services/ai-consulting" element={<AIConsulting />} />
              <Route path="/services/security" element={<SecurityServices />} />
              <Route path="/ai" element={<AILanding />} />
              <Route path="/nia" element={<NiaProductPage />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/home" element={<Home />} />
              <Route path="/dashboard" element={<ProtectedRoute requiredRoles={['admin', 'strategy', 'sales']}><StrategyDashboard /></ProtectedRoute>} />
              <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
              <Route path="/admin" element={<ProtectedRoute requiredRoles={['admin']}><AdminPanel /></ProtectedRoute>} />
              <Route path="/admin/users" element={<ProtectedRoute requiredRoles={['admin']}><UsersL /></ProtectedRoute>} />
              <Route path="/admin/users/:userId" element={<ProtectedRoute requiredRoles={['admin']}><UserView /></ProtectedRoute>} />
              <Route path="/change-password" element={<ProtectedRoute><ChangePassword /></ProtectedRoute>} />
              <Route path="/" element={<GCloudsPortal />} />
              <Route path="/call-demo" element={<CallDemo />} />
              <Route path="/voice-test" element={<VoiceCallTest />} />
              <Route path="/live-call" element={<NiaLiveCall />} />
              <Route path="*" element={<NotFound/>} />
            </Routes>
          </Suspense>
        </div>
      </main>

      {/* Conditionally render Footer */}
      {showFooter && <Footer />}
      <CookieNotice />
    </>
  );
}

// Main App component 
function App() {
  return (
    <ErrorBoundary>
      <div className="flex flex-col min-h-screen bg-slate-950">
        <AuthProvider>
          <BrowserRouter>
            <AppContent />
          </BrowserRouter>
        </AuthProvider>
      </div>
    </ErrorBoundary>
  );
}

export default App;