// /products/marketplace/sql-server
import React, { useState } from 'react';
import { 
  Shield,
  Settings,
  X,
  CheckCircle,
  CreditCard,
  Zap,
  ArrowRight,
  Lock,
  LineChart
} from 'lucide-react';
import AIChatBox from '../../AIChatBox';

const ChatModal = ({ show, onClose, initialMessage }) => {
  if (!show) return null;
  return (
    <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
      <div className="bg-slate-900 rounded-xl max-w-4xl w-full">
        <div className="flex justify-between items-center p-6 border-b border-slate-800">
          <h2 className="text-2xl font-semibold text-white">Chat with Nia</h2>
          <button onClick={onClose} className="text-slate-400 hover:text-white">
            <X className="w-6 h-6" />
          </button>
        </div>
        <div className="p-6">
          <AIChatBox initialMessageModal={initialMessage} />
        </div>
      </div>
    </div>
  );
};

const SQLServer = () => {
  const [showChatModal, setShowChatModal] = useState(false);
  const [chatMessage, setChatMessage] = useState('');

  const handleChatWithNia = () => {
    const message = `Hi Nia, I'm interested in running MS SQL Server ( Developer or Express edition) on Google Cloud. Could you help me understand the different editions available and their benefits?`;
    setShowChatModal(true);
    setChatMessage(message);
  };

  const editions = [
    {
      title: "SQL Server 2022 Developer Edition",
      features: [
        "Full-featured version for development and testing",
        "All Enterprise Edition features included",
        "No production deployment rights",
        "Perfect for development and testing environments"
      ],
      highlighted: true
    },
    {
      title: "SQL Server 2022 Express Edition",
      features: [
        "Free edition for development and small deployments",
        "Database size limit of 10 GB per database",
        "Uses limited server resources",
        "Ideal for learning and small applications"
      ]
    },
    {
      title: "SQL Server 2019 Express Edition",
      features: [
        "Previous version free edition",
        "Database size limit of 10 GB per database",
        "Uses limited server resources",
        "Good for legacy application support"
      ]
    }
  ];

  const benefits = [
    {
      icon: CreditCard,
      title: "Cost Optimisation",
      description: "Save on licensing costs with our pre-configured images",
      points: [
        "Pay-as-you-go pricing",
        "No upfront license costs",
        "Flexible deployment options",
        "Cost-effective development environments"
      ]
    },
    {
      icon: Shield,
      title: "Security & Compliance",
      description: "Enterprise-grade security with Google Cloud infrastructure",
      points: [
        "Automated security updates",
        "Built-in encryption",
        "Identity and access management",
        "Compliance certifications"
      ]
    },
    {
      icon: Zap,
      title: "Easy Deployment",
      description: "Quick and reliable deployment process",
      points: [
        "Pre-configured images",
        "One-click deployment",
        "Automated setup",
        "Instant provisioning"
      ]
    }
  ];

  const features = [
    {
      icon: Settings,
      title: "Full SQL Server Feature Set",
      description: "Access to complete SQL Server capabilities for development"
    },
    {
      icon: Lock,
      title: "License Compliance",
      description: "Fully compliant with Microsoft licensing terms"
    },
    {
      icon: LineChart,
      title: "Performance Monitoring",
      description: "Built-in monitoring and optimisation tools"
    }
  ];

  return (
    <div className="min-h-screen bg-slate-950">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Hero Section */}
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="lg:col-span-7">
            <h1 className="text-4xl font-bold text-white mb-6">
              SQL Server on Google Cloud Marketplace
            </h1>
            <p className="text-xl text-slate-400 mb-8 max-w-3xl">
              Deploy SQL Server instantly with our pre-configured images. Perfect for development, testing, and production environments.
            </p>
            <div className="space-x-4">
              <button
                onClick={handleChatWithNia}
                className="bg-blue-500 hover:bg-blue-600 text-white px-8 py-3 rounded-lg transition-colors"
              >
                Chat with Nia
              </button>
            </div>
          </div>

          {/* Product Highlights Sidebar */}
          <div className="lg:col-span-5 mt-8 lg:mt-0">
            <div className="bg-slate-900/50 backdrop-blur-xl rounded-xl border border-slate-800 p-6">
              <h3 className="text-lg font-semibold text-white mb-4">Key Benefits</h3>
              <ul className="space-y-4">
                <li className="flex items-start space-x-3">
                  <CheckCircle className="w-5 h-5 text-blue-400 mt-1" />
                  <span className="text-slate-300">
                    Latest SQL Server and OS Updates
                  </span>
                </li>
                <li className="flex items-start space-x-3">
                  <CheckCircle className="w-5 h-5 text-blue-400 mt-1" />
                  <span className="text-slate-300">
                    Pre-configured SQL Server and Google Cloud instances
                  </span>
                </li>
                <li className="flex items-start space-x-3">
                  <CheckCircle className="w-5 h-5 text-blue-400 mt-1" />
                  <span className="text-slate-300">
                    Pay only for compute resources
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Editions Section */}
        <div className="mt-24">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            Available Editions
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {editions.map((edition, index) => (
              <div
                key={index}
                className={`bg-slate-900/50 backdrop-blur-xl p-8 rounded-xl border ${
                  edition.highlighted ? 'border-blue-500' : 'border-slate-800'
                }`}
              >
                {edition.highlighted && (
                  <div className="bg-blue-500 text-white px-4 py-1 rounded-full text-sm w-fit mb-4">
                    Popular Choice
                  </div>
                )}
                <h3 className="text-xl font-semibold text-white mb-4">{edition.title}</h3>
                <ul className="space-y-3">
                  {edition.features.map((feature, idx) => (
                    <li key={idx} className="flex items-start space-x-2">
                      <CheckCircle className="w-4 h-4 text-blue-400 mt-1" />
                      <span className="text-slate-300">{feature}</span>
                    </li>
                  ))}
                </ul>
                <a
                  href={
                    edition.title === "SQL Server 2022 Developer Edition"
                      ? "https://console.cloud.google.com/marketplace/product/gclouds-public/sql-server-2022-developer-on-windows-server-2022-datacenter"
                      : edition.title === "SQL Server 2022 Express Edition"
                      ? "https://console.cloud.google.com/marketplace/product/gclouds-public/sql-server-2022-express-on-windows-server-2022-datacenter"
                      : "https://console.cloud.google.com/marketplace/product/gclouds-public/sql-server-2019-express-on-windows-server-2019-datacenter"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-full mt-6 bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg transition-colors flex items-center justify-center space-x-2"
                >
                  <span>Deploy Now</span>
                  <ArrowRight className="w-4 h-4" />
                </a>
              </div>
            ))}
          </div>
        </div>

        {/* Benefits Section */}
        <div className="mt-24">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            Benefits
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {benefits.map((benefit, index) => (
              <div
                key={index}
                className="bg-slate-900/50 backdrop-blur-xl p-8 rounded-xl border border-slate-800"
              >
                <div className="flex items-start space-x-4">
                  <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center flex-shrink-0">
                    <benefit.icon className="w-6 h-6 text-blue-400" />
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold text-white mb-2">{benefit.title}</h3>
                    <p className="text-slate-400 mb-4">{benefit.description}</p>
                    <ul className="space-y-2">
                      {benefit.points.map((point, idx) => (
                        <li key={idx} className="flex items-start space-x-2 text-slate-300">
                          <CheckCircle className="w-4 h-4 text-blue-400 mt-1" />
                          <span>{point}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Features Grid */}
        <div className="mt-24">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            Features
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <div
                key={index}
                className="bg-slate-900/50 backdrop-blur-xl p-6 rounded-xl border border-slate-800"
              >
                <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center mb-4">
                  <feature.icon className="w-6 h-6 text-blue-400" />
                </div>
                <h3 className="text-lg font-semibold text-white mb-2">{feature.title}</h3>
                <p className="text-slate-400">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Bottom CTA */}
        <div className="mt-24 text-center bg-blue-500/10 rounded-xl p-8 border border-blue-500/50">
          <h2 className="text-2xl font-semibold text-white mb-4">
            Ready to Get Started with SQL Server?
          </h2>
          <p className="text-slate-300 mb-6 max-w-2xl mx-auto">
            Chat with Nia to learn more about our SQL Server offerings and find the perfect edition for your needs.
          </p>
          <button
            onClick={handleChatWithNia}
            className="bg-blue-500 hover:bg-blue-600 text-white px-8 py-3 rounded-lg transition-colors"
          >
            Chat with Nia
          </button>
        </div>
      </div>

      <ChatModal 
        show={showChatModal}
        onClose={() => setShowChatModal(false)}
        initialMessage={chatMessage}
      />
    </div>
  );
};

export default SQLServer;