// MobileNav.js
import React, { useState } from 'react';
import { X, ChevronRight, ChevronLeft } from 'lucide-react';
import { menuData } from './menuData';

const SubMenu = ({ title, items, onBack }) => (
  <div className="fixed inset-0 bg-slate-900 transition-opacity duration-300 ease-in-out">
    <button
      onClick={onBack}
      className="flex items-center p-4 text-white hover:bg-slate-800"
    >
      <ChevronLeft className="w-5 h-5 mr-2" />
      Back
    </button>

    <div className="px-4 py-2 space-y-2 bg-slate-900 max-h-[calc(100vh_-_96px)] sm:max-h-[calc(100vh_-_128px)] overflow-y-auto"> {/* Increased sm:max-h to 128px */}
      <div className="text-sm uppercase text-gray-400 mb-2">{title}</div>
      {items.map((item, index) => (
        <a
          key={index}
          href={item.link}
          className="block p-3 rounded-lg hover:bg-slate-800 text-white bg-slate-900"
        >
          <div className="font-medium">{item.title}</div>
          {item.description && (
            <div className="text-sm text-gray-300">{item.description}</div>
          )}
        </a>
      ))}
    </div>
  </div>
);

const MobileNav = ({ isOpen, onClose }) => {
  const [activeSection, setActiveSection] = useState(null);

  const mainMenuItems = [
    { title: 'Overview', data: menuData.overview },
    { title: 'Solutions', data: menuData.solutions },
    { title: 'Products', data: menuData.products },
    { title: 'Services', data: menuData.services },
    { title: 'Contact', link: '/contact' },
    { title: 'Support', link: '/support' },
    { title: 'AppSheet Apps', link: '/solutions/application/appsheet' },
    { title: 'AI', link: '/ai' },
  ];

  if (!isOpen) return null;

  if (activeSection) {
    return (
      <SubMenu
        title={activeSection.title}
        items={activeSection.data.sections.flatMap(section => section.items)}
        onBack={() => setActiveSection(null)}
      />
    );
  }

  return (
    <div className="fixed inset-0 bg-slate-900 z-50">
      <div className="flex justify-between items-center p-4 border-b border-slate-800">
        <img src="/images/gClouds-logo.png" alt="gClouds" className="h-8" />
        <button onClick={onClose} className="text-white">
          <X className="w-6 h-6" />
        </button>
      </div>

      <div className="px-4 py-2 space-y-2 bg-slate-900 max-h-[calc(100vh_-_96px)] sm:max-h-[calc(100vh_-_128px)] overflow-y-auto"> {/* Increased sm:max-h to 128px */}
        {mainMenuItems.map((item) => (
          <div key={item.title} className="mb-2">
            {item.data ? (
              <button
                onClick={() => setActiveSection(item)}
                className="w-full flex justify-between items-center p-3 rounded-lg hover:bg-slate-800 text-white bg-slate-900"
              >
                {item.title}
                <ChevronRight className="w-5 h-5" />
              </button>
            ) : (
              <a
                href={item.link}
                className="block w-full p-3 rounded-lg hover:bg-slate-800 text-white bg-slate-900"
              >
                {item.title}
              </a>
            )}
          </div>
        ))}
      </div>

      <div className="absolute bottom-0 w-full p-4 border-t border-slate-800">
        <a
          href="/contact"
          className="block w-full py-3 px-4 bg-blue-500 text-white text-center rounded-lg py-3"
        >
          Contact Us
        </a>
      </div>
    </div>
  );
};

export default MobileNav;