import React, { useState } from 'react';
import { 
  Smartphone,
  Database,
  Briefcase,
  Truck,
  Users,
  ClipboardList,
  BarChart3,
  ShieldCheck,
  Box,
  ArrowRight,
  X
} from 'lucide-react';
import AIChatBox from '../../AIChatBox';

const ChatModal = ({ show, onClose, initialMessage }) => {
  if (!show) return null;
  return (
    <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
      <div className="bg-slate-900 rounded-xl max-w-4xl w-full">
        <div className="flex justify-between items-center p-6 border-b border-slate-800">
          <h2 className="text-2xl font-semibold text-white">Chat with Nia</h2>
          <button onClick={onClose} className="text-slate-400 hover:text-white">
            <X className="w-6 h-6" />
          </button>
        </div>
        <div className="p-6">
          <AIChatBox initialMessageModal={initialMessage} />
        </div>
      </div>
    </div>
  );
};

const AppsheetSolution = () => {
  const [showChatModal, setShowChatModal] = useState(false);
  const [chatMessage, setChatMessage] = useState('');

  const handleChatWithNia = (templateType) => {
    const message = `Hi Nia, I'm interested in creating a ${templateType} application using AppSheet. Could you help me understand the process and pricing?`;
    setShowChatModal(true);
    setChatMessage(message);
  };

  const templates = [
    {
      category: "Field Service",
      icon: Truck,
      templates: [
        {
          title: "Work Order Management",
          description: "Track jobs, assign tasks, and manage field service operations",
          features: [
            "Real-time job status updates",
            "Mobile-friendly interface",
            "Photo capture and annotations",
            "Customer signature collection"
          ]
        },
        {
          title: "Equipment Inspection",
          description: "Streamline equipment checks and maintenance records",
          features: [
            "Customisable inspection forms",
            "Offline capability",
            "Photo documentation",
            "Maintenance scheduling"
          ]
        }
      ]
    },
    {
      category: "Sales & CRM",
      icon: Briefcase,
      templates: [
        {
          title: "Sales Pipeline",
          description: "Manage leads and track sales opportunities",
          features: [
            "Lead tracking",
            "Deal pipeline visualisation",
            "Activity logging",
            "Performance dashboards"
          ]
        },
        {
          title: "Customer Management",
          description: "Organize customer data and interactions",
          features: [
            "Contact management",
            "Interaction history",
            "Task assignments",
            "Email integration"
          ]
        }
      ]
    },
    {
      category: "HR & Employee",
      icon: Users,
      templates: [
        {
          title: "Employee Directory",
          description: "Centralize employee information and management",
          features: [
            "Profile management",
            "Department organisation",
            "Skills tracking",
            "Contact information"
          ]
        },
        {
          title: "Time Tracking",
          description: "Monitor work hours and attendance",
          features: [
            "Time clock functionality",
            "Leave management",
            "Overtime tracking",
            "Approval workflows"
          ]
        }
      ]
    },
    {
      category: "Inventory",
      icon: Box,
      templates: [
        {
          title: "Stock Management",
          description: "Track inventory levels and movements",
          features: [
            "Real-time stock levels",
            "Barcode scanning",
            "Low stock alerts",
            "Order management"
          ]
        },
        {
          title: "Asset Tracking",
          description: "Monitor equipment and asset allocation",
          features: [
            "Asset assignment",
            "Maintenance records",
            "Location tracking",
            "Depreciation tracking"
          ]
        }
      ]
    },
    {
      category: "Project Management",
      icon: ClipboardList,
      templates: [
        {
          title: "Project Tracker",
          description: "Manage projects and track progress",
          features: [
            "Task management",
            "Timeline tracking",
            "Resource allocation",
            "Progress reporting"
          ]
        },
        {
          title: "Team Collaboration",
          description: "Coordinate team activities and communications",
          features: [
            "Task assignment",
            "Document sharing",
            "Team messaging",
            "Meeting management"
          ]
        }
      ]
    },
    {
      category: "Finance & Reports",
      icon: BarChart3,
      templates: [
        {
          title: "Expense Management",
          description: "Track and approve business expenses",
          features: [
            "Receipt capture",
            "Expense categorisation",
            "Approval workflows",
            "Budget tracking"
          ]
        },
        {
          title: "Financial Reports",
          description: "Generate and track financial metrics",
          features: [
            "Custom reports",
            "Data visualisation",
            "Excel integration",
            "Automated calculations"
          ]
        }
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-slate-950">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
            Ready-to-Use AppSheet Solutions
          </h1>
          <p className="text-xl text-slate-400 max-w-3xl mx-auto">
            Jumpstart your digital transformation with our pre-built templates
          </p>
        </div>

        {/* Why AppSheet Section */}
        <div className="grid md:grid-cols-3 gap-8 mb-16">
          <div className="bg-slate-800/50 backdrop-blur-xl p-6 rounded-xl border border-slate-700">
            <Smartphone className="w-8 h-8 text-blue-400 mb-4" />
            <h3 className="text-lg font-semibold text-white mb-2">Mobile-First Design</h3>
            <p className="text-slate-400">Native mobile apps that work offline and sync automatically</p>
          </div>
          <div className="bg-slate-800/50 backdrop-blur-xl p-6 rounded-xl border border-slate-700">
            <Database className="w-8 h-8 text-blue-400 mb-4" />
            <h3 className="text-lg font-semibold text-white mb-2">Smart Data Integration</h3>
            <p className="text-slate-400">Connect to your existing data sources and systems seamlessly</p>
          </div>
          <div className="bg-slate-800/50 backdrop-blur-xl p-6 rounded-xl border border-slate-700">
            <ShieldCheck className="w-8 h-8 text-blue-400 mb-4" />
            <h3 className="text-lg font-semibold text-white mb-2">Enterprise Security</h3>
            <p className="text-slate-400">Built-in security controls and compliance features</p>
          </div>
        </div>

        {/* Templates Section */}
        {templates.map((category, index) => (
          <div key={index} className="mb-16">
            <div className="flex items-center space-x-3 mb-8">
              <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center">
                <category.icon className="w-6 h-6 text-blue-400" />
              </div>
              <h2 className="text-2xl font-bold text-white">{category.category} Solutions</h2>
            </div>

            <div className="grid md:grid-cols-2 gap-8">
              {category.templates.map((template, tIndex) => (
                <div 
                  key={tIndex}
                  className="bg-slate-800/50 backdrop-blur-xl rounded-xl p-6 border border-slate-700 hover:border-blue-500/50 transition-all"
                >
                  <h3 className="text-xl font-semibold text-white mb-3">{template.title}</h3>
                  <p className="text-slate-400 mb-4">{template.description}</p>
                  
                  <ul className="space-y-2 mb-6">
                    {template.features.map((feature, fIndex) => (
                      <li key={fIndex} className="flex items-start space-x-2 text-slate-300">
                        <ArrowRight className="w-4 h-4 text-blue-400 mt-1 flex-shrink-0" />
                        <span>{feature}</span>
                      </li>
                    ))}
                  </ul>

                  <button
                    onClick={() => handleChatWithNia(template.title)}
                    className="text-blue-400 hover:text-blue-300 flex items-center space-x-2 transition-colors"
                  >
                    <span>Learn More</span>
                    <ArrowRight className="w-4 h-4" />
                  </button>
                </div>
              ))}
            </div>
          </div>
        ))}

        {/* Bottom CTA */}
        <div className="text-center bg-blue-500/10 rounded-xl p-8 border-2 border-blue-500">
          <h2 className="text-2xl font-semibold text-white mb-4">
            Need a Custom Solution?
          </h2>
          <p className="text-slate-300 mb-6 max-w-2xl mx-auto">
            Don't see exactly what you need? Our team can help you customise any template 
            or build a completely new solution tailored to your requirements.
          </p>
          <button
            onClick={() => handleChatWithNia('Custom Solution')}
            className="bg-blue-500 hover:bg-blue-600 text-white px-8 py-3 rounded-lg transition-colors"
          >
            Chat with Nia
          </button>
        </div>
      </div>
      <ChatModal 
        show={showChatModal}
        onClose={() => setShowChatModal(false)}
        initialMessage={chatMessage}
        />
    </div>
  );
};

export default AppsheetSolution;