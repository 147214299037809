import React from 'react';
import { MessageSquare, CreditCard, Calendar, ShieldCheck, Info, History, FileText } from 'lucide-react';

const ChatGuidance = ({ enhanced = false }) => {
  // Basic features for the compact view
  const basicFeatures = [
    {
      icon: <MessageSquare className="w-5 h-5" />,
      title: "Workspace Recommendations",
      description: "I'll help find your ideal Google Workspace plan"
    },
    {
      icon: <Calendar className="w-5 h-5" />,
      title: "Book a Chat",
      description: "Let me schedule your free consultation with our team"
    },
    {
      icon: <FileText className="w-5 h-5" />,
      title: "Custom Quotes",
      description: "I can create and email quotes with payment links"
    },
    {
      icon: <ShieldCheck className="w-5 h-5" />,
      title: "Technical Help",
      description: "I can answer questions and connect you with experts"
    }
  ];

  // Detailed features for the enhanced view (when shown in its own tab)
  const enhancedFeatures = [
    {
      icon: <MessageSquare className="w-5 h-5" />,
      title: "I'll find your perfect Workspace solution",
      description: "Tell me about your team, and I'll recommend the best Workspace license for you. I can compare Business Starter, Standard, Plus, and Enterprise plans with special partner pricing (up to 7% off direct Google pricing)."
    },
    {
      icon: <Calendar className="w-5 h-5" />,
      title: "Let's schedule a chat with our experts",
      description: "Need more in-depth advice? I can book you a free 30-minute consultation with our Google Cloud specialists. Just let me know what time works for you!"
    },
    {
      icon: <CreditCard className="w-5 h-5" />,
      title: "I'll help you purchase with ease",
      description: "Ready to buy? I can create a custom quote, email it to you, and send purchase invitations with personalised payment links. You can choose between flexible monthly or annual billing options."
    },
    {
      icon: <ShieldCheck className="w-5 h-5" />,
      title: "I'll connect you with implementation support",
      description: "Have questions about setting things up? I can answer basic questions and connect you with our team of certified experts who'll support you throughout your cloud journey."
    },
    {
      icon: <History className="w-5 h-5" />,
      title: "Our conversation is saved for you",
      description: "Feel free to take your time deciding. I'll remember our conversation throughout your browsing session, so you can come back when you're ready to proceed."
    }
  ];

  // Choose which feature set to use based on enhanced prop
  const features = enhanced ? enhancedFeatures : basicFeatures;

  if (enhanced) {
    // Enhanced layout for the dedicated tab
    return (
      <div className="space-y-6">
        {/* Prominent Nia Avatar and Introduction */}
        <div className="flex flex-col items-center mb-6">
          <div className="w-24 h-24 rounded-full bg-blue-500/10 p-1 border border-blue-500/20 mb-4 overflow-hidden">
            <img 
              src="/images/Nia5x320x320.png" 
              alt="Nia - AI Sales Assistant"
              className="w-full h-full object-cover rounded-full"
              onError={(e) => {
                e.target.onerror = null; 
                e.target.parentNode.innerHTML = '<div class="flex items-center justify-center w-full h-full text-blue-400 font-bold text-xl">Nia</div>';
              }}
            />
          </div>
          <h3 className="text-xl font-semibold text-white mb-2">Hi there! I'm Nia</h3>
          <p className="text-sm text-slate-300 text-center max-w-md">
            I'm your Google Cloud assistant. I can help with recommendations, answer questions, and connect you with our team of experts.
          </p>
        </div>
        
        {/* Quick Tip near the top for more visibility */}
        <div className="bg-blue-500/10 border border-blue-500/20 rounded-lg p-4">
          <div className="flex items-start space-x-3">
            <Info className="w-5 h-5 text-blue-400 flex-shrink-0 mt-0.5" />
            <div>
              <h4 className="text-sm font-medium text-white mb-1">Quick Tip from Nia</h4>
              <p className="text-sm text-slate-300">
                Not sure where to start? Just tell me what you're looking to achieve, and I'll help find the right solution! You can ask me about specific Google Cloud products or just describe your business challenges.
              </p>
            </div>
          </div>
        </div>
        
        <div className="grid gap-4">
          {features.map((feature, index) => (
            <div 
              key={index} 
              className="flex items-start space-x-3 p-4 bg-slate-800/70 rounded-lg"
            >
              <div className="mt-0.5 p-2 bg-blue-500/10 rounded-lg text-blue-400 flex-shrink-0">
                {feature.icon}
              </div>
              <div className="min-w-0 flex-1">
                <h4 className="text-md font-medium text-white mb-1">{feature.title}</h4>
                <p className="text-sm text-slate-300">{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  // Original compact layout for embedding
  return (
    <div className="w-full bg-slate-800/50 backdrop-blur-sm rounded-xl border border-slate-700/50 p-4 md:p-6 mb-8">
      <div className="text-center mb-4 md:mb-6">
        <h3 className="text-xl md:text-2xl font-semibold text-white mb-2">I'm Nia, Your Google Cloud Assistant</h3>
        <p className="text-sm md:text-base text-slate-300">Here's how I can help you today:</p>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-4">
        {features.map((feature, index) => (
          <div 
            key={index} 
            className="flex items-start space-x-3 p-3 md:p-4 bg-slate-700/30 rounded-lg hover:bg-slate-700/50 transition-colors"
          >
            <div className="mt-0.5 p-1.5 bg-blue-500/10 rounded-lg text-blue-400 flex-shrink-0">
              {feature.icon}
            </div>
            <div className="min-w-0 flex-1">
              <h4 className="text-sm md:text-base font-medium text-white mb-0.5">{feature.title}</h4>
              <p className="text-xs md:text-sm text-slate-300 break-words">{feature.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChatGuidance;