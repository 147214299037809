import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const MarkdownMessage = ({ content, isUser }) => {
  return (
      <ReactMarkdown 
        remarkPlugins={[remarkGfm]}
        components={{
          p: ({node, ...props}) => (
            <p className="mb-2 last:mb-0" {...props} />
          ),
          ul: ({node, ...props}) => (
            <ul className="list-disc pl-4 mb-2 space-y-1" {...props} />
          ),
          ol: ({node, ...props}) => (
            <ol className="list-decimal pl-4 mb-2 space-y-1" {...props} />
          ),
          li: ({node, ...props}) => (
            <li className="mb-1" {...props} />
          ),
          a: ({node, ...props}) => (
            <a 
              className="text-blue-300 hover:text-blue-200 underline" 
              target="_blank"
              rel="noopener noreferrer"
              {...props} 
            />
          ),
          strong: ({node, ...props}) => (
            <strong className="font-bold" {...props} />
          ),
          em: ({node, ...props}) => (
            <em className="italic" {...props} />
          ),
          code: ({node, inline, ...props}) => (
            inline ? 
              <code className="bg-slate-700/50 px-1.5 py-0.5 rounded text-sm" {...props} /> :
              <code className="block bg-slate-700/50 p-3 rounded-md text-sm my-2 overflow-x-auto" {...props} />
          ),
          blockquote: ({node, ...props}) => (
            <blockquote 
              className="border-l-4 border-slate-600 pl-4 my-2 italic text-gray-300"
              {...props}
            />
          ),
          hr: ({node, ...props}) => (
            <hr className="my-4 border-slate-600" {...props} />
          ),
          table: ({node, ...props}) => (
            <div className="overflow-x-auto my-4">
              <table className="min-w-full divide-y divide-slate-600" {...props} />
            </div>
          ),
          thead: ({node, ...props}) => (
            <thead className="bg-slate-700/50" {...props} />
          ),
          th: ({node, ...props}) => (
            <th className="px-4 py-2 text-left text-sm font-semibold" {...props} />
          ),
          td: ({node, ...props}) => (
            <td className="px-4 py-2 text-sm border-t border-slate-600" {...props} />
          )
        }}
      >
        {content}
      </ReactMarkdown>

  );
};

export default MarkdownMessage;