// Footer.js
import React from 'react';
import packageJson from '../../package.json';
import { 
  Linkedin, 
  Twitter, 
  FileText, 
  ArrowUpRight 
} from 'lucide-react';

const Footer = () => {
  const footerSections = {
    products: {
      title: 'Products',
      links: [
        { name: 'GCP Marketplace', href: 'https://console.cloud.google.com/marketplace/browse?filter=partner:gClouds' },
        { name: 'Google Workspace', href: '/products/workspace/business' },
        { name: 'AppSheet', href: '/solutions/application/appsheet' },
        { name: 'Google Cloud Platform', href: '/products/gcp/compute-engine' }
      ]
    },
    solutions: {
      title: 'Solutions',
      links: [
        { name: 'Cloud Migration', href: '/solutions/infrastructure/migration' },
        { name: 'Chatbot Integration', href: '/solutions/ai-chat' },
        { name: 'Nia AI Sales Assistant', href: '/nia' },
        { name: 'Custom Mobile Apps', href: '/solutions/app-development' },
        { name: 'Smart Contracts', href: '/solutions/blockchain/smart-contracts' }
      ]
    },
    resources: {
      title: 'Resources',
      links: [
        { name: 'Blog', href: 'https://blog.gclouds.co.uk' },
        { name: 'Support Channels', href: '/support' }
      ]
    },
    company: {
      title: 'Company',
      links: [
        { name: 'Why Choose gClouds', href: '/why-gclouds' },
        { name: 'Contact', href: '/contact' },
        { name: 'Privacy Policy', href: '/privacy' }
      ]
    }
  };

  const socialLinks = [
    { 
      name: 'LinkedIn', 
      href: 'https://www.linkedin.com/company/gclouds',
      icon: Linkedin 
    },
    { 
      name: 'Twitter', 
      href: 'https://twitter.com/gclouds2',
      icon: Twitter 
    },
    { 
      name: 'Blog', 
      href: 'https://blog.gclouds.co.uk',
      icon: FileText 
    }
  ];

  return (
    <footer className="bg-slate-900">
      {/* Main Footer */}
      <div className="max-w-7xl mx-auto pt-12 px-4 sm:px-6 lg:px-8">
        <div className="xl:grid xl:grid-cols-4 xl:gap-8">
          {/* Footer Sections */}
          {Object.values(footerSections).map((section) => (
            <div key={section.title} className="mb-12 xl:mb-0">
              <h3 className="text-sm font-semibold text-white tracking-wider uppercase">
                {section.title}
              </h3>
              <ul className="mt-4 space-y-4">
                {section.links.map((link) => (
                  <li key={link.name}>
                    <a
                      href={link.href}
                      target={link.href.startsWith('http') ? '_blank' : '_self'}
                      rel={link.href.startsWith('http') ? 'noopener noreferrer' : ''}
                      className="text-base text-gray-400 hover:text-white transition-colors flex items-center gap-1"
                    >
                      {link.name}
                      {link.href.startsWith('http') && 
                        <ArrowUpRight className="w-4 h-4" />
                      }
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        {/* Divider */}
        <div className="border-t border-slate-800 mt-8 pt-8">
          {/* Company Info */}
          <div className="md:flex md:items-start md:justify-between">
            <div className="space-y-4">
              <p className="text-gray-400 text-sm">
                gClouds IT Consultancy Ltd
              </p>
              <p className="text-gray-400 text-sm">
                Hatfield, Hertfordshire, UK
              </p>
              <p className="text-gray-400 text-sm">
                Registered in England & Wales No 12220173
              </p>
            </div>

            {/* Social Links */}
            <div className="mt-8 md:mt-0">
              <div className="flex space-x-6">
                {socialLinks.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-400 hover:text-white transition-colors"
                  >
                    <span className="sr-only">{item.name}</span>
                    <item.icon className="h-6 w-6" />
                  </a>
                ))}
              </div>
            </div>
          </div>

          {/* Copyright */}
          <div className="mt-8 border-t border-slate-800 pt-8 pb-8">
            <p className="text-gray-400 text-sm">
              © {new Date().getFullYear()} gClouds ™ and Nia ™ are trademarks of the Gclouds IT Consultancy Ltd.
            </p>
            <p className="text-gray-400 text-sm">
              v{packageJson.version}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;