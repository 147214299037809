import React, { useState, useEffect } from 'react';
import { Check,X } from 'lucide-react';
import AIChatBox from '../../AIChatBox';

const ChatModal = ({ show, onClose, initialMessage }) => {
  if (!show) return null;
  return (
    <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
      <div className="bg-slate-900 rounded-xl max-w-4xl w-full">
        <div className="flex justify-between items-center p-6 border-b border-slate-800">
          <h2 className="text-2xl font-semibold text-white">Chat with Nia</h2>
          <button onClick={onClose} className="text-slate-400 hover:text-white">
            <X className="w-6 h-6" />
          </button>
        </div>
        <div className="p-6">
          <AIChatBox initialMessageModal={initialMessage} />
        </div>
      </div>
    </div>
  );
};

const ComparisonTable = () => {
  const [showChatModal, setShowChatModal] = useState(false);
  const [chatMessage, setChatMessage] = useState('');
  const [planType, setPlanType] = useState('business');
  const [billingCycle, setBillingCycle] = useState('annual');  // Set default to annual
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleGetPricing = () => {
    const message = `Hi Nia, I'm interested in Google Workspace Enterprise pricing and features. Could you help me understand the different Enterprise plans and their benefits for my organisation?`;
    setShowChatModal(true);
    setChatMessage(message);
  };

  const getPricing = (tier) => {
    const prices = {
      Starter: { monthly: '£6.49', annual: '£5.49' },
      Standard: { monthly: '£12.99', annual: '£10.99' },
      Plus: { monthly: '£20.99', annual: '£17.99' }
    };
    const price = billingCycle === 'monthly' 
      ? prices[tier].monthly 
      : prices[tier].annual;
    
    return (
      <div className="text-center">
        <div className="text-2xl font-medium">
          {price} <span className="text-base font-normal text-slate-500">GBP</span>
        </div>
        <div className="text-xs text-slate-500">
          per user/{billingCycle === 'monthly' ? 'month, no contract' : 'month, 1-year commitment'} 
        </div>
      </div>
    );
   };

  const renderPlanTypeToggle = () => (
    <div className="flex justify-center mb-8">
      <div className="relative inline-flex rounded-lg border border-slate-700 p-1 bg-slate-800">
        <div 
          className="absolute h-8 bg-blue-500 rounded-md transition-transform duration-200 ease-in-out"
          style={{
            width: '50%',
            transform: `translateX(${planType === 'business' ? '0%' : '100%'})`
          }}
        />
        <button
          onClick={() => setPlanType('business')}
          className="relative px-6 py-1.5 rounded-md text-sm font-medium transition-colors z-10"
          style={{
            color: planType === 'business' ? 'white' : '#94A3B8'
          }}
        >
          Business Plans
        </button>
        <button
          onClick={() => setPlanType('enterprise')}
          className="relative px-6 py-1.5 rounded-md text-sm font-medium transition-colors z-10"
          style={{
            color: planType === 'enterprise' ? 'white' : '#94A3B8'
          }}
        >
          Enterprise Plans
        </button>
      </div>
    </div>
  );

  const renderBillingToggle = () => (
    <div className="flex items-center justify-center gap-2">
      <div className="relative inline-flex rounded-md border border-slate-700 p-0.5 bg-slate-800">
        <div 
          className="absolute h-5 bg-blue-500 rounded transition-transform duration-200 ease-in-out"
          style={{
            width: '50%',
            transform: `translateX(${billingCycle === 'monthly' ? '0%' : '100%'})`
          }}
        />
        <button
          onClick={() => setBillingCycle('monthly')}
          className="relative px-2 py-0.5 rounded text-xs font-medium transition-colors z-10"
          style={{
            color: billingCycle === 'monthly' ? 'white' : '#94A3B8'
          }}
        >
          Monthly
        </button>
        <button
          onClick={() => setBillingCycle('annual')}
          className="relative px-2 py-0.5 rounded text-xs font-medium transition-colors z-10 group"
          style={{
            color: billingCycle === 'annual' ? 'white' : '#94A3B8'
          }}
        >
          Annual
          <div className="hidden group-hover:block absolute top-6 left-1/2 -translate-x-1/2 bg-slate-900 text-white text-xs p-2 rounded shadow-lg whitespace-nowrap">
            1-year commitment required
          </div>
        </button>
      </div>
    </div>
   );

  const renderMobileView = () => (
    <div>
      {renderPlanTypeToggle()}
      <div className="space-y-6 px-4">
        {(planType === 'business' ? ['Starter', 'Standard', 'Plus'] : ['Standard', 'Plus']).map((tier) => (
          <div key={tier} className="bg-slate-800 rounded-lg p-4">
            <h3 className="text-lg font-medium text-white mb-4">{`${planType === 'business' ? 'Business' : 'Enterprise'} ${tier}`}</h3>
            {planType === 'business' && (
              <>
                <div className="mb-4">{renderBillingToggle()}</div>
                <div className="mb-4 text-2xl font-bold text-white">
                  {getPricing(tier)}
                </div>
              </>
            )}
            <dl className="space-y-4">
              <div>
                <dt className="text-sm text-slate-400">Storage</dt>
                <dd className="text-white">{
                  planType === 'business'
                    ? (tier === 'Starter' ? '30GB' : tier === 'Standard' ? '2TB' : '5TB')
                    : 'Unlimited'
                }/user</dd>
              </div>
              <div>
                <dt className="text-sm text-slate-400">Meet Participants</dt>
                <dd className="text-white">{
                  planType === 'business'
                    ? (tier === 'Starter' ? '100' : tier === 'Standard' ? '150' : '500')
                    : (tier === 'Standard' ? '500' : '1000')
                }</dd>
              </div>
              <div>
                <dt className="text-sm text-slate-400">Security</dt>
                <dd className="text-white">{
                  planType === 'business'
                    ? (tier === 'Starter' ? 'Standard' : tier === 'Standard' ? 'Enhanced' : 'Advanced')
                    : 'Enterprise-grade'
                }</dd>
              </div>
            </dl>
            {planType === 'business' ? (
              <button
              onClick={() => window.open(
                billingCycle === 'annual'
                  ? {
                      'Starter': 'https://www.paypal.com/ncp/payment/7PUKQNSKH5M7C',
                      'Standard': 'https://www.paypal.com/ncp/payment/FD2PLX92D9NSA',
                      'Plus': 'https://www.paypal.com/ncp/payment/NWMVFJJTTX5PG'
                    }[tier]
                 : {
                      'Starter': 'https://www.paypal.com/ncp/payment/ZBJW4WFX885U2',
                      'Standard': 'https://www.paypal.com/ncp/payment/SCDR5GT8A4CWU',
                      'Plus': 'https://www.paypal.com/ncp/payment/CMXNSMWSRGXHY'
                    }[tier],
               '_blank')}
              className="w-full mt-6 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors">
                Buy Now
              </button>
            ) : (
              <button 
                onClick={handleGetPricing}
                className="w-full mt-6 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors"
              >
                Chat with Nia
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  );

  const renderDesktopView = () => (
    <div className="max-w-7xl mx-auto px-6">
      {renderPlanTypeToggle()}
      <div className="overflow-x-auto">
        <table className="w-full text-white rounded-lg border border-slate-700">
          <thead>
            <tr className="border-b border-slate-700">
              <th className="text-left p-4 bg-slate-800">Features</th>
              {planType === 'business' ? (
                <>
                  <th className="p-4 bg-slate-800 text-center">
                    <div>Business Starter</div>
                    {renderBillingToggle()}
                    <div className="text-2xl font-bold mt-2">{getPricing('Starter')}</div>
                  </th>
                  <th className="p-4 bg-slate-800 text-center">
                    <div>Business Standard</div>
                    {renderBillingToggle()}
                    <div className="text-2xl font-bold mt-2">{getPricing('Standard')}</div>
                  </th>
                  <th className="p-4 bg-slate-800 text-center">
                    <div>Business Plus</div>
                    {renderBillingToggle()}
                    <div className="text-2xl font-bold mt-2">{getPricing('Plus')}</div>
                  </th>
                </>
              ) : (
                <>
                  <th className="p-4 bg-slate-800 text-center">Enterprise Standard</th>
                  <th className="p-4 bg-slate-800 text-center">Enterprise Plus</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            <tr className="border-b border-slate-700">
              <td className="p-4 font-medium">Storage</td>
              {planType === 'business' ? (
                <>
                  <td className="p-4 text-center">30GB per user</td>
                  <td className="p-4 text-center">2TB per user</td>
                  <td className="p-4 text-center">5TB per user</td>
                </>
              ) : (
                <>
                  <td className="p-4 text-center">Unlimited</td>
                  <td className="p-4 text-center">Unlimited</td>
                </>
              )}
            </tr>
            <tr className="border-b border-slate-700">
              <td className="p-4 font-medium">Meet Participants</td>
              {planType === 'business' ? (
                <>
                  <td className="p-4 text-center">100</td>
                  <td className="p-4 text-center">150</td>
                  <td className="p-4 text-center">500</td>
                </>
              ) : (
                <>
                  <td className="p-4 text-center">500</td>
                  <td className="p-4 text-center">1000</td>
                </>
              )}
            </tr>
            <tr className="border-b border-slate-700">
              <td className="p-4 font-medium">Appointment Booking pages</td>
              {planType === 'business' ? (
                <>
                  <td className="p-4 text-center">
                    <Check className="w-5 h-5 mx-auto text-white" />
                  </td>
                  <td className="p-4 text-center">
                    <Check className="w-5 h-5 mx-auto text-white" />
                  </td>
                  <td className="p-4 text-center">
                    <Check className="w-5 h-5 mx-auto text-white" />
                  </td>
                </>
              ) : (
                <>
                  <td className="p-4 text-center">
                    <Check className="w-5 h-5 mx-auto text-white" />
                  </td>
                  <td className="p-4 text-center">
                    <Check className="w-5 h-5 mx-auto text-white" />
                  </td>
                </>
              )}
            </tr>
            <tr className="border-b border-slate-700">
              <td className="p-4 font-medium">eSignature</td>
              {planType === 'business' ? (
                <>
                  <td className="p-4 text-center">—</td>
                  <td className="p-4 text-center">
                    <Check className="w-5 h-5 mx-auto text-white" />
                  </td>
                  <td className="p-4 text-center">
                    <Check className="w-5 h-5 mx-auto text-white" />
                  </td>
                </>
              ) : (
                <>
                  <td className="p-4 text-center">
                    <Check className="w-5 h-5 mx-auto text-white" />
                  </td>
                  <td className="p-4 text-center">
                    <Check className="w-5 h-5 mx-auto text-white" />
                  </td>
                </>
              )}
            </tr>

            <tr className="border-b border-slate-700">
              <td className="p-4 font-medium">eDiscovery & Vault</td>
              {planType === 'business' ? (
                <>
                  <td className="p-4 text-center">—</td>
                  <td className="p-4 text-center">—</td>
                  <td className="p-4 text-center">
                    <Check className="w-5 h-5 mx-auto text-white" />
                  </td>
                </>
              ) : (
                <>
                  <td className="p-4 text-center">
                    <Check className="w-5 h-5 mx-auto text-white" />
                  </td>
                  <td className="p-4 text-center">
                    <Check className="w-5 h-5 mx-auto text-white" />
                  </td>
                </>
              )}
            </tr>
            <tr className="border-b border-slate-700">
              <td className="p-4 font-medium">Endpoint Management</td>
              {planType === 'business' ? (
                <>
                  <td className="p-4 text-center">—</td>
                  <td className="p-4 text-center">—</td>
                  <td className="p-4 text-center">
                    <Check className="w-5 h-5 mx-auto text-white" />
                  </td>
                </>
              ) : (
                <>
                  <td className="p-4 text-center">
                    <Check className="w-5 h-5 mx-auto text-white" />
                  </td>
                  <td className="p-4 text-center">
                    <Check className="w-5 h-5 mx-auto text-white" />
                  </td>
                </>
              )}
            </tr>

            <tr className="border-b border-slate-700">
              <td className="p-4 font-medium">Client-side encryption</td>
              {planType === 'business' ? (
                <>
                  <td className="p-4 text-center">—</td>
                  <td className="p-4 text-center">—</td>
                  <td className="p-4 text-center">—</td>
                </>
              ) : (
                <>
                  <td className="p-4 text-center">—</td>
                  <td className="p-4 text-center">
                    <Check className="w-5 h-5 mx-auto text-white" />
                  </td>
                </>
              )}
            </tr>
            <tr className="border-b border-slate-700">
              <td className="p-4 font-medium">Advanced data exports</td>
              {planType === 'business' ? (
                <>
                  <td className="p-4 text-center">—</td>
                  <td className="p-4 text-center">—</td>
                  <td className="p-4 text-center">—</td>
                </>
              ) : (
                <>
                  <td className="p-4 text-center">—</td>
                  <td className="p-4 text-center">
                    <Check className="w-5 h-5 mx-auto text-white" />
                  </td>
                </>
              )}
            </tr>
            <tr className="border-b border-slate-700">
              <td className="p-4 font-medium">AI classification in Drive</td>
              {planType === 'business' ? (
                <>
                  <td className="p-4 text-center">—</td>
                  <td className="p-4 text-center">—</td>
                  <td className="p-4 text-center">—</td>
                </>
              ) : (
                <>
                  <td className="p-4 text-center">—</td>
                  <td className="p-4 text-center">
                    <Check className="w-5 h-5 mx-auto text-white" />
                  </td>
                </>
              )}
            </tr>
            <tr className="border-b border-slate-700">
              <td className="p-4 font-medium">Support</td>
              {planType === 'business' ? (
                <>
                  <td className="p-4 text-center">Standard</td>
                  <td className="p-4 text-center">Standard</td>
                  <td className="p-4 text-center">Enhanced</td>
                </>
              ) : (
                <>
                  <td className="p-4 text-center">Enhanced</td>
                  <td className="p-4 text-center">Enhanced</td>
                </>
              )}
            </tr>
            {planType === 'business' && (
              <tr className="h-20">
                <td className="p-4"></td>
                <td className="p-4 text-center">
                  <button
                    onClick={() => window.open(
                      billingCycle === 'annual'
                        ? 'https://www.paypal.com/ncp/payment/7PUKQNSKH5M7C'
                        : 'https://www.paypal.com/ncp/payment/ZBJW4WFX885U2', 
                      '_blank'
                    )}      
                    className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition-colors hover:scale-105">
                    Buy Now
                  </button>
                </td>
                <td className="p-4 text-center">
                  <button
                    onClick={() => window.open(
                      billingCycle === 'annual'
                        ? 'https://www.paypal.com/ncp/payment/FD2PLX92D9NSA'
                        : 'https://www.paypal.com/ncp/payment/SCDR5GT8A4CWU', 
                      '_blank'
                    )}        
                  className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition-colors hover:scale-105">
                    Buy Now
                  </button>
                </td>
                <td className="p-4 text-center">
                  <button
                    onClick={() => window.open(
                      billingCycle === 'annual'
                        ? 'https://www.paypal.com/ncp/payment/NWMVFJJTTX5PG'
                        : 'https://www.paypal.com/ncp/payment/CMXNSMWSRGXHY', 
                      '_blank'
                    )}          
                  className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition-colors hover:scale-105">
                    Buy Now
                  </button>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {planType === 'enterprise' && (
        <div className="flex justify-center mt-12">
          <button 
            onClick={handleGetPricing}
            className="bg-blue-500 hover:bg-blue-600 text-white px-6 py-2 rounded-xl font-medium shadow-lg transition-all hover:scale-105"
          >
            Chat Nia for prices
          </button>
        </div>
      )}
    </div>
  );

  return (
    <div className="py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mb-8">
      <h1 className="text-4xl md:text-5xl font-bold text-white text-center mb-6">Google Workspace Plans</h1>
        <p className="text-xl text-slate-400 max-w-3xl mx-auto text-center mb-8">
          Choose the plan that's right for your business
        </p>
      </div>
      {isMobile ? renderMobileView() : renderDesktopView()}
      <ChatModal 
        show={showChatModal}
        onClose={() => setShowChatModal(false)}
        initialMessage={chatMessage}
        />
    </div>
  );
};

export default ComparisonTable;