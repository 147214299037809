import React, { useState } from 'react';
import { 
  MessageSquare, 
  Calendar, 
  FileText, 
  Shield, 
  CreditCard, 
  Users, 
  ChevronRight, 
  Check, 
  BarChart, 
  GraduationCap,
  X
} from 'lucide-react';
import AIChatBox from '../AIChatBox';
import ROICalculator from '../ai/ROICalculator';

const NiaProductPage = () => {
  const [showChatModal, setShowChatModal] = useState(false);
  const [chatMessage, setChatMessage] = useState('');

  const handleChatWithNia = (topic) => {
    let message = '';
    switch(topic) {
      case 'general':
        message = "Hi Nia, I'd like to learn more about the AI Sales Assistant and how it can help with our sales process.";
        break;
      case 'schedule':
        message = "Hi Nia, I'm interested in scheduling a consultation to learn more about AI Sales Assistant.";
        break;
      case 'workspace':
        message = "Hi Nia, I need help comparing Google Workspace plans for my business. Could you advise me?";
        break;
      case 'quote':
        message = "Hi Nia, I'm interested in getting a quote for AI Sales Assistant.";
        break;
      default:
        message = "Hi Nia, I'd like to learn more about your capabilities.";
    }
    setShowChatModal(true);
    setChatMessage(message);
  };
  return (
    <div className="bg-gradient-to-br from-slate-950 via-slate-900 to-blue-950 text-white min-h-screen">
      {/* Hero Section */}
      <section className="relative pt-24 pb-32 px-6 overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-br from-blue-500/10 via-purple-500/10 to-slate-950 pointer-events-none" />
        <div className="max-w-7xl mx-auto relative z-10">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            <div>
              <div className="inline-flex items-center space-x-2 bg-blue-500/10 rounded-full px-4 py-2 mb-8">
                <Shield className="w-5 h-5 text-blue-400" />
                <span className="text-blue-400">Powered by Gemini GenAI & Google Cloud</span>
              </div>
              <h1 className="text-5xl md:text-6xl font-bold mb-6">
                Meet <span className="bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">Nia</span>, Your AI Sales Assistant
              </h1>
              <p className="text-xl text-slate-300 mb-8">
                Supercharge your sales with an intelligent, 24/7 sales assistant that qualifies leads, schedules meetings, and accelerates your sales process.
              </p>
              <div className="flex flex-col sm:flex-row gap-4">
                <a 
                  href="#demo" 
                  onClick={() => handleChatWithNia('general')}
                  className="px-8 py-3 bg-blue-600 hover:bg-blue-700 rounded-lg font-medium transition-colors text-center"
                >
                  Try Demo
                </a>
                <a 
                  href="#pricing" 
                  className="px-8 py-3 bg-slate-700 hover:bg-slate-600 rounded-lg font-medium transition-colors text-center"
                >
                  View Pricing
                </a>
              </div>
            </div>
            
            <div className="relative">
              <div className="bg-slate-800/70 backdrop-blur-sm rounded-2xl border border-slate-700/50 shadow-xl overflow-hidden">
                <div className="flex items-center justify-between p-4 border-b border-slate-700">
                  <div className="flex items-center space-x-3">
                    <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center">
                      <div className="w-8 h-8 rounded-full bg-blue-400 flex items-center justify-center text-white font-bold">N</div>
                    </div>
                    <div>
                      <h3 className="font-medium">Nia</h3>
                      <p className="text-xs text-slate-400">Google Cloud Sales Expert</p>
                    </div>
                  </div>
                </div>
                
                <div className="p-4 h-80 overflow-y-auto space-y-4">
                  <div className="flex items-start space-x-2">
                    <div className="w-8 h-8 rounded-full bg-blue-500/20 flex-shrink-0" />
                    <div className="bg-slate-700 rounded-lg p-3 max-w-[85%]">
                      <p className="text-sm">Hi there! I'm Nia, your Google Cloud assistant. How can I help you today?</p>
                    </div>
                  </div>
                  
                  <div className="flex justify-end">
                    <div className="bg-blue-600 rounded-lg p-3 max-w-[85%]">
                      <p className="text-sm">I need help choosing a Google Workspace plan for my team of 15 people.</p>
                    </div>
                  </div>
                  
                  <div className="flex items-start space-x-2">
                    <div className="w-8 h-8 rounded-full bg-blue-500/20 flex-shrink-0" />
                    <div className="bg-slate-700 rounded-lg p-3 max-w-[85%]">
                      <p className="text-sm">I'd be happy to help you choose the right Google Workspace plan. For a team of 15, I recommend considering Business Standard at £10.99/user/month (annual commitment), which includes:</p>
                      <ul className="text-sm mt-2 space-y-1">
                        <li>• 2TB cloud storage per user</li>
                        <li>• Enhanced security controls</li>
                        <li>• Standard support</li>
                        <li>• Video meetings with up to 150 participants</li>
                      </ul>
                      <p className="text-sm mt-2">Would you like me to email you a custom quote with our partner discount?</p>
                    </div>
                  </div>
                </div>
                
                <div className="p-4 border-t border-slate-700">
                  <div className="flex space-x-2">
                    <input 
                      type="text" 
                      className="flex-1 bg-slate-800 border border-slate-600 rounded-lg px-4 py-2 text-sm"
                      placeholder="Type your message..."
                    />
                    <button className="bg-blue-600 p-2 rounded-lg">
                      <ChevronRight className="w-5 h-5" />
                    </button>
                  </div>
                </div>
              </div>
              
              {/* Decorative elements */}
              <div className="absolute -bottom-6 -right-6 w-32 h-32 bg-blue-500/20 rounded-full blur-3xl"></div>
              <div className="absolute -top-6 -left-6 w-32 h-32 bg-purple-500/20 rounded-full blur-3xl"></div>
            </div>
          </div>
        </div>
      </section>
      
      {/* Features Section */}
      <section className="py-20 px-6 bg-slate-900">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-16">
            <h2 className="text-3xl md:text-4xl font-bold mb-4">Transform Your Sales Process</h2>
            <p className="text-xl text-slate-300 max-w-3xl mx-auto">
              Nia handles the repetitive tasks of your sales process so your team can focus on building relationships and closing deals.
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[
              {
                icon: <MessageSquare className="w-6 h-6" />,
                title: "Intelligent Lead Qualification",
                description: "Nia engages with prospects, answers product questions, and qualifies leads using BANT criteria before routing them to your sales team."
              },
              {
                icon: <FileText className="w-6 h-6" />,
                title: "Custom Quote Generation",
                description: "Generate and email detailed quotes with your branding. Nia can create tailored quotes based on customer needs and send them directly."
              },
              {
                icon: <Calendar className="w-6 h-6" />,
                title: "Automated Meeting Scheduling",
                description: "Let Nia coordinate and book meetings between prospects and your sales team directly in Google Calendar."
              },
              {
                icon: <CreditCard className="w-6 h-6" />,
                title: "Streamlined Purchase Process",
                description: "Nia sends custom purchase links with your payment gateway, making it easy for customers to complete transactions."
              },
              {
                icon: <BarChart className="w-6 h-6" />,
                title: "Sales Analytics",
                description: "Gain insights into your sales pipeline with detailed analytics on conversations, lead qualification, and conversion rates."
              },
              {
                icon: <Shield className="w-6 h-6" />,
                title: "Enterprise-Grade Security",
                description: "Built on Google Cloud with robust security measures including email verification and secure data handling."
              }
            ].map((feature, index) => (
              <div 
                key={index} 
                className="bg-slate-800/50 border border-slate-700/50 rounded-xl p-6 hover:border-blue-500/50 transition-colors"
              >
                <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center text-blue-400 mb-4">
                  {feature.icon}
                </div>
                <h3 className="text-xl font-semibold mb-3">{feature.title}</h3>
                <p className="text-slate-300">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      
      {/* How It Works Section */}
      <section className="py-20 px-6">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-16">
            <h2 className="text-3xl md:text-4xl font-bold mb-4">How Nia Works</h2>
            <p className="text-xl text-slate-300 max-w-3xl mx-auto">
              Powered by Google's advanced AI, Nia seamlessly integrates with your sales process.
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center mb-20">
            <div className="bg-slate-800 rounded-xl overflow-hidden shadow-xl">
              <img 
                src="images/nia_dashboard1024x1024.png" 
                alt="Nia Dashboard Preview" 
                className="w-full h-auto"
              />
            </div>
            
            <div className="space-y-8">
              {[
                {
                  number: "01",
                  title: "Connect Nia to your website",
                  description: "Simple integration with your existing website using our JavaScript widget or API."
                },
                {
                  number: "02",
                  title: "Customise your sales knowledge base",
                  description: "Upload your product information, pricing, and FAQs to personalise Nia for your business."
                },
                {
                  number: "03",
                  title: "Nia engages with prospects",
                  description: "When visitors have questions, Nia provides accurate information and qualifies their interest."
                },
                {
                  number: "04",
                  title: "Review insights in your dashboard",
                  description: "Track conversations, scheduled meetings, quotes sent, and completed purchases."
                }
              ].map((step, index) => (
                <div key={index} className="flex items-start space-x-4">
                  <div className="w-12 h-12 rounded-lg bg-blue-500/10 text-blue-400 flex items-center justify-center font-bold flex-shrink-0">
                    {step.number}
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold mb-2">{step.title}</h3>
                    <p className="text-slate-300">{step.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      
      {/* Benefits Section */}
      <section className="py-20 px-6 bg-slate-900">
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
            <div>
              <h2 className="text-3xl md:text-4xl font-bold mb-8">The Benefits of AI-Powered Sales</h2>
              
              <div className="space-y-6">
                {[
                  {
                    title: "24/7 Lead Qualification",
                    description: "Never miss a sales opportunity with round-the-clock lead engagement and qualification."
                  },
                  {
                    title: "Reduced Response Time",
                    description: "Instant responses to prospect inquiries increase engagement and conversion rates."
                  },
                  {
                    title: "Consistent Sales Messaging",
                    description: "Ensure your sales messaging is consistent and accurate across all customer interactions."
                  },
                  {
                    title: "Scalable Sales Operations",
                    description: "Handle more leads without increasing headcount, allowing your team to focus on high-value activities."
                  },
                  {
                    title: "Data-Driven Insights",
                    description: "Gain valuable insights into customer preferences and sales patterns to optimise your strategy."
                  }
                ].map((benefit, index) => (
                  <div key={index} className="flex items-start space-x-3">
                    <div className="p-1 bg-blue-500/10 rounded-full text-blue-400 flex-shrink-0 mt-1">
                      <Check className="w-5 h-5" />
                    </div>
                    <div>
                      <h3 className="text-lg font-semibold mb-1">{benefit.title}</h3>
                      <p className="text-slate-300">{benefit.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            
            <div className="relative">
              <div className="absolute -inset-4 bg-gradient-to-br from-blue-500/20 via-purple-500/20 to-transparent blur-3xl rounded-3xl"></div>
              <div className="relative bg-slate-800 rounded-xl overflow-hidden border border-slate-700/50 shadow-xl">
                <div className="p-8">
                  <div className="flex items-center justify-between mb-8">
                    <h3 className="text-2xl font-bold">Performance Impact</h3>
                    <span className="bg-blue-500/10 text-blue-400 px-3 py-1 rounded-full text-sm font-medium">Average Results</span>
                  </div>
                  
                  <div className="space-y-6">
                    {[
                      { metric: "Lead Response Time", value: "< 1 minute", improvement: "↓ 98%" },
                      { metric: "Qualified Leads", value: "3x increase", improvement: "↑ 200%" },
                      { metric: "Sales Cycle Duration", value: "30% shorter", improvement: "↓ 30%" },
                      { metric: "Customer Satisfaction", value: "92% positive", improvement: "↑ 15%" },
                      { metric: "Sales Team Capacity", value: "40% more time", improvement: "↑ 40%" }
                    ].map((stat, index) => (
                      <div key={index} className="flex items-center justify-between pb-4 border-b border-slate-700/50">
                        <div className="flex items-center space-x-3">
                          <div className="w-3 h-3 rounded-full bg-blue-500"></div>
                          <span className="text-slate-200">{stat.metric}</span>
                        </div>
                        <div className="flex items-center space-x-4">
                          <span className="font-semibold">{stat.value}</span>
                          <span className={`text-sm ${stat.improvement.includes('↑') ? 'text-green-400' : 'text-blue-400'}`}>
                            {stat.improvement}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
        {/* Pricing Section */}
        <section id="pricing" className="py-20 px-6">
        <div className="max-w-7xl mx-auto">
            <div className="text-center mb-16">
            <h2 className="text-3xl md:text-4xl font-bold mb-4">Outcome-Based Pricing: You Win, We Win</h2>
            <p className="text-xl text-slate-300 max-w-3xl mx-auto">
            A transparent partnership model with no hidden fees
            </p>
            </div>
            
            <div className="relative">
                <div className="absolute inset-0 bg-blue-500/10 rounded-xl -mx-4 -my-4 blur-xl"></div>
                <div className="relative grid grid-cols-1 md:grid-cols-3 gap-6 bg-slate-800/50 border border-slate-700/50 rounded-xl p-8">
                    {[
                        {
                            step: "01",
                            title: "One-Time Setup",
                            description: "From £999, Professional Services deployment of Nia",
                            details: [
                                "Nia website integration",
                                "Google Cloud Platform Project configuration",
                                "Sales knowledge base creation"
                            ]
                        },
                        {
                            step: "02",
                            title: "Nia Finds Leads",
                            description: "Automatically engage and qualify potential customers through intelligent conversation",
                            details: [
                                "24/7 prospect interaction",
                                "BANT qualification criteria",
                                "Contextual lead scoring"
                            ]
                        },
                        {
                            step: "03",
                            title: "Infrastructure",
                            description: "Pay only for actual usage",
                            details: [
                                "Zero cost during low traffic",
                                "No minimum monthly charge",
                                "Scales directly with usage",
                                "Full Google Cloud Platform billing transparency",
                                "Predictable cost model"
                            ]
                            },
                        {
                            step: "04",
                            title: "Customer Conversion",
                            description: "Transform qualified leads into paying customers",
                            details: [
                                "Product recommendations",
                                "Streamlined purchase process",
                                "Instant quote generation"
                            ]
                        },
                        {
                            step: "05",
                            title: "Invoice Generation",
                            description: "Customer completes purchase and you send your first invoice",
                            details: [
                                "Clear sales attribution",
                                "Transparent invoicing",
                                "Verifiable sales tracking"
                            ]
                        },
                        {
                            step: "06",
                            title: "Success Commission",
                            description: "15% → 8% Commission from Nia-generated sales",
                            details: [
                                "15% on your customer's first invoice",
                                "8% from your customer's future billings",
                                "No commission on unattributed sales"
                            ]
                            }
                    ].map((item, index) => (
                        <div 
                            key={index} 
                            className="bg-slate-900/50 border border-slate-700/50 rounded-xl p-6"
                        >
                            <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center text-blue-400 mb-4 font-bold">
                                {item.step}
                            </div>
                            <h3 className="text-xl font-semibold mb-3">{item.title}</h3>
                            <p className="text-slate-300 mb-4">{item.description}</p>
                            
                            <ul className="space-y-2">
                                {item.details.map((detail, i) => (
                                    <li key={i} className="flex items-start space-x-2">
                                        <Check className="w-4 h-4 text-blue-400 flex-shrink-0 mt-1" />
                                        <span className="text-slate-400 text-sm">{detail}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
            <div className="mt-12 text-center">
            <ROICalculator />
            </div>
                    {/* ROI caculator Section
            <div className="mt-12 text-center">
                <div className="bg-slate-800/50 border border-slate-700/50 rounded-xl p-8 max-w-3xl mx-auto">
                    <h3 className="text-2xl font-bold mb-4">Example ROI Calculation</h3>
                    <div className="grid md:grid-cols-2 gap-6">
                        <div>
                            <p className="text-slate-300 mb-4">
                                Scenario: Average Sale Value of £5,000
                            </p>
                            <ul className="space-y-2">
                                <li className="flex items-center space-x-2">
                                    <Check className="w-5 h-5 text-blue-400" />
                                    <span>First Invoice Commission: £750 (15%)</span>
                                </li>
                                <li className="flex items-center space-x-2">
                                    <Check className="w-5 h-5 text-blue-400" />
                                    <span>Ongoing Commission: £400 (8%)</span>
                                </li>
                                <li className="flex items-center space-x-2">
                                    <Check className="w-5 h-5 text-blue-400" />
                                    <span>Total First Year: £1,150</span>
                                </li>
                            </ul>
                        </div>
                        <div className="bg-blue-500/10 rounded-lg p-4">
                            <h4 className="text-xl font-semibold text-blue-400 mb-2">Net Gain Calculation</h4>
                            <div className="space-y-2">
                                <div className="flex justify-between">
                                    <span className="text-slate-300">Total Sales Generated</span>
                                    <span className="font-semibold">£5,000</span>
                                </div>
                                <div className="flex justify-between">
                                    <span className="text-slate-300">Nia Commission</span>
                                    <span className="text-red-400">-£1,150</span>
                                </div>
                                <div className="border-t border-slate-700 pt-2 flex justify-between">
                                    <span className="text-slate-300 font-bold">Your Net Gain</span>
                                    <span className="text-green-400 font-bold">£3,850</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            */}
            
            <div className="mt-8 text-center">
            <p className="text-slate-300">
                Ready to transform your sales process? <a href="#contact" className="text-blue-400 hover:underline">Schedule a consultation</a>
            </p>
            </div>
        </div>
        </section>
      
      {/* Demo Section with Embedded AIChatBox */}
      <section id="demo" className="py-20 px-6 bg-slate-900">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-16">
            <h2 className="text-3xl md:text-4xl font-bold mb-4">Try Nia Today</h2>
            <p className="text-xl text-slate-300 max-w-3xl mx-auto">
              Experience the power of AI-assisted sales with a live demo of Nia.
            </p>
          </div>
          
          <div className="grid grid-cols-1 lg:grid-cols-5 gap-8">
            <div className="lg:col-span-2 flex flex-col" style={{ height: "500px" }}>
              <div className="bg-slate-800 rounded-xl p-6 border border-slate-700/50 h-[280px] mb-5">
                <h3 className="text-xl font-semibold mb-4 flex items-center">
                  <GraduationCap className="w-5 h-5 mr-2 text-blue-400" />
                  How to use this demo
                </h3>
                <ul className="space-y-3">
                  <li className="flex items-start space-x-3">
                    <div className="w-6 h-6 rounded-full bg-blue-500/20 text-blue-400 flex items-center justify-center flex-shrink-0 mt-0.5">1</div>
                    <p>Ask Nia about Google Cloud products and pricing</p>
                  </li>
                  <li className="flex items-start space-x-3">
                    <div className="w-6 h-6 rounded-full bg-blue-500/20 text-blue-400 flex items-center justify-center flex-shrink-0 mt-0.5">2</div>
                    <p>Request a custom quote for your business needs</p>
                  </li>
                  <li className="flex items-start space-x-3">
                    <div className="w-6 h-6 rounded-full bg-blue-500/20 text-blue-400 flex items-center justify-center flex-shrink-0 mt-0.5">3</div>
                    <p>Try scheduling a consultation meeting</p>
                  </li>
                  <li className="flex items-start space-x-3">
                    <div className="w-6 h-6 rounded-full bg-blue-500/20 text-blue-400 flex items-center justify-center flex-shrink-0 mt-0.5">4</div>
                    <p>Compare different Google Workspace plans</p>
                  </li>
                </ul>
              </div>
              
              <div className="bg-slate-800 rounded-xl p-6 border border-slate-700/50 h-[280px]">
                <h3 className="text-xl font-semibold mb-4 flex items-center">
                  <Users className="w-5 h-5 mr-2 text-blue-400" />
                  The Power of AI in Sales
                </h3>
                <div className="space-y-4">
                  <div className="pb-4 border-b border-slate-700/50">
                    <p className="italic text-slate-300 mb-2">"In our initial deployment, Nia has already helped us generate £2,500 in potential sales, demonstrating the promising potential of AI-assisted sales."</p>
                    <p className="text-sm text-slate-400">— M Gulercan, Director at gClouds</p>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="lg:col-span-3">
              {showChatModal ? (
                <div className="bg-slate-800 rounded-xl border border-slate-700/50 overflow-hidden h-full">
                  <div className="p-4 border-b border-slate-700 flex items-center justify-between">
                    <div className="flex items-center space-x-3">
                      <div className="w-10 h-10 rounded-full bg-blue-500/20 flex items-center justify-center">
                        <span className="font-bold text-blue-400">N</span>
                      </div>
                      <div>
                        <h3 className="font-medium">Nia Demo</h3>
                        <p className="text-xs text-slate-400">Live Interactive Demo</p>
                      </div>
                    </div>
                    <button 
                      onClick={() => setShowChatModal(false)}
                      className="text-slate-400 hover:text-white"
                    >
                      <X className="w-5 h-5" />
                    </button>
                  </div>
                    <AIChatBox 
                      initialMessageModal={chatMessage || "Hi! I'd like to learn more about how Nia can help with our sales process."} 
                    />
                  </div>
              ) : (
                <div className="bg-slate-800 rounded-xl border border-slate-700/50 overflow-hidden h-full flex flex-col">
                  <div className="p-4 border-b border-slate-700 flex items-center space-x-3">
                    <div className="w-10 h-10 rounded-full bg-blue-500/20 flex items-center justify-center">
                      <span className="font-bold text-blue-400">N</span>
                    </div>
                    <div>
                      <h3 className="font-medium">Nia Demo</h3>
                      <p className="text-xs text-slate-400">Live Interactive Demo</p>
                    </div>
                  </div>
                  
                  <div className="flex-1 p-6 flex items-center justify-center">
                    <div className="text-center">
                      <div className="w-20 h-20 bg-blue-500/10 rounded-full flex items-center justify-center mx-auto mb-4">
                        <MessageSquare className="w-10 h-10 text-blue-400" />
                      </div>
                      <h3 className="text-xl font-semibold mb-2">Try Nia Live Demo</h3>
                      <p className="text-slate-300 mb-6">Experience how Nia can transform your sales process</p>
                      <button 
                        onClick={() => {
                          setShowChatModal(true);
                          setChatMessage("");
                        }}
                        className="px-6 py-3 bg-blue-600 hover:bg-blue-700 rounded-lg font-medium transition-colors"
                      >
                        Start Demo
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      
      {/* Contact Section - Using Nia Instead of Form */}
      <section id="contact" className="py-20 px-6">
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            <div>
              <h2 className="text-3xl md:text-4xl font-bold mb-6">Ready to transform your sales process?</h2>
              <p className="text-xl text-slate-300 mb-8">
                Talk to Nia directly to experience how she can transform your sales process. She'll guide you through scheduling a demo or answering any questions you might have.
              </p>
              
              <div className="space-y-6">
                <div className="flex items-start space-x-4">
                  <div className="w-12 h-12 rounded-lg bg-blue-500/10 text-blue-400 flex items-center justify-center flex-shrink-0">
                    <MessageSquare className="w-6 h-6" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold mb-1">Chat with Nia</h3>
                    <p className="text-slate-300 mb-2">Nia can answer your questions, provide a live demo now, or schedule a consultation with our team.</p>
                    <a 
                      href="#demo" 
                      onClick={() => handleChatWithNia('general')}
                      className="text-blue-400 hover:text-blue-300 transition-colors font-medium inline-flex items-center"
                    >
                      Try Nia now <ChevronRight className="w-4 h-4 ml-1" />
                    </a>
                  </div>
                </div>
                
                <div className="flex items-start space-x-4">
                  <div className="w-12 h-12 rounded-lg bg-blue-500/10 text-blue-400 flex items-center justify-center flex-shrink-0">
                    <Calendar className="w-6 h-6" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold mb-1">Schedule via Nia</h3>
                    <p className="text-slate-300 mb-2">Let Nia book a consultation for you with our Google Cloud specialists.</p>
                    <a
                      href="#demo" 
                      onClick={() => handleChatWithNia('schedule')}
                      className="text-blue-400 hover:text-blue-300 transition-colors font-medium inline-flex items-center"
                    >
                      Schedule now <ChevronRight className="w-4 h-4 ml-1" />
                      </a>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="bg-slate-800/50 rounded-xl border border-slate-700/50 overflow-hidden">
              <div className="p-6 flex justify-center">
                <img 
                  src="/images/nia_in_action_24hr512x358.png" 
                  alt="Nia available 24/7 across all devices" 
                  className="max-w-full h-auto rounded-lg"
                  style={{ objectFit: 'contain' }}
                />
              </div>
              <div className="p-6">
                <h3 className="text-xl font-semibold mb-2">Why use Nia for sales?</h3>
                <ul className="space-y-3">
                  <li className="flex items-start space-x-3">
                    <Check className="w-5 h-5 text-blue-400 flex-shrink-0 mt-0.5" />
                    <span>Available 24/7 to engage with prospects</span>
                  </li>
                  <li className="flex items-start space-x-3">
                    <Check className="w-5 h-5 text-blue-400 flex-shrink-0 mt-0.5" />
                    <span>Handles repetitive tasks while your team focuses on closing</span>
                  </li>
                  <li className="flex items-start space-x-3">
                    <Check className="w-5 h-5 text-blue-400 flex-shrink-0 mt-0.5" />
                    <span>Provides consistent, accurate product information</span>
                  </li>
                  <li className="flex items-start space-x-3">
                    <Check className="w-5 h-5 text-blue-400 flex-shrink-0 mt-0.5" />
                    <span>Generates qualified leads with detailed context</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default NiaProductPage;