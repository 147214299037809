// CallService.js update
import { functions } from '../../firebaseConfig';
import { httpsCallable } from 'firebase/functions';

const callGemini = httpsCallable(functions, 'callGemini');
const textToSpeech = httpsCallable(functions, 'textToSpeech');

export const callNia = async ({ sessionId, isInitializing = false, prompt = '' }) => {
  try {
    console.log('Calling Gemini with:', { 
      sessionId, 
      isInitializing, 
      prompt,
      model: 'gemini-1.5-pro-002' // Explicitly specify model
    });
    
    const result = await callGemini({
      sessionId,
      isInitializing,
      prompt,
      model: 'gemini-1.5-pro-002', // This is the latest Gemini model with best conversation capabilities
      temperature: 0.2, // Lower temperature for more consistent responses
      maxTokens: 256 // Enough for voice responses
    });
    
    // Extract text response
    let textResponse = '';
    
    if (isInitializing && result.data?.messages && result.data.messages.length > 0) {
      textResponse = result.data.messages[0];
    } else if (result.data?.response) {
      textResponse = result.data.response;
    }
    
    console.log('Extracted text response:', {
      text: textResponse ? textResponse.substring(0, 50) + '...' : 'EMPTY',
      length: textResponse ? textResponse.length : 0
    });
      
    if (!textResponse || textResponse.trim() === '') {
      console.error('Empty response from Gemini');
      return { audioUrl: null, text: null, sessionId };
    }

    const ttsResult = await textToSpeech({
      text: textResponse,
      sessionId: sessionId || 'unknown',
      voice: 'en-GB-Neural2-C' // Specify voice to ensure consistency
    });
    
    return {
      audioUrl: ttsResult.data.audioUrl,
      text: textResponse,
      sessionId: result.data.sessionId || sessionId
    };
  } catch (error) {
    console.error('Error calling Nia:', error);
    throw error;
  }
};