import React, { useState, useRef, useEffect } from 'react';
import { ChevronDown } from 'lucide-react';
import MegaMenu from './MegaMenu';

const NavItem = ({ 
  title, 
  icon: Icon, 
  hasMegaMenu = false,
  href,
  menuData
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const navItemRef = useRef(null);
  const timeoutRef = useRef(null);
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navItemRef.current && !navItemRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      // Clear any existing timeout on unmount
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const handleMouseEnter = () => {
    if (window.innerWidth >= 768 && hasMegaMenu) {
      // Clear any existing timeout
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
      setIsOpen(true);
    }
  };

  const handleMouseLeave = () => {
    if (window.innerWidth >= 768) {
      // Set a timeout before closing the menu
      timeoutRef.current = setTimeout(() => {
        setIsOpen(false);
      }, 100); // Small delay to allow movement to mega menu
    }
  };

  const handleMegaMenuMouseEnter = () => {
    // Clear the timeout when entering the mega menu
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };

  const handleMegaMenuMouseLeave = () => {
    // Close the menu when leaving the mega menu
    setIsOpen(false);
  };

  const handleClick = (e) => {
    if (hasMegaMenu) {
      e.preventDefault();
      setIsOpen(!isOpen);
    }
  };

  if (!hasMegaMenu && href) {
    return (
      <a
        href={href}
        className="text-gray-300 hover:bg-slate-800 hover:text-white px-3 py-2 rounded-md text-sm font-medium flex items-center space-x-2"
      >
        {Icon && <Icon className="w-5 h-5" />}
        <span>{title}</span>
      </a>
    );
  }

  return (
    <div
      ref={navItemRef}
      className="relative"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <button
        onClick={handleClick}
        className={`flex items-center space-x-2 px-3 py-2 rounded-md text-sm font-medium transition-colors
          ${isOpen 
            ? 'text-white bg-slate-800' 
            : 'text-gray-300 hover:bg-slate-800 hover:text-white'
          }`}
      >
        {Icon && <Icon className="w-5 h-5" />}
        <span>{title}</span>
        {hasMegaMenu && (
          <ChevronDown 
            className={`w-4 h-4 transition-transform ${isOpen ? 'transform rotate-180' : ''}`}
          />
        )}
      </button>

      {hasMegaMenu && isOpen && menuData && (
        <div 
          className="absolute left-0 w-screen max-w-screen-lg transform -translate-x-1/4 mt-2 z-50"
          onMouseEnter={handleMegaMenuMouseEnter}
          onMouseLeave={handleMegaMenuMouseLeave}
        >
          <div className="bg-slate-900 border border-slate-800 rounded-xl shadow-xl overflow-hidden">
            <MegaMenu 
              sections={menuData.sections}
              features={menuData.features}
              links={menuData.links}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default NavItem;